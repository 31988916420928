import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChangeLog from '../../components/ChangeLog';
import DropDownList from '@ncscolour/ncs-ui-utils/components/DropDownList'
import { Translations } from  '../../../utils/translator'

import { 
    fetchDashboardLog, 
    showMoreDashboardLog 
} from '../../actions/startPageActions'

const Widget = (props) => {
    return <article className="startpage__widget">
        <h2>{props.title}</h2>
        {props.children}
    </article>
  }

const DashboardHeader = (props) => { 
    const dispatch = useDispatch()
    const [dayChosen, setDayChosen] = useState(7)

    if(props.loggedInFirstName && props.loggedInFirstName.length === 0) {
        return null
    }

    return <div className="entity-header">
        <h1>{Translations.Copy_Dashboard_Template_Welcome.format(props.loggedInFirstName)}</h1>
        <div className="startpage__date-container">
            <label>{Translations.Copy_Dashboard_SelectTimePeriod}:</label>
            <DropDownList
                className="form-control"
                data={
                {
                    7: Translations.Copy_Dashboard_Template_LastXDays.format(7),
                    14: Translations.Copy_Dashboard_Template_LastXDays.format(14),
                    //30: Translations.Copy_Dashboard_Template_LastXDays.format(30),
                }
                }
                selected={dayChosen}
                style={{width: 'auto'}}
                onChange={(daysBack) => {
                    setDayChosen(parseInt(daysBack))
                    dispatch(fetchDashboardLog(daysBack))
                }}
            />
            </div>
    </div>
}

const StartPageContainer = (props) => {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.startPage.loadingDl || state.startPage.loadingLu)
    const changeLog = useSelector(state => state.startPage.changeLog)
    //const latestUploads = useSelector(satte => state.startPage.latestUploads)

    useEffect(() => {
        dispatch(fetchDashboardLog(7))
        // dispatch(fetchLatestUploads())
    }, [dispatch])

    const className = loading === true ? 'capq-spinner' : '';
    return <div className={className}>
            <DashboardHeader loggedInFirstName={props.loggedInFirstName} />

            <Widget title={changeLog['today'].title}>
                <ChangeLog 
                    changeLog={changeLog['today']}
                    onShowMoreClick={() => dispatch(showMoreDashboardLog('today'))} />
            </Widget>

            <Widget title={changeLog['earlier'].title}>
                <ChangeLog 
                    changeLog={changeLog['earlier']}
                    onShowMoreClick={() => dispatch(showMoreDashboardLog('earlier'))} />
            </Widget>
        
            {/* <Widget title="Latest uploads on assignment">
                <LinkList links={latestUploads} />
            </Widget> */}
    </div>        
    
}

export default StartPageContainer