import React from 'react';

import './modal.scss'

const Modal = (props) => {
      return <>
        <div className="modal modal-block">
          <div>
            <div className="modal-dialog">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">{props.title}</h4>
                          {props.onClose ?  <button className="close" onClick={props.onClose}> 
                            <span aria-hidden="true">×</span>
                          </button>: null}
                      </div>
                      <div className="modal-body">
                        {props.children}
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div className="modal-backdrop"></div>
        </>
}
export default Modal