import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import PageView from './PageView';
import BreakView from './BreakView';

export default class PaginationBox extends Component {
  constructor(props) {
    super(props);

    let initialSelected;
    if (props.initialPage) {
      initialSelected = props.initialPage;
    } else if (props.forcePage) {
      initialSelected = props.forcePage;
    } else {
      initialSelected = 0;
    }

    this.state = {
      selected: initialSelected,
    };
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePageSelected = this.handlePageSelected.bind(this);
    this.handleBreakClick = this.handleBreakClick.bind(this);
  }


  componentDidUpdate(prevProps) {
    if (
      typeof this.props.forcePage !== 'undefined' &&
      this.props.forcePage !== prevProps.forcePage
    ) {
      this.setState({ selected: this.props.forcePage });
    }
  }

  handlePreviousPage(evt) {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    if (this.state.selected > 0) {
      this.handlePageSelected(this.state.selected - 1, evt);
    }
  }
  handleNextPage(evt){
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    if (this.state.selected < this.props.pageCount - 1) {
      this.handlePageSelected(this.state.selected + 1, evt);
    }
  }

  handlePageSelected(selected, evt) {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);

    if (this.state.selected === selected) return;

    this.setState({ selected: selected });

    // Call the callback with the new selected item:
    
    this.props.onPageChange({ selected: selected });
  }

  getForwardJump() {

    const forwardJump = this.state.selected + this.props.pageRangeDisplayed;
    return forwardJump >= this.props.pageCount ? this.props.pageCount - 1 : forwardJump;
  }

  getBackwardJump() {
    const backwardJump = this.state.selected - this.props.pageRangeDisplayed;
    return backwardJump < 0 ? 0 : backwardJump;
  }

  handleBreakClick(index, evt){
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);

    this.handlePageSelected(
      this.state.selected < index ? this.getForwardJump() : this.getBackwardJump(),
      evt
    );
  }

  hrefBuilder(pageIndex) {
    if (
        this.props.hrefBuilder &&
      pageIndex !== this.state.selected &&
      pageIndex >= 0 &&
      pageIndex < this.props.pageCount
    ) {
      return this.hrefBuilder(pageIndex + 1);
    }
  }

  ariaLabelBuilder(pageIndex) {
    const selected = pageIndex === this.state.selected;
    if (
      this.props.ariaLabelBuilder &&
      pageIndex >= 0 &&
      pageIndex < this.props.pageCount
    ) {
      let label = this.props.ariaLabelBuilder(pageIndex + 1, selected);
      // DEPRECATED: The extraAriaContext prop was used to add additional context
      // to the aria-label. Users should now use the ariaLabelBuilder instead.
      if (this.props.extraAriaContext && !selected) {
        label = label + ' ' + this.props.extraAriaContext;
      }
      return label;
    }
  }

  getPageElement(index) {
    return (
      <PageView
        key={index}
        onClick={this.handlePageSelected.bind(null, index)}
        selected={this.state.selected === index}
        pageClassName={'paginate_button page-item'}
        pageLinkClassName={'page-link'}
        activeClassName={this.props.activeClassName}
        activeLinkClassName={'page-link'}
        extraAriaContext={this.props.extraAriaContext}
        href={this.hrefBuilder(index)}
        ariaLabel={this.ariaLabelBuilder(index)}
        page={index + 1}
      />
    );
  }

  pagination(){
    const items = [];

    if (this.props.pageCount <= this.props.pageRangeDisplayed) {
      for (let index = 0; index < this.props.pageCount; index++) {
        items.push(this.getPageElement(index));
      }
    } else {
      let leftSide = this.props.pageRangeDisplayed / 2;
      let rightSide = this.props.pageRangeDisplayed - leftSide;

      // If the selected page index is on the default right side of the pagination,
      // we consider that the new right side is made up of it (= only one break element).
      // If the selected page index is on the default left side of the pagination,
      // we consider that the new left side is made up of it (= only one break element).
      if (this.state.selected > this.props.pageCount - this.props.pageRangeDisplayed / 2) {
        rightSide = this.props.pageCount - this.state.selected;
        leftSide = this.props.pageRangeDisplayed - rightSide;
      } else if (this.state.selected < this.props.pageRangeDisplayed / 2) {
        leftSide = this.state.selected;
        rightSide = this.props.pageRangeDisplayed - leftSide;
      }

      let index;
      let page;
      let breakView;
      let createPageView = index => this.getPageElement(index);

      for (index = 0; index < this.props.pageCount; index++) {
        page = index + 1;

        // If the page index is lower than the margin defined,
        // the page has to be displayed on the left side of
        // the pagination.
        if (page <= this.props.marginPagesDisplayed) {
          items.push(createPageView(index));
          continue;
        }

        // If the page index is greater than the page count
        // minus the margin defined, the page has to be
        // displayed on the right side of the pagination.
        if (page > this.props.pageCount - this.props.marginPagesDisplayed) {
          items.push(createPageView(index));
          continue;
        }

        // If the page index is near the selected page index
        // and inside the defined range (pageRangeDisplayed)
        // we have to display it (it will create the center
        // part of the pagination).
        if (index >= this.state.selected - leftSide && index <= this.state.selected + rightSide) {
          items.push(createPageView(index));
          continue;
        }

        // If the page index doesn't meet any of the conditions above,
        // we check if the last item of the current "items" array
        // is a break element. If not, we add a break element, else,
        // we do nothing (because we don't want to display the page).
        if (this.props.breakLabel && items[items.length - 1] !== breakView) {
          breakView = (
            <BreakView
              key={index}
              breakLabel={this.props.breakLabel}
              breakClassName={'paginate_button page-item ' + this.props.breakClassName}
              breakLinkClassName={'page-link ' + this.props.breakLinkClassName}
              onClick={this.handleBreakClick.bind(null, index)}
            />
          );
          items.push(breakView);
        }
      }
    }

    return items;
  }

  render() {
    const previousAriaDisabled = this.state.selected === 0 ? 'true' : 'false';
    const nextAriaDisabled = this.state.selected === this.props.pageCount - 1 ? 'true' : 'false';

    return (
        <div className="dataTables_paginate paging_full_numbers">
            <ul className="pagination">
                <li className={'paginate_button page-item previous'}>
                    <a
                        onClick={this.handlePreviousPage}
                        className={'page-link'}
                        href={this.hrefBuilder(this.state.selected - 1)}
                        tabIndex="0"
                        role="button"
                        onKeyPress={this.handlePreviousPage}
                        aria-disabled={previousAriaDisabled}
                    >
                        {this.props.previousLabel}
                    </a>
                </li>

                {this.pagination()}

                <li className={'paginate_button page-item next'}>
                    <a
                        onClick={this.handleNextPage}
                        className={'page-link'}
                        href={this.hrefBuilder(this.state.selected + 1)}
                        tabIndex="0"
                        role="button"
                        onKeyPress={this.handleNextPage}
                        aria-disabled={nextAriaDisabled}
                    >
                        {this.props.nextLabel}
                    </a>
                </li>
            </ul>
        </div>

    );
  }
}