import React,{ useState } from 'react'
import { Translations } from '../../../../utils/translator'
import './connection-details.scss';
import ConnectionResultChart from './ConnectionResult/ConnectionResultChart';
import ConnectionResultTable from './ConnectionResult/ConnectionResultTable';
import ConnectionReproducibilityChart from './ConnectionResult/ConnectionReproducibilityChart';
import ManageProfiles from './../ManageProfiles/ManageProfiles';
import ConnectionDetailsTab from './ConnectionDetailsTab';
import ConnectionSettingsTab from './ConnectionSettingsTab';


const ConnectionDetails = (props) => {
    const [activeTab, setActiveTab] = useState('details')
    const [connectionDetailsState, setConnectionDetails] = useState(props.connectionDetails)
    const [showManageModal, setShowManageModal] = useState(false)

    const changesMade = () => {
        let changed = false
        Object.keys(connectionDetailsState).forEach(key => {
            if(connectionDetailsState[key] !== props.connectionDetails[key]){
                changed = true
            }
        })
        return changed
    }

    return <section className="connection-details">
        <div className="entity-header">
            <h1>{`${props.connectionDetails.brand} - ${props.connectionDetails.model} (${props.connectionDetails.serialNo})` }</h1>
            <div className="button-row">
                {(props.authorizedToAdministrate === true) ? <>
                    {props.connectionDetails.showReconnectButton ? <button className="btn btn-primary" onClick={()=> window.location = `/MeasurementInstrument/ReConnectAdmin/${props.connectionDetails.instrumentSettingId}`}>
                        {Translations.Copy_Instrument_Reconnect}
                    </button> : null}
                    {props.connectionDetails.showConnectButton === true ? <button className="btn btn-primary" onClick={()=> window.location = `/ConnectNewInstrument/${props.connectionDetails.instrumentSettingId}`}>
                        {Translations.Copy_Instrument_Connect}
                    </button> : null}
                    {props.connectionDetails.showNewSettingButton ? <button className="btn btn-secondary" onClick={()=> window.location = `/MeasurementInstrument/NewConnectionSettingAdmin/${props.connectionDetails.instrumentSettingId}`}>
                        <i className="fas fa-plus"></i>{Translations.Copy_Instrument_NewSetting}
                    </button> : null}
                </> : null}

                {props.isCoreSupport === true ? <button className="btn btn-secondary" style={{border: '1px solid red'}} onClick={() => setShowManageModal(true)}>
                    {Translations.Buttons_General_Manage}
                </button> : null }
            </div>
        </div>

        <article className="entity-information">
            <aside className="info-panel">
                <div className="info-panel-item">
                    <span className="panel-item-label">{Translations.Copy_Instrument_Owner}:</span>
                    <span className="panel-item-info">{props.connectionDetails.owner}</span>
                </div>
                {['ready', 'ready and pending'].indexOf(props.connectionDetails.status.toLowerCase()) >= 0 ? <div className="info-panel-item">
                    <span className="panel-item-label">{Translations.Copy_General_Status}:</span>
                        {props.connectionDetails.status.toLowerCase() !== 'stopped' ?  
                        <span className="panel-item-info text-ok">{props.connectionDetails.status}</span> : 
                        <span className="panel-item-info text-stopped">{props.connectionDetails.status}</span> }
                </div> : null}
                <div className="info-panel-item">
                    <span className="panel-item-label">{Translations.Copy_Instrument_ConnectionSet}:</span>
                    <span className="panel-item-info">{props.connectionDetails.activeProfileName}</span>
                </div>
                <div className="info-panel-item">
                    <span className="panel-item-label">{Translations.Copy_Instrument_Type}:</span>
                    <span className="panel-item-info">{props.connectionDetails.connectionType}</span>
                </div>
                {
                   props.connectionDetails.referenceInstrument !== '' ? <div className="info-panel-item">
                   <span className="panel-item-label">{Translations.Copy_Instrument_Reference}:</span>
                   <span className="panel-item-info">{ props.connectionDetails.referenceInstrument}</span>
                   </div> : null
                }

                {props.connectionDetails.createdDate ? <div className="info-panel-item">
                    <span className="panel-item-label">{Translations.Copy_General_Connected}:</span>
                    <span className="panel-item-info">{props.connectionDetails.createdDate}</span>
                </div> : null}
                {props.connectionDetails.expiryDate ? <div className="info-panel-item">
                    <span className="panel-item-label">{Translations.Copy_Instrument_NextReconnect}:</span>
                    <span className="panel-item-info">{props.connectionDetails.expiryDate}</span>
                </div> : null}
            </aside>
            <section className="main-panel main-panel-tabs">
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a href="#" className={ activeTab === 'details' ? 'nav-link active' : 'nav-link'} onClick={() => setActiveTab('details')}>Details</a>
                    </li>
                    <li className="nav-item">
                        <a  href="#" className={ activeTab === 'settings' ? 'nav-link active' : 'nav-link'} onClick={() => setActiveTab('settings')}>Settings</a>
                    </li>
                    <li className="nav-item">
                        <a  href="#" className={ activeTab === 'connectionresult' ? 'nav-link active' : 'nav-link'} onClick={() => setActiveTab('connectionresult')}>Connection Results</a>
                    </li>
                    <li className="nav-item">
                        <a  href="#" className={ activeTab === 'reproducibility' ? 'nav-link active' : 'nav-link'} onClick={() => setActiveTab('reproducibility')}>Reproducibility</a>
                    </li>
                    <li className="nav-item">
                      <a  href="#" className={ activeTab === 'connectionresultchart' ? 'nav-link active' : 'nav-link'} onClick={() => setActiveTab('connectionresultchart')}>Connection Result Chart</a>
                    </li>
                </ul>

                <div className="tab-content">
                    <div className="tab-pane fade show active">
                        {activeTab === 'details' ? <ConnectionDetailsTab 
                            authorizedToAdministrate={props.authorizedToAdministrate}
                            brand={connectionDetailsState.brand}
                            model={connectionDetailsState.model}
                            serialNo={connectionDetailsState.serialNo}
                            description={connectionDetailsState.description !== null ? connectionDetailsState.description : ''} 
                            location={connectionDetailsState.location || ''}
                            onDescriptionChange={(value) => props.authorizedToAdministrate === true ? setConnectionDetails({...connectionDetailsState, description: value}) : null}
                            onLocationChange={(value) => props.authorizedToAdministrate === true ? setConnectionDetails({...connectionDetailsState, location: value}) : null}
                            /> : null}
                        {activeTab === 'settings' ? <ConnectionSettingsTab 
                            physicalPropertyName={props.connectionDetails.physicalPropertyName}
                            physicalPropertyDescription={props.connectionDetails.physicalPropertyDescription} 
                            specularComponentName={props.connectionDetails.specularComponentName} 
                            specularComponentDescription={props.connectionDetails.specularComponentDescription} 
                            apertureName={props.connectionDetails.apertureName} 
                            apertureDescription={props.connectionDetails.apertureDescription} 
                            uvFilterName={props.connectionDetails.uvFilterName} 
                            uvFilterDescription={props.connectionDetails.uvFilterDescription} 
                            /> : null}
                        {(activeTab === 'connectionresult' && props.connectionDetails.profiles.data.length > 0) ? <ConnectionResultTable 
                                isCoreSupport={props.isCoreSupport}
                                authorizedToAdministrate={props.authorizedToAdministrate === true}
                                connectionResultTable={props.connectionResultTable}
                                getConnectionResultTable={props.getConnectionResultTable}
                                activeProfile={props.connectionDetails.profiles.data.find(p => p.id === props.connectionDetails.activeProfileId)}
                                profiles={props.connectionDetails.profiles}
                                />
                            : null
                        } 
                        {(activeTab === 'reproducibility' && props.connectionDetails.profiles.data.length > 0) ? <>
                                <p>{Translations.Copy_Instrument_Reproducibility_HelpText}</p>
                                <ConnectionReproducibilityChart
                                    authorizedToAdministrate={props.authorizedToAdministrate === true}
                                    reproducibility={props.reproducibility}
                                    getReproducibilityResult={props.getReproducibilityResult}
                                    activeProfile={props.connectionDetails.profiles.data.find(p => p.id === props.connectionDetails.activeProfileId)}
                                    profiles={props.connectionDetails.profiles}
                                />
                            </> : null
                        } 
                        {(activeTab === 'connectionresultchart' && props.connectionDetails.profiles.data.length > 0) ? <>
                                <p>{Translations.Copy_Instrument_ConnectionResult_HelpText}</p>
                                <ConnectionResultChart
                                    authorizedToAdministrate={props.authorizedToAdministrate === true}
                                    connectionResult={props.connectionResultData}
                                    getConnectionResult={props.getConnectionResult}
                                    activeProfile={props.connectionDetails.profiles.data.find(p => p.id === props.connectionDetails.activeProfileId)}
                                    profiles={props.connectionDetails.profiles}
                                />
                            </> : null
                        }
                    </div> 
                </div>
                <div className="main-panel-footer">
                    <div className="button-row button-row-bottom">
                        <a className="btn btn-secondary" href="/V2/Connection/ConnectionList">{Translations.Buttons_General_BackToListButton}</a>
                        {(activeTab === 'details' && props.authorizedToAdministrate === true) ? <button 
                                        disabled={changesMade() !== true} 
                                        onClick={() => {
                                            props.updateInstrumentDescription(connectionDetailsState.description, connectionDetailsState.location, connectionDetailsState.instrumentId)}
                                        }
                                        className="btn btn-primary"
                                        >Save</button>
                        : null}
                    </div>
                </div>
            </section>
    </article>
    {(showManageModal && props.isCoreSupport === true) ? <ManageProfiles 
        profiles={props.connectionDetails.profiles}
        instrumentSettingsId={props.connectionDetails.instrumentSettingId}
        enableOptimize={props.authorizedToAdministrate === true && props.isCoreSupport === true}
        onClose={() => setShowManageModal(false)}
        onApply={props.updateProfile}
        onDelete={props.deleteProfile}
        downloadProfileMeasurement={props.downloadProfileMeasurement}
        /> : null}
    </section>
}

export default ConnectionDetails