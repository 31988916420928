import React, { useState, useEffect, useRef } from 'react'
import validate from 'validate.js';
import CapqLogo from '../../CapqLogo'

import './login-form.scss'

const ForgotPasswordForm = (props) => {
    const focusElementRef = useRef()
    const [className, setClassName] = useState('login-form')
    const [email, setEmail] = useState('')
    const [validationResult, setValidationResult] = useState(null)

    useEffect(() => {
        setClassName('login-form login-form--visible')
        focusElementRef.current.focus();
    }, [])

    const checkFields = (fieldKey) => {
        return (validationResult && validationResult[fieldKey] && validationResult[fieldKey].length > 0)
    }

    const validationMessage = (fieldKey) => {
        return checkFields(fieldKey)?
                <div className="validation-message"><p className="help-block error">{validationResult[fieldKey].join(' ')}</p></div>
                :
                null
    }

    const submitForm = () => {
        if(email.length === 0){
            return
        }
        focusElementRef.current.focus();
        const user = {
            email: email
        }
        
        const validationConstraints = {
            email: {
              email: true
            }
          }
          const hasErrors = validate(user, validationConstraints)
          setValidationResult(hasErrors)
          if(!hasErrors){
              const emailCopy = email
              setEmail('')
            props.onSubmit(emailCopy)
          }
        
    }

    const baseClass = 'form-group'
    const emailFormGroupClassName = checkFields('email') ? `${baseClass} has-error` : baseClass
    return <section className={className}>
        <section className="form-group form-group--centered">
            <CapqLogo />
            <h2>Forgot your password?</h2>
            <p>We will email you instructions on how to reset it.</p>
        </section>
        

        {props.fail !== true && props.resultMessage && props.resultMessage.length > 0 ? <div className={`${baseClass} success-message`}>
            <div className="capq-checkmark"></div>
            <p>{props.resultMessage}</p>
        </div>
        :
        <>
            <div className={emailFormGroupClassName}>
                <label htmlFor="email">Email</label>
                <input 
                    ref={focusElementRef}
                    type="text" 
                    className="form-control" 
                    id="email" 
                    name="email" 
                    value={email} 
                    onKeyUp={event => {
                        if(event.key.toLowerCase() === 'enter'){ //event.keyCode is deprectated!
                            submitForm()
                        }
                    }}
                    onChange={(e) => setEmail(e.target.value)} 
                />
                {validationMessage('email')}
                {(props.resultMessage && props.resultMessage.length > 0) ? <p className='help-block error'>{props.resultMessage}</p> : null}
            </div>
            
            <div className="form-group buttons">
            {(props.onActionLinkClick && props.actionLinkText) ? <a tabIndex="4" href="#" onClick={e => {
                    e.preventDefault()
                    props.onActionLinkClick()
                }}>{props.actionLinkText}</a> : null}
                <button 
                    className="btn btn-primary" 
                    onClick={submitForm}
                >Send link</button>
            </div>
        </>
        }
    </section>
}

export default ForgotPasswordForm