/*eslint no-undef:off */
import { qs, qsa } from './utils/dom';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './react/store';

import AppContainer from './react/containers/AppContainer'
import DatabaseUpdateContainer from './react/containers/database-update/DatabaseUpdateContainer'
import LoginContainer from './react/containers/account/LoginContainer'
import StartPageContainer from './react/containers/start-page/StartPageContainer'
import CreateAssignmentContainer from './react/containers/assignment/CreateAssignmentContainer'
import NewUserContainer from './react/containers/account/NewUserContainer'
import EditUserContainer from './react/containers/account/EditUserContainer'
import CreateNewPasswordContainer from './react/containers/account/CreateNewPasswordContainer'
import ColourStandardContainer from './react/containers/colourstandard/ColourStandardContainer'
import AssignmentContainer from './react/containers/assignment/AssignmentContainer'
import UserListContainer from './react/containers/account/UserListContainer';
import CompanyListContainer from './react/containers/company/CompanyListContainer';
import CreateCompanyContainer from './react/containers/company/CreateCompanyContainer';
import EditCompanyContainer from './react/containers/company/EditCompanyContainer';
import ConnectionListContainer from './react/containers/connection/ConnectionListContainer';
import ConnectionDetailsContainer from './react/containers/connection/ConnectionDetailsContainer';

export function loadReactComponent(reactComponent, element) {
    const tempElement = document.createElement('section');
    tempElement.classList.add('capq-app')

    const root = createRoot(tempElement)

    root.render(<Provider store={store}>
        <AppContainer>  
            {reactComponent}
        </AppContainer>
        </Provider>);
    
    setTimeout(() => {
        const requestVerificationTokenField = qs(element, 'input[name="__RequestVerificationToken"]')
        if(requestVerificationTokenField){
            tempElement.appendChild(requestVerificationTokenField)
        }
        element.replaceWith(tempElement)
        //return tempElement
    }, 2000)

    return tempElement
}

class ComponentLoader {
    constructor(element) {
        this.element = element;
        this.components = {
            'databaseUpdate': (element) => {
                return require.ensure([], function () {
                    loadReactComponent(<DatabaseUpdateContainer inProgressOnLoad={element.dataset.inProgressOnLoad.toLowerCase() === 'true'} />, element);
                });
            },
            'login': (element) => {
                return require.ensure([], function () {
                    loadReactComponent(<LoginContainer />, element);
                });
            },
            'startPage': (element) => {
                return require.ensure([], function () {
                    const reactElement = qs(element, '.js_startPage');
                    if(reactElement){
                        loadReactComponent(<StartPageContainer loggedInFirstName={reactElement.dataset.firstName} />, reactElement);
                    }
                });
            },
            'createAssignmentPage': (element) => {
                return require.ensure([], function () {
                    const reactElement = qs(element, '.js_createAssignmentPage');
                    if(reactElement){
                        loadReactComponent(<CreateAssignmentContainer/>, reactElement);
                    }
                });
            },
            'userListPage': (element) => {
                return require.ensure([], function () {
                    const reactElement = qs(element, '.js_userListPage');
                    if(reactElement){
                        loadReactComponent(<UserListContainer  />, reactElement);
                    }
                });
            },
            'newUser': (element) => {
                return require.ensure([], function () {
                    const reactElement = qs(element, '.js_newUser');
                    if(reactElement){
                        loadReactComponent(<NewUserContainer  />, reactElement);
                    }
                });
            },
            'editUser': (element) => {
                return require.ensure([], function () {
                    const reactElement = qs(element, '.js_editUser');
                    if(reactElement){
                        loadReactComponent(<EditUserContainer 
                            userId={element.dataset.userId} 
                            isCoreSupport={element.dataset.isCoreSupport.toLowerCase() === 'true'} 
                            isSupervisor={element.dataset.isSupervisor.toLowerCase() === 'true'} 
                            isLoggedInUser={element.dataset.isLoggedInUser.toLowerCase() === 'true'} 
                            canEdit={element.dataset.canEdit.toLowerCase() === 'true'}/>, reactElement);
                    }
                });
            },
            'createNewPassword': (element) => {
                return require.ensure([], function () {
                    loadReactComponent(<CreateNewPasswordContainer 
                        linkId={element.dataset.linkId} 
                        userId={element.dataset.userId} 
                        />, element);
                });
            },
            'companyListPage': (element) => {
                return require.ensure([], function () {
                    const reactElement = qs(element, '.js_companyListPage');
                    if(reactElement){
                        loadReactComponent(<CompanyListContainer  />, reactElement);
                    }
                });
            },
            'createCompany': (element) => {
                return require.ensure([], function () {
                    const reactElement = qs(element, '.js_createCompany');
                    if(reactElement){
                        loadReactComponent(<CreateCompanyContainer  />, reactElement);
                    }
                });
            },
            'editCompany': (element) => {
                return require.ensure([], function () {
                    const reactElement = qs(element, '.js_editCompany');
                    if(reactElement){
                        loadReactComponent(<EditCompanyContainer 
                            companyId={element.dataset.companyId} 
                            isCoreSupport={element.dataset.isCoreSupport.toLowerCase() === 'true'} 
                            isSupervisor={element.dataset.isSupervisor.toLowerCase() === 'true'} 
                            canEdit={element.dataset.canEdit.toLowerCase() === 'true'}/>, reactElement);
                    }
                });
            },
            'connectionListPage': (element) => {
                return require.ensure([], function () {
                    loadReactComponent(<ConnectionListContainer 
                        isCoreSupport={element.dataset.isCoreSupport.toLowerCase() === 'true'} 
                        authorizedToAdministrate={element.dataset.authorizedToAdministrate.toLowerCase() === 'true'} 
                            />, element);
                });
            },
            'connectionDetails': (element) => {
                return require.ensure([], function () {
                    loadReactComponent(<ConnectionDetailsContainer 
                        isCoreSupport={element.dataset.isCoreSupport.toLowerCase() === 'true'}
                        isAdmin={element.dataset.isAdmin.toLowerCase() === 'true'} 
                        isSupervisor={element.dataset.isSupervisor.toLowerCase() === 'true'} 
                        authorizedToAdministrate={element.dataset.authorizedToAdministrate.toLowerCase() === 'true'}
                        instrumentSettingsId={element.dataset.instrumentSettingsId} 
                            />, element);
                });
            },
            'assignmentReviewCharts': (element) => {
                return require.ensure([], function () {
                    const AssignmentReviewCharts = require('./components/assignment-review-charts').default;
                    new AssignmentReviewCharts(element);
                });
            },
            'measurementChangeStatusDialog': (element) => {
                return require.ensure(['./components/dialog'], function () {
                    const MeasurementChangeStatusDialog = require('./components/measurement-change-status-dialog').default;
                    new MeasurementChangeStatusDialog(element);
                });
            },
            'downloadColourStandardDialog': (element) => {
                return require.ensure(['./components/dialog'], function () {
                    const DownloadColourStandardDialog = require('./components/download-colour-standard-dialog').default;
                    new DownloadColourStandardDialog(element);
                });
            },
            'deactivateButton': (element) => {
                return require.ensure([], function () {
                    const DeactivateButton = require('./components/deactivate-button').default;
                    new DeactivateButton(element);
                });
            },
            'activateButton': (element) => {
                return require.ensure([], function () {
                    const ActivateButton = require('./components/activate-button').default;
                    new ActivateButton(element);
                });
            },
            'postUrlButton': (element) => {
                return require.ensure([], function () {
                    const PostUrlButton = require('./components/posturl-button').default;
                    new PostUrlButton(element);
                });
            },
            'dialog': (element) => {
                return require.ensure([], function () {
                    const Dialog = require('./components/dialog').default;
                    new Dialog(element);
                });
            },
            'colourStandardsDataTable': (element) => {
                return require.ensure([], function () {
                    const reactElement = qs(element, '.js_colourStandardsDataTable');
                    if(reactElement){
                        const userHaveAccess = element.dataset.access === 'true'
                        loadReactComponent(<ColourStandardContainer userHaveAccess={userHaveAccess}  />, reactElement);
                    }
                });
            },
            'assignmentsDataTable': (element) => {
                return require.ensure([], function () {
                    const reactElement = qs(element, '.js_assignmentsDataTable');
                    if(reactElement){
                        const userHaveAccess = element.dataset.access === 'true'
                        loadReactComponent(<AssignmentContainer userHaveAccess={userHaveAccess}  />, reactElement);
                    }
                });
            },
            'tolerancesDataTable': (element) => {
                return require.ensure(['./components/dialog'], function () {
                    const TolerancesDataTable = require('./components/tolerances-datatable').default;
                    new TolerancesDataTable(element);
                });
            },
            'colourStandardAssignmentsDataTable': (element) => {
                return require.ensure([], function () {
                    const ColourStandardAssignmentsDataTable = require('./components/colour-standard-assignments-datatable').default;
                    new ColourStandardAssignmentsDataTable(element);
                });
            },
            'toleranceEdit': (element) => {
                return require.ensure(['./components/dialog', './components/tolerances-datatable'], function () {
                    const ToleranceEdit = require('./components/tolerances-edit').default;
                    new ToleranceEdit(element);
                });
            },
            'searchFieldSuggest': (element) => {
                return require.ensure([], function () {
                    const SearchFieldSuggest = require('./components/search-field-suggest').default;
                    new SearchFieldSuggest(element);
                });
            },
            'colourEdit': (element) => {
                return require.ensure([], function () {
                    const ColourEdit = require('./components/colour-edit').default;
                    new ColourEdit(element);
                });
            },
            'colourStandardEdit': (element) => {
                return require.ensure([], function () {
                    const ColourStandardEdit = require('./components/colour-standard-edit').default;
                    new ColourStandardEdit(element);
                });
            },
            'colourStandardCreate': (element) => {
                return require.ensure([], function () {
                    const ColourStandardCreate = require('./components/colour-standard-create').default;
                    new ColourStandardCreate(element);
                });
            },
            'measurementsUpload': (element) => {
                return require.ensure([], function () {
                    const MeasurementsUpload = require('./components/measurements-upload').default;
                    new MeasurementsUpload(element);
                });
            },
            'tagsField': (element) => {
                return require.ensure([], function () {
                    const TagsField = require('./components/tags-field').default;
                    new TagsField(element);
                });
            },
            'assignmentEdit': (element) => {
                return require.ensure([], function () {
                    const AssignmentEdit = require('./components/assignment-edit').default;
                    new AssignmentEdit(element);
                });
            },
            'projectEdit': (element) => {
                return require.ensure([], function () {
                    const ProjectEdit = require('./components/project-edit').default;
                    new ProjectEdit(element);
                });
            },
            'suppliersDataTable': (element) => {
                return require.ensure(['./components/assignment-edit'], function () {
                    const SuppliersDataTable = require('./components/suppliers-datatable').default;
                    new SuppliersDataTable(element);
                });
            },
            'projectColoursDataTable': (element) => {
                return require.ensure(['./components/assignment-edit'], function () {
                    const ProjectColoursDataTable = require('./components/project-colours-datatable').default;
                    new ProjectColoursDataTable(element);
                });
            },
            'projectColourAddDialogDataTable': (element) => {
                return require.ensure(['./components/dialog', './components/assignment-edit'], function () {
                    const ProjectColourAddDialogDataTable = require('./components/project-colour-add-dialog-datatable').default;
                    new ProjectColourAddDialogDataTable(element);
                });
            },
            'suppliersAddDialogDataTable': (element) => {
                return require.ensure(['./components/dialog', './components/assignment-edit'], function () {
                    const SuppliersAddDialogDataTable = require('./components/suppliers-add-dialog-datatable').default;
                    new SuppliersAddDialogDataTable(element);
                });
            },
            'measurementsDataTable': (element) => {
                return require.ensure(['./components/assignment-edit'], function () {
                    const MeasurementsDataTable = require('./components/measurements-datatable').default;
                    new MeasurementsDataTable(element);
                });
            },
            'dateConverter': (element) => {
                return require.ensure([], function () {
                    const dateConverter = require('./components/date-converter').default;
                    new dateConverter(element);
                });
            },
            'languageSelector': (element) => {
                return require.ensure([], function () {
                    const LanguageSelector = require('./components/language-selector').default;
                    new LanguageSelector(element);
                });
            },
            'loginAsCompany': (element) => {
                return require.ensure([], function () {
                    const LoginAsCompany = require('./components/login-as-company').default;
                    new LoginAsCompany(element);
                });
            },
            'creativeSpaceDump': (element) => {
                return require.ensure([], function () {
                    const CreativeSpaceDump = require('./components/creative-space-dump').default;
                    new CreativeSpaceDump(element);
                });
            },
            'creativeSpaceExport': (element) => {
                return require.ensure([], function () {
                    const CreativeSpaceExport = require('./components/creative-space-export').default;
                    new CreativeSpaceExport(element);
                });
            }
        };
    }

    loadComponents(callback) {
        const componentLoadPromises = [];
        const componentsElements = qsa(this.element, '*[data-component]');
        componentsElements.sort((c1, c2) => {
            const componentNames = c2.dataset.component.replace(' ', '').split(',');
            if (componentNames && componentNames.indexOf('dialog') >= 0) { //Prioritize some components to ensure they load before others
                return 1;
            }
            return -1;
        });
        componentsElements.forEach((el) => {
            const componentNames = el.dataset.component.split(',');
            componentNames.forEach(item => {
                const trimmed = item.trim();
                if (!trimmed) {
                    return;
                }
                try {
                    componentLoadPromises.push(this.components[trimmed](el));
                } catch (err) {
                    console.log(`%cERROR! Component "${item}" found but it is not defined in component-loader list!`, 'padding:10px;background-color:red;color:white;');
                }
            });
        });
        Promise.all(componentLoadPromises)
            .then(() => {
                //console.log('All components are loaded to DOM-elements. Calling callback method...');
                if(typeof callback === 'function') callback();
            });
    }
}

export default ComponentLoader;