import React from 'react';
import { Translations } from '../../../utils/translator'

const decodeHTML = logItem => {
    let textOutput = `
        <time>${logItem.readableDate}</time>
        <div>
            <div>${logItem.text}</div>
            ${logItem.changedProperties.map(cp => `<div>${cp}</div>`).join('')}
            <div class="user"><i class="fa fa-user"></i>${logItem.userOutput}</div>
        </div>
        `;
    if(logItem.actionId === 1){
        textOutput = `<span class="badge badge-primary">${Translations.Copy_General_Added}</span>${textOutput}`
    } else if(logItem.actionId === 2){
        textOutput = `<span class="badge badge-success">${Translations.Copy_General_Updated}</span> ${textOutput}`
    } else if(logItem.actionId === 3){
        textOutput = `<span class="badge badge-danger">${Translations.Copy_General_Deleted}</span> ${textOutput}`
    }
    const txt = document.createElement('textarea');
	txt.innerHTML = textOutput;
	return txt.value;
};

const ChangeLog = (props) => {
    if(props.changeLog.data.length === 0){
        return <div className="log">{Translations.Copy_Dashboard_NoActivities}</div> 
    }

    const filtered = props.changeLog.showCount && props.changeLog.showCount > 0 ? props.changeLog.data.slice(0, props.changeLog.showCount) : props.changeLog.data
    return <section>
            <div className="startpage__changelog">
                <div className="logs">
                    {filtered.map((l, i) => (l.text && l.text.length > 0)? 
                    <div key={i} className="log" dangerouslySetInnerHTML={{__html: decodeHTML(l)}} ></div>
                    : 
                    null)}
                </div>
            </div>
    
                    {props.changeLog.data.length > filtered.length ? 
                    <button className="btn btn-block" onClick={() => props.onShowMoreClick()} style={{justifyContent: 'center'}}>
                        {Translations.Copy_General_ShowMore}
                        </button>
                        :
                        null
                        }
        </section>
}

export default ChangeLog;