import { ColourStandardActionTypes } from '../actions/colourStandardActions'
import filterModel from '../models/filterModel';
import datatableModel from '../models/datatableModel';

const initialState = {
    colourStandards: {
        ...datatableModel,
        headers: [],
        data: [],
        order: [],//{column: 6, dir: 'desc'}
    },
    colourLists: [],
    filter: {
        ...filterModel,
        hideShowInactiveControl: false
    },
    tagField: {
        initialized: false, //Needed for now, as whitelist for Tagify in SearchPanel need to wait for tagField.suggestions to be loaded. Cannot be reinitialized some how
        preselected: [],
        suggestions: [],
        tagSearch: true
    }
}

const colourStandardReducer = (state = initialState, action) => {
    switch(action.type){
        case ColourStandardActionTypes.FETCHED_COLOUR_STANDARDS:{
            return {
                ...state,
                colourStandards: {
                    ...state.colourStandards,
                    data: action.data,
                    headers: action.headers,
                    recordsFiltered: action.recordsFiltered,
                    recordsTotal: action.recordsTotal,
                    order: action.order,
                    skip: action.skip,
                    take: action.take
                },
                filter: {
                    ...state.filter
                },
                colourLists: action.colourLists,
                tagField: {
                    initialized: true,
                    preselected: action.tagField.Preselected.split(','),
                    suggestions: action.tagField.Suggestions,
                    tagSearch: action.tagField.TagSearch
                }
            }
        }

        case ColourStandardActionTypes.CS_UPDATE_FILTER:
            return {
                ...state,
                filter: {
                    ...action.filter
                }
            }

        default:
            return state
    }
}
export default colourStandardReducer