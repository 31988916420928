import buildUniqueActionTypes from '../../utils/actionTypeUtils'

export const ExportActionTypes = buildUniqueActionTypes([
                                  'SET_EXPORT_URL'
                                ])

export function setExportUrl(exportUrl){
    return {
      type: ExportActionTypes.SET_EXPORT_URL,
      exportUrl
    }
}