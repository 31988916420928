import ajax from '../utils/ajax';
export let Translations = {};

class Translator {
    init(callback) {

        ajax({
            url: '/jsapi/translations/getbyculture',
            type: 'GET',
            error: (data) => {
                console.log('Translations error:', data);
            },
            success: (data) => {
                if (typeof callback === 'function') {
                    //console.log(`Getting translations for: ${data.Culture}`);
                    Translations = data.translations;
                    callback();
                }
            }
        });
    }
}
export default Translator;