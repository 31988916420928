import React, { useState } from 'react';
import validate from 'validate.js';
import { Translations } from '../../../../utils/translator';
import DropDownList from '@ncscolour/ncs-ui-utils/components/DropDownList'

const CreateCompanySupervisor = (props) => {
    const [validationResult, setValidationResult] = useState(null)
    const [newSupervisorState, setSupervisorToEdit] = useState(props.newSupervisor)

    const checkFields = (fieldKey) => {
        return (validationResult && validationResult[fieldKey] && validationResult[fieldKey].length > 0)
    }

    const validationMessage = (fieldKey) => {
        return checkFields(fieldKey)?
                <div className="validation-message"><p className="help-block error">{validationResult[fieldKey].join(' ')}</p></div>
                :
                null
    }

    const updateSupervisor = (propName, value) => {
        const newSupervisorStateCopy = {...newSupervisorState};
        newSupervisorStateCopy[propName] = value;
        newSupervisorStateCopy.isValid = validateSupervisor(newSupervisorStateCopy) //TODO: Hack for now until rebuild it all
        setSupervisorToEdit(newSupervisorStateCopy)
        props.onChange(newSupervisorStateCopy)
    }

    const validateSupervisor = (supervisor) => {
        const validationConstraints = {
                        contactName: {
                            presence: {
                                allowEmpty: false
                            }
                        },
                        email: {
                            email: true
                        },
                        country: {
                            presence: {
                                allowEmpty: false
                            }
                        }
                      }
        const validationResult = validate(supervisor, validationConstraints)
        setValidationResult(validationResult)
        return validationResult === undefined
    }


    const baseClass = 'form-group'
    const contactNameFormGroupClassName = checkFields('contactName') ? `${baseClass} has-error` : baseClass
    const emailFormGroupClassName = checkFields('email') ? `${baseClass} has-error` : baseClass

     return <section className="create-company-supervisor">
    <div>
         <div className={contactNameFormGroupClassName}>
                <label className="form-control-label">{Translations.Copy_NewUser_ContactName}</label>
                 <input 
                    value={newSupervisorState.contactName} 
                    onChange={(event) => updateSupervisor('contactName', event.currentTarget.value)} 
                    className="form-control text-box" 
                    type="text"
                 />
                 {validationMessage('contactName')}
            </div> 

            <div className={emailFormGroupClassName}>
                <label className="form-control-label">{Translations.Copy_General_Email}</label>
                <input 
                    value={newSupervisorState.email} 
                    onChange={(event) => updateSupervisor('email', event.currentTarget.value)} 
                    className="form-control text-box" 
                    type="text"
                 />
            </div>

            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_Adress}</label>
                <input 
                    value={newSupervisorState.postalAddress} 
                    onChange={(event) => updateSupervisor('postalAddress', event.currentTarget.value)} 
                    className="form-control text-box" 
                    type="text"
                 />
            </div>
            
            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_ZipCode}</label>
                <input 
                    value={newSupervisorState.zipCode} 
                    onChange={(event) => updateSupervisor('zipCode', event.currentTarget.value)} 
                    className="form-control text-box" 
                    type="text"
                 />
            </div>

            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_City}</label>
                <input 
                    value={newSupervisorState.city} 
                    onChange={(event) => updateSupervisor('city', event.currentTarget.value)} 
                    className="form-control text-box" 
                    type="text"
                 />
            </div>
            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_Country}</label>
                <DropDownList 
                    className="form-control" 
                    data={props.countries} 
                    selected={newSupervisorState.country} 
                    onChange={country => updateSupervisor('country', country)} 
                    />
 
            </div>
            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_Telephone}</label>
                <input 
                    value={newSupervisorState.telephone} 
                    onChange={(event) => updateSupervisor('telephone', event.currentTarget.value)} 
                    className="form-control text-box" 
                    type="text"
                 />
            </div>
         </div>
        </section>
}

export default CreateCompanySupervisor