import React, { useState } from 'react'
import Modal from '../../common/Modal'
import { Translations } from '../../../../utils/translator'
import DataTable from '../../common/DataTable'
import DatePicker from 'react-datepicker';
import SwitchButton from '../../common/SwitchButton';

import './manage-profiles.scss'

const ManageProfiles = (props) => {
    const [selectedProfileId, setSelectedProfileId] = useState(null)
    const [expiryDate, setExpiryDate] = useState('')
    const [profileName, setProfileName] = useState('')
    const [statusEnabled, setStatusEnabled] = useState(false)
    const [hideOptimizeButton, setHideOptimizeButton] = useState(false)
    const profilesWithoutSamplesIds = props.profiles.data.filter(p => p.haveProfileSamples === false).map(p => p.id)
    const deleteAllowedIds = props.profiles.data.filter(p => p.deleteAllowed).map(p => p.id)

    const config = {
        'expiryDate': (ExpiryDate, isHeader, _selected, item) => {
            if(isHeader){
                return <span>{Translations.Copy_General_Expired}</span>
            } else {
                return <span className={ item.expired === false ? 'column-ok' : 'column-danger'}>{ExpiryDate}</span>
            }
        },
        'id': (id, isHeader) => {
            if(isHeader){
                return 
            } else {
                return <span className="action-area">
                    {profilesWithoutSamplesIds.indexOf(id) === -1 ? <a href="#" className="link-button" onClick={() => props.downloadProfileMeasurement(id, false)}>{Translations.Copy_Instrument_Download_Sample}</a> : null}
                    <a href="#" className="link-button" onClick={() => props.downloadProfileMeasurement(id, true)}>{Translations.Copy_Instrument_Download_Reference}</a>
                    {deleteAllowedIds.indexOf(id) >= 0 ? <button className="btn btn-danger" onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setSelectedProfileId(null)
                        props.onDelete(id)
                    }}>Delete</button> : null}
                    </span>
            }
        }
    }

    

     return <Modal title={Translations.Copy_Instrument_ManageProfiles_Title}>
            <section className="manage-profiles">
            <DataTable
              config={config} 
              tableData={props.profiles}
              selectedItems={[]}
              hideBottomRow={true}
              selectProp={'id'}
              selectRow={profileId => {
                  const selectedProfile = {...props.profiles.data.find(p => p.id === profileId)}
                  const expirydate = selectedProfile.expiryDate.split('-');
                  const date = new Date(expirydate[0], expirydate[1] - 1, expirydate[2])
                  setSelectedProfileId(selectedProfile.id)
                  setExpiryDate(date)
                  setStatusEnabled(selectedProfile.status === 'Ready')
                  setProfileName(selectedProfile.labelShort)
                  const profileHaveNoSamples = profilesWithoutSamplesIds.indexOf(selectedProfileId) >= 0
                  const profilePendingState = ['pending', 'waitingforsupplier'].indexOf(selectedProfile.status.toLowerCase()) >= 0
                  setHideOptimizeButton(profilePendingState || profileHaveNoSamples)
              }}
             />
             {(selectedProfileId !== null && profilesWithoutSamplesIds.indexOf(selectedProfileId) === -1) ? <div className="manage-profiles__editable-row form-group">
                    <div>
                        <label className="form-control-label">{Translations.Copy_Instrument_Profile_Selected}:</label>
                        <span>{profileName}</span>
                    </div>
                    <div>
                        <label className="form-control-label">{Translations.Copy_Instrument_ExpiryDate}:</label>
                        <DatePicker
                                className="form-control date-control"  
                                selected={expiryDate}
                                dateFormat="yyyy-MM-dd"
                                onChange={(expiryDate) => {
                                    setExpiryDate(expiryDate)
                                }}
                                />
                    </div>
                    <div>
                        <label className="form-control-label">{Translations.Copy_Instrument_Toggle_Enable}:</label>
                        <SwitchButton 
                            inactiveText={Translations.Copy_General_Stopped} 
                            activeText={Translations.Copy_General_Ready}
                            isOn={statusEnabled} 
                            onChange={value => setStatusEnabled(value)}
                        />
                    </div>
                    {(hideOptimizeButton === false && props.enableOptimize === true) ? <button className="btn btn-primary" style={{marginRight: '10px'}} onClick={()=> {
                        document.body.classList.add('body-wait')
                        window.location = `/OptimizeConnection/${props.instrumentSettingsId}/${selectedProfileId}`
                    }}>
                    Optimize
                    </button> : null}
                    <button 
                        className="btn btn-primary" 
                        onClick={() => props.onApply(selectedProfileId, expiryDate, statusEnabled, props.instrumentSettingsId)}
                    >{Translations.Buttons_General_Apply}</button>
                </div> : null}
            </section>
            <div className="form-group buttons">
                <button className="btn btn-secondary" onClick={props.onClose}>{Translations.Buttons_General_CloseButton}</button>
            </div> 
         </Modal>
}

export default ManageProfiles