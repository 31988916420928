import React, { useState, useEffect } from 'react';   
import { connect } from 'react-redux';
import Guide from '../../components/common/Guide'; 
import CreateAssignmentStep1 from '../../components/assignment/create/create-assignment-step1'; 
import CreateAssignmentStep2 from '../../components/assignment/create/create-assignment-step2'; 
import CreateAssignmentStep3 from '../../components/assignment/create/create-assignment-step3'; 
import CreateAssignmentStep4 from '../../components/assignment/create/create-assignment-step4'; 
// import Modal from '../../components/common/Modal'; 
import { Translations } from '../../../utils/translator';
import { 
    saveAssigmentStep1, 
    fetchProjects, 
    showCreateProjectModal, 
    hideCreateProjectModal, 
    createNewProject, 
    fetchSuppliers,
    updateSelectedSuppliersList, 
    fetchAvailableColours,
    updateFilter,
    clearFilter,
    updateSelectedColours,
    createAssignment,
    selectAllColours,
    // fetchAllColours,
    selectAllSuppliers
} from '../../actions/createAssignmentActions';
import { bindActionCreators } from 'redux';
import parseGlossValue from '../../../utils/gloss';

import { returnToPreviousStep, goToNextStep } from '../../actions/guideActions';

const CreateAssignmentContainer = (props) => {
    const [finalSaveIsClicked, setFinalSaveIsClicked] = useState(false)
    const [isInitialized, setIsInitialized] = useState(false)

     useEffect(() => {
         if(isInitialized === false) {
            props.fetchProjects()
            props.fetchSuppliers()
            setFinalSaveIsClicked(false);

            window.addEventListener('beforeunload', e => {
                if(finalSaveIsClicked === false && props.assignment.name.length > 0){
                    e.preventDefault(); //Will show built in native browser dialog, which is not possible to interact with as I know?
                    e.returnValue = '';
                    return '';
                }
            });
            setIsInitialized(true)
         }

     },[isInitialized, finalSaveIsClicked, props]);

   const handleStepNr = (currentStep) => {
        switch (currentStep) {
            case 1:{
                if(props.assignment.isValid === true){
                    props.saveAssigmentStep1(props.assignment)
                    props.fetchAvailableColours(props.availableColours.skip, props.availableColours.take, props.filter, props.availableColours.order, props.availableColours.headers);
                    props.goToNextStep();
                }
                break;
            }
            case 2:
                props.goToNextStep();
                break;
            case 3:
                props.goToNextStep();
                break;
            case 4:
                // eslint-disable-next-line no-case-declarations
                const selectedColoursCopy = {...props.selectedColours}
                Object.values(selectedColoursCopy).forEach(colour => {
                    colour.Gloss60 = parseGlossValue(colour.Gloss60);
                });
                props.createAssignment(props.assignment, Object.values(selectedColoursCopy).filter(c => c.Selected), props.projects);
                break;    
            default:
                break;
        }
    }

        return <div className={props.loading === true ? 'capq_spinner' : ''}>
            <div className="assignment-create">
            <Guide 
                title={Translations.Copy_Assignment_Create_Title + ` (${props.currentStep}/4)`}
                onPreviousClicked={props.returnToPreviousStep}
                onCancelClick={()=>window.location.href = '/V2/Assignment'}
                totalSteps={4}
                currentStep={props.currentStep}
                onNext={()=> handleStepNr(props.currentStep)}
            >
         
                <CreateAssignmentStep1 
                    currentStep={props.currentStep} 
                    assignment={props.assignment} 
                    projects={props.projects} 
                    suppliers={props.suppliers}
                    modalVisible={props.modalVisible}
                    onChange={props.saveAssigmentStep1}
                    showModal={props.showModal}
                    hideModal={props.hideModal}
                    createNewProject={props.createNewProject}
                    updateSelectedSuppliersList={props.updateSelectedSuppliersList}
                    selectAllSuppliers={ props.selectAllSuppliers}
                    onSelectAllSuppliers={props.onSelectAllSuppliers}
                    // onInit={validatorCallback => setValidatorCallback(() => validatorCallback)}
                />

                <CreateAssignmentStep2 
                    currentStep={props.currentStep} 
                    selectedColours={props.selectedColours}
                    availableColours={props.availableColours}
                    fetchAvailableColours={props.fetchAvailableColours}
                    updateFilter={props.updateFilter}
                    clearFilter={props.clearFilter}
                    filter={props.filter}
                    updateSelectedColours={props.updateSelectedColours}
                    selectAllColours={props.selectAllColours}
                    onSelectAllColours={props.onSelectAllColours}
                    
                />

                <CreateAssignmentStep3 
                    currentStep={props.currentStep}  
                    selectedColours={props.selectedColours}
                    materials={props.availableColours.materials}
                    toleranceSetList={props.availableColours.toleranceSetList}
                    updateSelectedColours={props.updateSelectedColours}/>

                <CreateAssignmentStep4 
                    currentStep={props.currentStep} 
                    assignment={props.assignment}
                    selectedColours={props.selectedColours}
                    projects={props.projects}
                    materials={props.availableColours.materials}
                    toleranceSetList={props.availableColours.toleranceSetList}/> 
            </Guide>   
            </div>
            
            </div>   
}


const mapStateToProps = (state) => { 
    return {
        currentStep: state.guide.currentStep,
        assignment: state.createAssignmentPage.assignment,
        projects: state.createAssignmentPage.projects,
        modalVisible: state.createAssignmentPage.modalVisible,
        suppliers: state.createAssignmentPage.suppliers,
        selectAllSuppliers: state.createAssignmentPage.selectAllSuppliers,
        availableColours: state.createAssignmentPage.availableColours,
        loading: state.createAssignmentPage.loading || state.createAssignmentPage.loadingAV,
        filter: state.createAssignmentPage.filter,
        selectedColours: state.createAssignmentPage.selectedColours,
        selectAllColours: state.createAssignmentPage.selectAllColours
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    saveAssigmentStep1,
    showModal: showCreateProjectModal,
    hideModal: hideCreateProjectModal,
    createNewProject,
    updateSelectedSuppliersList,
    fetchAvailableColours,
    updateFilter,
    clearFilter,
    returnToPreviousStep,
    goToNextStep,
    updateSelectedColours,
    createAssignment,
    onSelectAllColours: selectAllColours,
    // fetchAllColours:(cacheGuids)=> fetchAllColours(cacheGuids),
    onSelectAllSuppliers: selectAllSuppliers,
    fetchProjects,
    fetchSuppliers
}, dispatch)


export default connect(
    mapStateToProps,
    mapDispatchToProps
  ) (CreateAssignmentContainer)