import datatableModel from '../models/datatableModel'
import filterModel from '../models/filterModel'
import { ConnectionActionTypes } from '../actions/connectionActions'

const initialState = {
    connectionList: {
        ...datatableModel,
        headers: [],
        data: [],
        order: []
    },
    filter: {
        ...filterModel,
        geometry: '',
        instrumentModelId: null
    },
    connectionDetails: { 
        ...datatableModel,
        headers: [],
        data: [],
        order: []
    },
    instrumentModels: {},
    connectionResultData: [],
    connectionResultTable: {},
    reproducibility: {},
    geometryList: {}
}

const connectionReducer = (state = initialState, action) => {
    switch (action.type) {
            case ConnectionActionTypes.GET_CONNECTIONS_SUCCESS: {
                const models = action.instrumentModels
                models.push({Value: '', Text: 'Show all'})
                const geometries = action.geometryList
                geometries.push({Value: '', Text: 'Show all'})
                return {
                    ...state,
                    connectionList: {
                        ...state.connectionList,
                        data: action.data,
                        headers: action.headers,
                        recordsFiltered: action.recordsFiltered,
                        localReferenceExist: action.localReferenceExist,
                        recordsTotal: action.recordsTotal,
                        skip: action.skip,
                        take: action.take,
                        order: action.order
                    },
                    instrumentModels: models,
                    geometryList: geometries
                }
            }
  
            
            case ConnectionActionTypes.GET_CONNECTION_SUCCESS: 
                return {
                    ...state,
                    connectionDetails: action.connectionDetails
                }

                case ConnectionActionTypes.GET_CONNECTION_RESULT_SUCCESS: 
                return {
                    ...state,
                    connectionResultData: action.connectionResultData,
                }
                case ConnectionActionTypes.GET_CONNECTION_RESULT_TABLE_SUCCESS: 
                return {
                    ...state,
                    connectionResultTable: action.connectionResultTable,
                }
                case ConnectionActionTypes.GET_REPRODUCIBILITY_RESULT_SUCCESS: 
                return {
                    ...state,
                    reproducibility: action.reproducibility
                }


                case ConnectionActionTypes.GET_CONNECTIONS: 
                return {
                    ...state,
                    filter: action.filter
                }
            

        default:
            return state
    }
}
export default connectionReducer