import StorageKeys from './storage-keys';
import config from './config';

class StorageService {
    updateStorageForCompany() {
        if (this.userHasChanged()) {
            this.updateLoggedInCompanyID(config.loggedInCompanyID);
        }
    }

    updateLoggedInCompanyID(id) {
        sessionStorage.setItem(StorageKeys.LoggedInCompanyID, id);
    }

    userHasChanged() {
        return config.loggedInCompanyID !== parseInt(sessionStorage.getItem(StorageKeys.LoggedInCompanyID));
    }
}
export default StorageService;