import React from 'react';
import { Translations } from '../../../../utils/translator';
import DataTable from '../../common/DataTable'

import './company-list.scss';

const CompanyList = (props) => {

    const config = {
        'ID': (id, isHeader) => {
            if(isHeader){
                return null
            } else {
                return null
            }
        },
        'Disabled': (disabled, isHeader) => {
            if(isHeader){
                return <span className="cell-centered">Disabled</span>
            } else {
                return disabled === true ? <i className="cell-centered fas fa-check"></i> : null
            }
        }
    }

     return <section>
                <div className="button-row">
                    <a className="btn btn-primary" href="/V2/Company/CreateCompany"><i className="fas fa-plus"></i>{Translations.Copy_Company_Create_Button}</a>
                </div>
                <DataTable
                config={config}
                tableData={props.companyList}
                selectedItems={[]}
                selectProp={'ID'}
                selectRow={(id)=>{
                    window.location = `/V2/Company/EditCompany/${id}`
                }
                }
                navigateToNewPage={(selectedPageInfo) => props.getCompanyList(selectedPageInfo.selected * props.companyList.take, props.companyList.take)}
                updatePageSize={newSize => props.getCompanyList(0, newSize)}
            />
        </section>
}

export default CompanyList