import React, {useEffect, useState} from 'react';   /* eslint-disable-line */
import DataTable from '../../common/DataTable';
import SearchPanel from '../../common/SearchPanel';
import { Translations } from '../../../../utils/translator';


const CreateAssignmentStep2 = (props) => {  
        const [clearFilterBridge, setClearFilterBridge] = useState(null)
        const [selectedColours, setSelectedColours] = useState(props.selectedColours)

        let myRef = React.createRef();  
        const config = {
            'ColourStandardId': (id, isHeader, isSelected) => {
                if(isHeader){
                    return <input ref={myRef} type="checkbox" className="js_selectAllColours_Checkbox" checked={props.selectAllColours} onChange={selectAllColours}/>
                } else {
                    return <input type="checkbox" id={id} checked={isSelected || (selectedColours[id] !== undefined)} onChange={(event) => updateSelectedColoursList(event.currentTarget.id)}/>
                }
            },
            'HtmlColour': (colour, isHeader) => {
                if(isHeader){
                    return
                } else {
                    return <span className="iconHTML" style={{backgroundColor: colour }}></span>
                }
            },
        }

        useEffect(()=>{
            if(myRef.current !== null){
                const ids = props.availableColours.data.map(i=>{return i.ColourStandardId});
                let checkbox = myRef.current;
                let checkIfIndetermenate = 0;
                let checkIfAllIsSelected = true;
                for (let i = 0; i < ids.length; i++) {
                    const element = ids[i];
                    if(props.selectedColours[element] === undefined){
                        checkIfAllIsSelected = false
                    } else {
                        checkIfIndetermenate++;
                    }          
                }
                if(checkIfIndetermenate > 0){
                    checkbox.indeterminate = true;
                } else {
                    checkbox.indeterminate = false;
                }
                if(!checkIfAllIsSelected && Object.keys(props.selectedColours).length > 0){
                    checkbox.indeterminate = true;
                } else {
                    checkbox.indeterminate = false;
                }
                props.onSelectAllColours(checkIfAllIsSelected); 
            }          
        },[props, props.selectedColours, props.availableColours.skip, props.availableColours.take, myRef])


    const updateSelectedColoursList = (id) => {
        const listToUpdate = {...selectedColours};
        let itemToAdd = props.availableColours.data.filter((item)=> item.ColourStandardId == id);
        let checkbox = myRef.current;
        if(typeof props.updateSelectedColours === 'function'){
                if(listToUpdate[id] != undefined ){
                    delete listToUpdate[id];
                    checkbox.indeterminate = Object.keys(listToUpdate).length > 0;
                    if (Object.keys(listToUpdate).length === 0) {
                        checkbox.checked = false;
                    }
                } else {
                    if(itemToAdd.length > 0){
                        listToUpdate[id] = itemToAdd[0];
                        listToUpdate[id].Selected = true;
                        checkbox.indeterminate = true;
                    }
                }
            setSelectedColours(listToUpdate)
            props.updateSelectedColours(listToUpdate);
        }
    }

    const selectAllColours = (event) =>{
        const listToUpdate = {...props.selectedColours};
        const items = props.availableColours.data.map(i=>{return i});
        for (let i = 0; i < items.length; i++) {
            const element = items[i];
            if(event.currentTarget.checked){
                listToUpdate[element.ColourStandardId] = element;
                listToUpdate[element.ColourStandardId].Selected = true;
            } else {
                if(listToUpdate[element.ColourStandardId] !== undefined){
                    delete listToUpdate[element.ColourStandardId];
                } 
            }          
        }
        if(Object.keys(listToUpdate).length > 0 && !event.currentTarget.checked){
            let checkbox = myRef.current;
            checkbox.indeterminate = true;
        }

        setSelectedColours(listToUpdate)
        props.updateSelectedColours(listToUpdate);
        props.onSelectAllColours(!props.selectAllColours);
    }

    const handleNavigation = (selectedPageInfo) => {
        props.fetchAvailableColours(selectedPageInfo.selected * props.availableColours.take, props.availableColours.take, props.filter, props.availableColours.order, props.availableColours.headers);
    }
    
    const updatePageSize = (newSize) =>{
        props.fetchAvailableColours(0, newSize, props.filter, props.availableColours.order, props.availableColours.headers);
    }
    
    return <div>
        <h2>{Translations.Copy_Assignment_Create_SelectColours}</h2>
        <SearchPanel 
            filter={props.filter}
            onChange={(filter)=> { 
                props.fetchAvailableColours(0, props.availableColours.take, filter, props.availableColours.order, props.availableColours.headers);
                props.updateSelectedColours({});
                props.onSelectAllColours(false);
            }}
            onInit={(clearFilterCallback) => {
                setClearFilterBridge(() => clearFilterCallback)
            } }
            />
        <DataTable 
            config={config} 
            tableData={props.availableColours} 
            navigateToNewPage={handleNavigation}
            updatePageSize={updatePageSize}
            selectedItems={props.selectedColours}
            selectRow={updateSelectedColoursList}
            selectProp={'ColourStandardId'}
            updateSortColumnOrder={(order) => {
                props.fetchAvailableColours(props.availableColours.skip, props.availableColours.take, props.filter, order, props.availableColours.headers)
            }}
            onClearFilter={() => {
                if(clearFilterBridge){
                    clearFilterBridge()
                }
            }}
            />
    </div>

}   

export default CreateAssignmentStep2