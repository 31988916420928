import React from 'react'
import Modal from '../../common/Modal'
import { Translations } from '../../../../utils/translator'

const LockUser = (props) => {
     return <Modal
            title={Translations.Copy_EditUser_LockUnlockUser}
            >
            
            <p>
                {props.isLocked ? Translations.Copy_EditUser_Unlock_Message : Translations.Copy_EditUser_Lock_Message}: {props.userName}?
            </p>

            <div className="form-group buttons">
                <button className="btn btn-secondary" onClick={props.onClose}>{Translations.Copy_General_No}</button>
                <button className="btn btn-primary" onClick={()=>props.isLocked === true ? props.onUnlock() : props.onLock()}>{Translations.Copy_General_Yes}</button>
            </div> 
         </Modal>
}

export default LockUser