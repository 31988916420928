import { StartPageActionTypes } from '../actions/startPageActions'
import { Translations } from '../../utils/translator'

const CHANGELOG_SHOW_COUNT = 5;

const initialState = {
    loadingLu: false,
    loadingDl: false,
    latestUploads: [],
    changeLog: {
        today:{
            title: '',
            showCount: CHANGELOG_SHOW_COUNT,
            data: []
        },
        earlier: {
            title:'',
            showCount: CHANGELOG_SHOW_COUNT,
            data: []
        }
    },
    daysBack: 0
};

const startPageReducer = (state = initialState, action) => {

    switch(action.type){
        case StartPageActionTypes.LOADING_LU:
            return {
                ...state,
                loadingLu: true
            }
        
        case StartPageActionTypes.LOADING_DL:
                return {
                    ...state,
                    loadingDl: true,
                    daysBack: action.daysBack
                }

        case StartPageActionTypes.FETCHED_LU:
            return {
                ...state,
                loadingLu: false,
                latestUploads: action.data
            }

        case StartPageActionTypes.FETCHED_DL:
                return {
                    ...state,
                    loadingDl: false,
                    changeLog: getGroupedChangeLog(action.data, state.daysBack),
                }

        case StartPageActionTypes.SHOW_MORE_DL:{
            const copyChangeLog = { ...state.changeLog }
            copyChangeLog[action.changeLogKey].showCount += CHANGELOG_SHOW_COUNT
            return {
                    ...state,
                    changeLog: copyChangeLog
                }
        }
         
        default: 
            return state
    }
}


//Helpers
const getGroupedChangeLog = (dataArray, daysBack) => {
    const todayKey = 'today';
    const earlierKey = 'earlier';
    const initialObject = {};
    initialObject[todayKey] = {
        title: Translations.Copy_General_Today,
        showCount: CHANGELOG_SHOW_COUNT,
        data: []
    }
    initialObject[earlierKey] = {
        title: Translations.Copy_Dashboard_Template_LastXDays.format(daysBack),
        showCount: CHANGELOG_SHOW_COUNT,
        data: []
    }
    const result = dataArray.reduce((acc, item) => {
        if(item.isToday === true){
            acc[todayKey].data.push(item);
        } else {
            acc[earlierKey].data.push(item);
        }
        return acc;
    }, initialObject);
    return result;
}
export default startPageReducer;