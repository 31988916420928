/*global $*/
/*global dataLayer */
import 'promise-polyfill/src/polyfill'; //For older IE etc.
import './polyfills/replace-with'; //For IE11 etc. element.ReplaceWith() is used when loading React initially in component-loader.js
import './polyfills/string-format'; //For easier use with our CAPQ-translation templates
import './polyfills/array_includes';
import './polyfills/array_find';
import './polyfills/object_assign';
import './polyfills/starts-with';
import './extensions/format';
import '../sass/index.scss';
import config from './utils/config';
import {qsa} from './utils/dom';

global.jQuery = global.$ = require('jquery'); /* eslint-disable-line */
import 'jquery-validation';
require('bootstrap');                       /* eslint-disable-line */
require('datatables.net');              /* eslint-disable-line */
require('datatables.net-bs4');              /* eslint-disable-line */


import ComponentLoader from './component-loader';
import Translator from './utils/translator';
import StorageService from './utils/storage-service';
import GTMEventsService from './utils/gtm-events-service';


//Init any tooltips that might be placed on page.
$(function () {
    const tooltips = qsa(document, '[data-toggle="tooltip"]');
    tooltips.forEach(tt => {
        $(tt).tooltip();
        
        //Hack needed (Bootstrap tooltip buggy) to get tooltip to disappear after some time
        //as sometimes on mouse out it does not.
        //Even CSS hack is applied as well in common.scss, as with much padding it works better.
        $(tt).on('show.bs.tooltip', function() {
            setTimeout(() => {
                $(this).tooltip('hide');
            }, 4000);
        });
    });
});

const storageService = new StorageService();
storageService.updateStorageForCompany();

if(typeof dataLayer !== 'undefined') { //dataLayer is defined in _Layout if not debug mode
    const gtmEventsService = new GTMEventsService(config.loggedInCompanyID, config.loggedInCompanyName, config.loggedInUserID, config.loggedInUserName);
    gtmEventsService.setupEvents();
}


const translator = new Translator();
translator.init(() => {
    const componentLoader = new ComponentLoader(document.body);
    componentLoader.loadComponents();
});