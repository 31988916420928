import { UtilsActionTypes } from '../actions/utilsActions'

const initialState = {
    timezones: {},
    countries: {}
}

const utilsReducer = (state = initialState, action) => {
    switch (action.type) {

            case UtilsActionTypes.GET_TIMEZONES_SUCCESS:
                return {
                    ...state,
                    timezones: action.timezones
                }
            case UtilsActionTypes.GET_COUNTRIES_SUCCESS:
                return {
                    ...state,
                    countries: action.countries
                }   
            
        default:
            return state
    }
}
export default utilsReducer