import React, { useState, useEffect, useRef } from 'react'
import CapqLogo from '../../CapqLogo'

import './login-form.scss'

const LoginForm = (props) => {
    const focusElementRef = useRef()
    const [className, setClassName] = useState('login-form')
    const [credentials, setCredentials] = useState({userName: '', password: ''})

    useEffect(() => {
        setClassName('login-form login-form--visible')
        focusElementRef.current.focus();
    }, [])

    const submitForm = () => {
        if(credentials.userName.length === 0 || credentials.password.length === 0){
            return
        }
        focusElementRef.current.focus();
        props.onSubmit(credentials.userName, credentials.password)
    }

    return <section className={className}>
        <section className="form-group form-group--centered">
            <CapqLogo />
            <h2>Welcome</h2>
            <p>Log in to your CAPQ account.</p>
        </section>
        <div className="form-group">
            <label htmlFor="username">Email</label>
            <input 
                ref={focusElementRef}
                type="text" 
                className="form-control" 
                id="username" 
                name="username" 
                value={credentials.userName} 
                onKeyUp={event => {
                    if(event.key.toLowerCase() === 'enter'){ //event.keyCode is deprecated!
                        submitForm()
                    }
                }}
                onChange={(e) => setCredentials({
                    ...credentials,
                    userName: e.target.value
                })} 
            />
        </div>

        <div className="form-group">
            <label htmlFor="password">Password</label>
            <input 
                type="password" 
                className="form-control" 
                id="password" 
                name="password" 
                value={credentials.password} 
                onKeyUp={event => {
                    if(event.key.toLowerCase() === 'enter'){ //event.keyCode is deprecated!
                        submitForm()
                    }
                }}
                onChange={(e) => setCredentials({
                    ...credentials,
                    password: e.target.value
                })} 
            />
            {props.errorMessage && props.errorMessage.length > 0 ? <p className="error">{props.errorMessage}</p> : null}
        </div>
        
        <div className="form-group buttons">
            {(props.onActionLinkClick && props.actionLinkText) ? <a tabIndex="4" href="#" onClick={e => {
                e.preventDefault()
                props.onActionLinkClick()
            }}>{props.actionLinkText}</a> : null}
            <button 
                className="btn btn-primary" 
                onClick={submitForm}
            >Login</button>
        </div>
    </section>
}

export default LoginForm