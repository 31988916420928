import React from 'react'
import Modal from '../../common/Modal'
import { Translations } from '../../../../utils/translator'

const DeleteUser = (props) => {

     return <Modal
            title={Translations.Copy_EditUser_DeleteUser}
            >
            <p>{`${Translations.Copy_EditUser_DeleteUser_Message} : ${props.userName}?`}</p>

            <div className="form-group buttons">
                <button className="btn btn-secondary" onClick={props.onClose}>{Translations.Copy_General_No}</button>
                <button className="btn btn-primary" onClick={props.onDelete}>{Translations.Copy_General_Yes}</button>
            </div> 
         </Modal>
}

export default DeleteUser