import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Url, { urlSearchKeys } from '../../../utils/url'
import { Translations } from '../../../utils/translator'
import SearchPanel from '../../components/common/SearchPanel'
import DataTable from '../../components/common/DataTable';
import { fetchConnectionList } from '../../actions/connectionActions'

import './connection-list.scss'

const ConnectionTypeIcon = (props) => {
    switch (props.instrumentConnectionType){
        case 0:
            return <i className="fas fa-globe" title="Global"></i>

        case 1:
            return null //Local

        case 2:
            return <i className="fas fa-globe-americas" title="Global Reference"></i>

        case 3:
            return <i className="fas fa-map-marker-alt" title="Local Reference"></i>

        default:
            return null
    }
}


const ConnectionListContainer = (props) =>  {
    const [clearFilterBridge, setClearFilterBridge] = useState(null)
    const [connectionType, setConnectionType] = useState(1)


    const config = {
        'ExpiryDate': (content, isHeader, _selected, item) => {
            if(isHeader){
                return content
            } else {
                return <span className={['ready', 'ready and pending'].indexOf(item.Status.toLowerCase()) >= 0 ? 'column-ok' : 'column-danger'}>{content}</span>
            }
        },
        'InstrumentModel': (content, isHeader, _selected, item) => {
            if(isHeader){
                return content
            } else {
                return <span style={{whiteSpace: 'nowrap'}}>
                    {content} <ConnectionTypeIcon instrumentConnectionType={item.InstrumentConnectionType} />
                </span>
            }
        }
    }

    const extraFilters = [ 
        {
            data: props.instrumentModels,
            title: Translations.Copy_Instrument_Model,
            selected: props.filter.instrumentModelId,
            filterKey: 'instrumentModelId',
            urlKey: 'InstrumentModel',
            urlKeyType: 'int',
            onChange: (value)=> {
                const filterState = {
                    ...props.filter,
                    instrumentModelId: value
                }
                Url.changeAddressQs(urlSearchKeys.InstrumentModel, `${filterState.instrumentModelId}`)
                props.fetchConnectionList(0, props.connectionList.take, filterState, props.connectionList.order, props.connectionList.headers);
            }
        },
        {
            data: props.geometryList,
            title: Translations.Copy_Instrument_Geometry,
            selected: props.filter.geometry,
            filterKey: 'geometry',
            urlKey: 'Geometry',
            urlKeyType: 'string',
            onChange: (value)=> {
                const filterState = {
                    ...props.filter,
                    geometry: value
                }
                Url.changeAddressQs(urlSearchKeys.Geometry, `${filterState.geometry}`)
                props.fetchConnectionList(0, props.connectionList.take, filterState, props.connectionList.order, props.connectionList.headers);
            }
        }          
    ]
console.log('props.authorizedToAdministrate', props.authorizedToAdministrate)
console.log('props.connectionList', props.connectionList)
        return <div className="connection-list">
            <div className="entity-header">
                    <h1>{Translations.Copy_General_InstrumentSettings}</h1>
                
                {props.isCoreSupport === true ? <div className="form-group button-row">
                        <label className="form-control-label">Creation type:</label>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="userRole" id="userRole1" value="1" defaultChecked onChange={(event) => setConnectionType(parseInt(event.currentTarget.value))} />
                            <label className="form-check-label">Global</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="userRole" id="userRole2" value="2" onChange={(event) => setConnectionType(parseInt(event.currentTarget.value))}/>
                            <label className="form-check-label">Local reference</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="userRole" id="userRole3" value="3" onChange={(event) => setConnectionType(parseInt(event.currentTarget.value))}/>
                            <label className="form-check-label">Local</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="userRole" id="userRole4" value="4" onChange={(event) => setConnectionType(parseInt(event.currentTarget.value))}/>
                            <label className="form-check-label">Global reference</label>
                        </div>
                        <button onClick={()=>{
                            switch (connectionType) {
                                case 1:
                                    window.location = '/MeasurementInstrument/RegisterGlobalInstrument'
                                    break
                                case 2:
                                    window.location = '/MeasurementInstrument/RegisterlocalReferenceInstrument'
                                    break
                                case 3:
                                    window.location = '/MeasurementInstrument/RegisterlocalInstrument'
                                    break
                                case 4:
                                    window.location = '/MeasurementInstrument/RegisterGlobalReferenceInstrument'
                                    break
                            }
                        }} className="btn btn-primary"><i className="fas fa-plus"></i> {Translations.Copy_Instrument_New_Button}</button>
                    </div>
                    
                 : (props.authorizedToAdministrate === true && props.connectionList.data.length > 0 && props.connectionList.localReferenceExist === true) ? <button onClick={() => window.location = '/MeasurementInstrument/RegisterlocalInstrument'} className="btn btn-primary"><i className="fas fa-plus"></i> {Translations.Copy_Instrument_New_Button}</button>
                                            : 
                                        null}
                </div>
                <SearchPanel 
                    filter={props.filter}
                    extraFilters={extraFilters}
                    onInit={(clearFilterCallback) => {
                        setClearFilterBridge(() => clearFilterCallback)
                    } }
                    onChange={filter => props.fetchConnectionList(0, props.connectionList.take, filter, props.connectionList.order, props.connectionList.headers)}
                />

                <DataTable 
                    config={config} 
                    tableData={props.connectionList} 
                    navigateToNewPage={(selectedPageInfo) => props.fetchConnectionList(selectedPageInfo.selected * props.connectionList.take, props.connectionList.take, props.filter, props.connectionList.order, props.connectionList.headers)}
                    updatePageSize={newSize => props.fetchConnectionList(0, newSize, props.filter, props.connectionList.order, props.connectionList.headers)}
                    selectedItems={[]}
                    selectRow={instrumentSettingsId => {
                        if(props.authorizedToAdministrate === true || props.isCoreSupport === true) window.location.href =  `/V2/Connection/ConnectionDetails/${instrumentSettingsId}`;
                    }}
                    selectProp={'InstrumentSettingId'}
                    noRecordsText={Translations.Copy_Instrument_No_Instruments}
                    noRecordsButtonText={Translations.Buttons_Instrument_NewInstrument}
                    noRecordsButtonHref="/V2/Instrument/Create"
                    updateSortColumnOrder={(order) => props.fetchConnectionList(props.connectionList.skip, props.connectionList.take, props.filter, order, props.connectionList.headers)}
                    onClearFilter={() => {
                        if(clearFilterBridge){
                            clearFilterBridge()
                        }
                    }}
                    />

            </div>
}

const mapStateToProps = (state) => {
    return {
        connectionList: state.connection.connectionList,
        filter: state.connection.filter,
        instrumentModels: state.connection.instrumentModels,
        geometryList: state.connection.geometryList,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchConnectionList
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConnectionListContainer)