import React, { useState } from 'react'
import { Translations } from '../../../../utils/translator'
import validate from 'validate.js'

const ChangePassword = (props) => {
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [validationResult, setValidationResult] = useState(null)

    const checkFields = (fieldKey) => {
        return (validationResult && validationResult[fieldKey] && validationResult[fieldKey].length > 0)
    }

    const validationMessage = (fieldKey) => {
        return checkFields(fieldKey)?
                <div className="validation-message"><p className="help-block error">{validationResult[fieldKey].join(' ')}</p></div>
                :
                null
    }

    const submitDisabled = () => {
        return props.oldPasswordRequired ? oldPassword.length <= 0 || newPassword.length <= 0 || newPassword !== repeatPassword : newPassword.length <= 0 || newPassword !== repeatPassword
    }

    const submitForm = () => {
        if(submitDisabled()){
            return
        }
        
        let passwords = {
            newPassword: newPassword,
        }
        let validationConstraints = {
            newPassword: {
                presence: {
                    allowEmpty: false
                },
                format:{
                    pattern: /^(?=.*[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-])[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]{6,150}$/, 
                    message: Translations.Validator_Password_Conditions
                }
           
            }
            };
        if(props.oldPasswordRequired){
            passwords = {
                ...passwords, 
                 oldPassword: oldPassword
            }
            validationConstraints = {
                ...validationConstraints,    
                    oldPassword: {
                        presence: {
                            allowEmpty: false
                        }
                    }
                }
            } 
            const hasErrors = validate(passwords, validationConstraints)
            setValidationResult(hasErrors)
            if(!hasErrors){
                props.onPasswordChange(props.userId, newPassword, oldPassword)
            }
    }

    const baseClass = 'form-group'
    const oldPasswordFormGroupClassName = checkFields('oldPassword') ? `${baseClass} has-error` : baseClass
    const newPasswordFormGroupClassName = checkFields('newPassword') ? `${baseClass} has-error` : baseClass

     return <section>
        { props.oldPasswordRequired ?
            <div className={oldPasswordFormGroupClassName}>
            <label className="form-control-label">{Translations.Copy_EditUser_OldPassword}:</label>
            <input className="form-control" value={oldPassword} onChange={(event) => setOldPassword(event.currentTarget.value)} type="password"/>
            {validationMessage('oldPassword')}
        </div> : null
        } 
         <div className={newPasswordFormGroupClassName}>
             <label className="form-control-label">{Translations.Copy_EditUser_NewPassword}:</label>
             <input 
             className="form-control" 
             type="password" 
             value={newPassword} 
             onChange={(event) => setNewPassword(event.currentTarget.value)} 
             onKeyUp={event => {
                if(event.key.toLowerCase() === 'enter'){ //event.keyCode is deprectated!
                    submitForm()
                }
             }}
             />
             {validationMessage('newPassword')}
         </div>
         <div className="form-group">
             <label className="form-control-label">{Translations.Copy_EditUser_RepeatPassword}:</label>
             <input 
             className="form-control" 
             type="password"
             value={repeatPassword} onChange={(event) => setRepeatPassword(event.currentTarget.value)} 
             onKeyUp={event => {
                if(event.key.toLowerCase() === 'enter'){ //event.keyCode is deprectated!
                    submitForm()
                }
             }}
             />
         </div>
         <div className="form-group buttons">
             <button className="btn btn-secondary" onClick={props.onClose}>{Translations.Copy_General_No}</button>
             <button 
             className="btn btn-primary" 
             onClick={submitForm}>{Translations.Copy_General_Yes}</button>
         </div>
         </section>
}

export default ChangePassword