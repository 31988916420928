import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { 
    updateInstrumentDescription, 
    getConnection, 
    getConnectionResult, 
    updateProfile, 
    deleteProfile,
    getReproducibilityResult, 
    getConnectionResultTable,
    downloadProfileMeasurement } from '../../actions/connectionActions'
import ConnectionDetails from '../../components/connection/ConnectionDetails'

const ConnectionDetailsContainer = (props) => {
    const { connectionDetails, instrumentSettingsId, getConnection } = props

    useEffect(() => {
        if(connectionDetails.instrumentSettingId !== parseInt(instrumentSettingsId)){
            getConnection(instrumentSettingsId)
        }
    }, [connectionDetails.instrumentSettingId, instrumentSettingsId, getConnection]);

    return props.connectionDetails.instrumentSettingId === parseInt(props.instrumentSettingsId) ? 
            <ConnectionDetails 
                connectionDetails={props.connectionDetails}
                updateInstrumentDescription={props.updateInstrumentDescription}
                connectionResultData={props.connectionResultData}
                connectionResultTable={props.connectionResultTable}
                reproducibility={props.reproducibility}
                deactivateProfile={props.deactivateProfile}
                getConnectionResult={props.getConnectionResult}
                getConnectionResultTable={props.getConnectionResultTable}
                getReproducibilityResult={props.getReproducibilityResult}
                updateProfile={props.updateProfile}
                deleteProfile={profileId => props.deleteProfile(profileId, instrumentSettingsId)}
                isAdmin={props.isAdmin}
                isSupervisor={props.isSupervisor}
                isCoreSupport={props.isCoreSupport}
                authorizedToAdministrate={props.authorizedToAdministrate}
                downloadProfileMeasurement={props.downloadProfileMeasurement}
            /> : null
}

const mapStateToProps = (state) => {
    return {
        connectionDetails: state.connection.connectionDetails,
        connectionResultData: state.connection.connectionResultData,
        connectionResultTable: state.connection.connectionResultTable,
        reproducibility: state.connection.reproducibility
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    updateInstrumentDescription,
    getConnection,
    getConnectionResult,
    getConnectionResultTable,
    getReproducibilityResult,
    updateProfile,
    deleteProfile,
    downloadProfileMeasurement
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConnectionDetailsContainer)