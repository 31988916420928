import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createCompany, setNewCompanyData } from '../../actions/companyActions'
import { Translations } from '../../../utils/translator'
import CreateCompany from '../../components/company/CreateCompany'
import { getTimezones, getCountries } from '../../actions/utilsActions'
import Guide from '../../components/common/Guide'
import CreateCompanySupervisor from '../../components/company/CreateCompanySupervisor/CreateCompanySupervisor'
import { returnToPreviousStep, goToNextStep } from '../../actions/guideActions';

const CreateCompanyContainer = (props) =>  {
    const dispatch = useDispatch()
    const [supervisor, setSupervisor] = useState(null)

    useEffect(()=> {
        if(Object.keys(props.timezones).length <= 0 && Object.keys(props.countries).length <= 0){
            dispatch(getTimezones())
            dispatch(getCountries())
        }
    });

    const goToStepNr = (currentStep) => {
        switch (currentStep) {
            case 1:{
                if(props.newCompany.isValid === true){
                    props.goToNextStep();
                }
                break;
            }
            case 2:{
                if(supervisor !== null && supervisor.isValid === true){
                    props.createCompany(props.newCompany, supervisor)
                }
                break;  
            }
                 
            default:
                break;
        }
    }
    
    return <div>
        <Guide 
            title={`${Translations.Copy_Company_Create_Title} (${props.currentStep}/2)`}
            onPreviousClicked={props.returnToPreviousStep}
            onCancelClick={() => window.location.href = '/V2/Company/CompanyList'}
            totalSteps={2}
            currentStep={props.currentStep}
            onNext={()=> goToStepNr(props.currentStep)}
            >
        <CreateCompany 
            timezones={props.timezones}
            countries={props.countries}
            newCompany={props.newCompany}
            onChange={props.setNewCompanyData}
        />
        <CreateCompanySupervisor
            countries={props.countries}
            newSupervisor={props.newSupervisor}
            onChange={setSupervisor}
        />
    </Guide>
</div>
}

const mapStateToProps = (state) => {
    return {
        timezones: state.utils.timezones,
        countries: state.utils.countries,
        currentStep: state.guide.currentStep,
        newCompany: state.company.newCompany,
        newSupervisor: state.company.newSupervisor
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    createCompany,
    getCountries,
    getTimezones,
    returnToPreviousStep,
    goToNextStep,
    setNewCompanyData 
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateCompanyContainer)