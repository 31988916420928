const  filterModel = {
    statusList: null, //Not actually a filter, this is content so should be moved
    hideShowInactiveControl: true,  //Not actually a filter, this is content or filter-config so should be moved
    hidePassFailControl: true,       //Not actually a filter, this is content or filter-config so should be moved
    
    colourListId: null,     //This is an actual filter
    supplierListId: null,    //This is an actual filter
    searchBox: '',          //This is an actual filter
    searchType: 'contains', //This is an actual filter
    tags: [],               //This is an actual filter
    showInactive: false     //This is an actual filter
}

export default {...filterModel};