import React from 'react'
import { useDispatch } from 'react-redux'
import CapqLogo from '../../components/CapqLogo'
import NcsLogo from '../../components/NcsLogo'
import ChangePassword from '../../components/account/ChangePassword'
import { changePassword } from '../../actions/accountActions'

const CreateNewPasswordContainer = (props) => {
    const dispatch = useDispatch()
    return <section className="login-page">
        <NcsLogo />
        <section className="login-page__form-container">
            <div className="login-form login-form--visible">
                <CapqLogo />
                <ChangePassword 
                userId={props.userId}
                oldPasswordRequired={false} 
                onClose={() => window.location.href = '/'} 
                onPasswordChange={(userId, newPassword, oldPassword) => {
                    dispatch(changePassword(userId, newPassword, oldPassword, true))
                }} 
                />
            </div>
            
        </section>
    </section>        
}

export default CreateNewPasswordContainer