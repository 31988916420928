import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
    deleteUser, 
    getUser, 
    lockUser, 
    unlockUser, 
    updateUser, 
    changePassword
 } from '../../actions/accountActions'
import { getCountries } from '../../actions/utilsActions'
import EditUser from '../../components/account/EditUser'

const EditUserContainer = (props) => {
    const dispatch = useDispatch()
    const userToEdit = useSelector(state => state.account.userToEdit)
    const inProgress = useSelector(state => state.account.inProgress)
    const showChangeModal = useSelector(state => state.account.showChangeModal)
    const showDeleteModal = useSelector(state => state.account.showDeleteModal)
    const showLockUnlockModal = useSelector(state => state.account.showLockUnlockModal)
    const countries = useSelector(state => state.utils.countries)

    useEffect(()=> {
        if(userToEdit.userId === ''){
            dispatch(getUser(props.userId))
            dispatch(getCountries())
        }
    }, [dispatch, userToEdit.userId, props.userId]);

    return userToEdit.userId !== '' ? <EditUser 
                userToEdit={userToEdit}
                showChangeModal={showChangeModal}
                showDeleteModal={showDeleteModal}
                showLockUnlockModal={showLockUnlockModal} 
                countries={countries}
                inProgress={inProgress}
                userId={props.userId}
                isCoreSupport={props.isCoreSupport}
                canEdit={props.canEdit}
                isSupervisor={props.isSupervisor}
                isLoggedInUser={props.isLoggedInUser}
                onSave={user => dispatch(updateUser(user))}
                lockUser={userId => dispatch(lockUser(userId))}
                unlockUser={userId => dispatch(unlockUser(userId))}
                changePassword={(userId, newPassword, oldPassword) => dispatch(changePassword(userId, newPassword, oldPassword))}
                deleteUser={userId => dispatch(deleteUser(userId))}
            /> : null
                
}

export default EditUserContainer