import React, { useState } from 'react'
import PaginationBox from '../Pagination/Pagination';
import Url from '../../../../utils/url'
import { Translations }  from '../../../../utils/translator';

import './datatable.scss';

const DataTable = (props) => {
    const [clickedRow, setClickedRow] = useState(null)

    const handlePageClick = (selectedPage) => {
        if(props.navigateToNewPage){
            props.navigateToNewPage(selectedPage);
        }
    }

    const handlePageSizeChange = (event) => {
        if(props.updatePageSize){
            props.updatePageSize(event.target.value);
        }
    }

    const selectRow = (id) => {
        if(props.selectRow){
            props.selectRow(id);
        }
    }

    const handleSortColumnClick = (id) => {
        const listToUpdate = [...props.tableData.order];
        const item = listToUpdate.find(o => o.column === id);
        if(item){
            switch (item.dir) {
                case 'asc':
                    item.dir = 'desc';
                    break;
                case 'desc':
                    listToUpdate.splice(listToUpdate.indexOf(item), 1);
                    break;
                default:
                    break;
            } 
        } else {
            listToUpdate.push({column: id, dir: 'asc'});
        }
        props.updateSortColumnOrder(listToUpdate);
    }   

    const renderHeaders = () => {
        return props.tableData.headers.map((header, index) => { 
            if(!header.Hidden){
                if(!header.Orderable) {
                    return <th key={header.ColumnData}>{renderData(header, header.ColumnData, true)}</th>
                } else {
                    return <th className="sorting" key={header.ColumnData} id={index} onClick={() => handleSortColumnClick(index)}>
                            {renderData(header, header.ColumnData, true)}
                            {renderOrderIcon(index)}
                        </th>
                    }
            } else {
                return null //To keep index correct
            }
        });
    }

    const renderData = (item, columnData, isHeader) => {
        const content = isHeader ? item.ColumnName :item[columnData]
        return props.config[columnData] ? props.config[columnData](content, isHeader, props.selectedItems[content] !== undefined, item) : content;
    }

    const renderOrderIcon = (index) => {
        let html = <i className="fas fa-sort table-sortimage"></i>;
        const orderItem = props.tableData.order.find(item => parseInt(item.column) === index);
        if(!orderItem) return html;

        switch (orderItem.dir) {
            case 'asc':
                html = <i className="fas fa-sort-up table-sortimage"></i>
                break;
            case 'desc':
                html = <i className="fas fa-sort-down table-sortimage"></i>
                break;
            default:
                break;
        }
        return html;
}
    
    const renderRows = () => {
            return props.tableData.data.map((row, i) => {
               const clickedClassName = i === clickedRow ? ' selected' : ''
               const oddEvenClassName = i % 2 === 0 ? 'even' : 'odd'
               return <tr id={row[props.selectProp]} key={`Tr_${i}`} className={`${oddEvenClassName}${clickedClassName}`} onClick={() => {
                        setClickedRow(i)   
                        selectRow(row[props.selectProp])
                    }}>
                   {props.tableData.headers.map(h => { //At the moment the headers decide if current column-cell in row is visible or not
                       if(!h.Hidden){
                        return <td key={h.ColumnData + i}>{renderData(row, h.ColumnData, false)}</td>
                       } else {
                           return null
                       }
                   })}
            </tr>
        })
    }
    

    const renderDataTableBottom = () => {

        let valueTo = parseInt(props.tableData.skip) + parseInt(props.tableData.take);
        if(valueTo > props.tableData.recordsFiltered){
            valueTo = props.tableData.recordsFiltered
        }
        const totalpages = Math.ceil(props.tableData.recordsFiltered / props.tableData.take)
        const initialPage =  totalpages - Math.ceil((props.tableData.recordsFiltered - props.tableData.skip)/ props.tableData.take);
            
        return <div className="datatables-bottom">
            <div className="dataTables_length row">
                <label>{Translations.DataTables_PageSize}:</label>
                <select className="custom-select custom-select-sm form-control form-control-sm" value={props.tableData.take} onChange={handlePageSizeChange}>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>
            
            <div className="dataTables_info">
                {Translations.DataTables_Showing.format(props.tableData.skip + 1, valueTo, props.tableData.recordsTotal)}
            </div>
            
            <PaginationBox
                previousLabel={Translations.DataTables_previous}
                nextLabel={Translations.DataTables_next}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalpages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                initialPage={initialPage}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
            />
        </div>
    }

    if(!props.tableData.data || props.tableData.data.length === 0 || props.tableData.headers === undefined){
        return <section className="datatable-no-result">
            <div><i className={Url.haveSearchQueryString() ? 'fas fa-filter' : 'fas fa-palette' }></i></div>
            <div>{Url.haveSearchQueryString() ? Translations.DataTables_zeroRecords : props.noRecordsText}</div>
            {(Url.haveSearchQueryString() && props.onClearFilter) ? <button className="btn btn-secondary" onClick={props.onClearFilter}>{Translations.Buttons_General_ClearSearchFilterButton}</button>
            : 
            (props.noRecordsButtonHref && props.noRecordsButtonText) ? <a className="btn btn-primary" href={props.noRecordsButtonHref}><i className="fas fa-plus"></i> {props.noRecordsButtonText}</a>
            :
            null}
        </section>
    }

    return <div className="dataTables_wrapper">
            <div className="datatables-table">
                <table className="table datatable">
                    <thead>
                        <tr className="table-header">
                        {renderHeaders()}
                        </tr>
                    </thead>
                    <tbody>
                        {renderRows()}
                    </tbody>
                </table>
            </div>
            {props.hideBottomRow === true ? null : renderDataTableBottom()}
    </div>
}
export default DataTable;