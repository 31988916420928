export default function formatDate(date, withTime) {
    if(typeof date === 'undefined' || date === null || date === ''){
        return '';
    }
    
    const d = date.getDate();
    const m = date.getMonth()+1;

    const dd = (d < 10) ? `0${d}` : `${d}`;
    const mm = (m < 10) ? `0${m}` : `${m}`;

    const ho = date.getHours()
    const mi = date.getMinutes()
    const hor = (ho < 10) ? `0${ho}` : `${ho}`;
    const minu = (mi < 10) ? `0${mi}` : `${mi}`;

    return withTime ? `${date.getFullYear()}-${mm}-${dd} ${hor}:${minu}` : `${date.getFullYear()}-${mm}-${dd}`
}