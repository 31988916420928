import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUsersList } from '../../actions/accountActions'
import { Translations } from '../../../utils/translator'
import UserList from '../../components/account/UserList'

const UserListContainer = () =>  {
    const dispatch = useDispatch()

    const userList = useSelector(state => state.account.userList)

    useEffect(()=> {
        if(userList.headers.length <= 0){
            dispatch(getUsersList(userList.skip, userList.take))
        }
    })

    return <>
            <div className="entity-header">
                <h1>{Translations.Copy_Account_UserList}</h1>
            </div>
            <UserList 
                userList={userList}
                getUsersList={(skip, take) => dispatch(getUsersList(skip, take))}
            />
        </>
}

export default UserListContainer