import { CompanyActionTypes } from '../actions/companyActions'
import datatableModel from '../models/datatableModel'

const initialState = {
    inProgress: false,
    companyToEdit: {},
    companyList: {
        ...datatableModel,
        headers: [],
        data: [],
    },
    timezones: [],
    countries: [],
    newCompany: {
        name: '',
        email: '',
        zipCode: '',
        telephone: '',
        postalAddress: '',
        timezone: 'UTC',
        city: '',
        country: 'Sweden',
    },
    newSupervisor: {
        contactName: '',
        email: '',
        zipCode: '',
        telephone: '',
        postalAddress: '',
        city: '',
        country: 'Sweden',
    },
}

const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case CompanyActionTypes.COMPANY_CREATE:
            return {
                ...state,
                inProgress: true
            }

        case CompanyActionTypes.GET_COMPANY_SUCCESS:
            return {
                ...state,
                companyToEdit: action.companyToEdit
            }

        case CompanyActionTypes.SET_COMPANY_DATA:
            return {
                ...state,
                newCompany: action.company
            }

        case CompanyActionTypes.GET_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                companyList: {
                    ...state.companyList,
                    data: action.data,
                    headers: action.headers,
                    recordsFiltered: action.recordsFiltered,
                    recordsTotal: action.recordsTotal,
                    skip: action.skip,
                    take: action.take
                },
            }
                
        default:
            return state
    }
}
export default companyReducer