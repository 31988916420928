import ajax from '../../utils/ajax';
import buildUniqueActionTypes from '../../utils/actionTypeUtils'

export const StartPageActionTypes = buildUniqueActionTypes ([
  'LOADING_LU',
  'FETCHED_LU',
  'LOADING_DL',
  'FETCHED_DL',
  'SHOW_MORE_DL'
])

// -----------------------------------------------------------
// Start page actions
// -----------------------------------------------------------


export function fetchLatestUploads(){
  return dispatch => {
    dispatch({
      type: StartPageActionTypes.LOADING_LU
    })

    ajax({
      url: '/jsapi/Start/GetLatestUploadsOnAssignment',
      type: 'POST',
      data: null,
      error: (errorData) => {
          console.error('GetLatestUploadsOnAssignment error:', errorData);
          dispatch({
            type: StartPageActionTypes.FETCHED_LU,
            data: null
          })
      },
      success: (response) => {
          dispatch({
            type: StartPageActionTypes.FETCHED_LU,
            data: response.data
          })
      }
    })
  }
}

export function fetchDashboardLog(daysBack){
  return dispatch => {
    dispatch({
      type: StartPageActionTypes.LOADING_DL,
      daysBack
    })

    ajax({
      url: '/jsapi/Start/GetDashboardLog',
      type: 'POST',
      data: {
        daysBack: daysBack
      },
      error: (errorData) => {
          console.error('GetDashboardLog error:', errorData);
          dispatch({
            type: StartPageActionTypes.FETCHED_DL,
            data: []
          })
      },
      success: (response) => {
          dispatch({
            type: StartPageActionTypes.FETCHED_DL,
            data: response.data
          })
      }
    })
  }
}

export function showMoreDashboardLog(changeLogKey){
  return {
    type: StartPageActionTypes.SHOW_MORE_DL,
    changeLogKey
  }
}
