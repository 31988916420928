import React, { useState, useEffect } from 'react';
import validate from 'validate.js';
import { Translations } from '../../../../utils/translator';
import DropDownList from '@ncscolour/ncs-ui-utils/components/DropDownList'
import SwitchButton from '../../common/SwitchButton'

import './edit-company.scss';

const EditCompany = (props) => {
    const [companyToEditState, setCompanyToEdit] = useState(null)
    const [validationResult, setValidationResult] = useState(null)
    const [isSubmitted, setIsSubmitted] = useState(false)

    useEffect(() => {
        if(Object.keys(props.companyToEdit).length > 0){
            setCompanyToEdit(props.companyToEdit)
        }
    }, [props.companyToEdit])

    if(!companyToEditState || Object.keys(companyToEditState).length === 0){
        return null
    }

    const changesMade = () => {
        let changed = false
        Object.keys(companyToEditState).forEach(key => {
            if(companyToEditState[key] !== props.companyToEdit[key]){
                changed = true
            }
        })
        return changed
    }

    const validateField = (fieldKey) => {
        return (validationResult && validationResult[fieldKey] && validationResult[fieldKey].length > 0)
    }

    const validationMessage = (fieldKey) => {
        return validateField(fieldKey)?
                <div className="validation-message"><p className="help-block error">{validationResult[fieldKey].join(' ')}</p></div>
                :
                null
    }

    const baseClass = 'form-group'
    const nameFormGroupClassName = validateField('name') ? `${baseClass} has-error` : baseClass
    const zipCodeFormGroupClassName = validateField('zipCode') ? `${baseClass} has-error` : baseClass
    const telephoneFormGroupClassName = validateField('telephone') ? `${baseClass} has-error` : baseClass

     return <>
     <header className="entity-header">
        <h1>{Translations.Copy_Company_Edit_Title}</h1>
     </header>

     <article className="entity-information">
        <section className="main-panel">
         <div className="edit-company__form">
         <div className={nameFormGroupClassName}>
                <label className="form-control-label">{Translations.Copy_General_CompanyName}</label>
                 <input 
                    disabled={props.canEdit === false || isSubmitted === true}
                    value={companyToEditState.name} 
                    onChange={(event) => setCompanyToEdit({...companyToEditState, name: event.currentTarget.value})} 
                    className="form-control text-box" 
                    type="text"
                 />
                 {validationMessage('name')}
            </div> 

            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_Adress}</label>
                <input 
                    disabled={props.canEdit === false || isSubmitted === true}
                    value={companyToEditState.postalAddress || ''} 
                    onChange={(event) => setCompanyToEdit({...companyToEditState, postalAddress: event.currentTarget.value})} 
                    className="form-control text-box" 
                    type="text"
                 />
            </div>
            
            <div className={zipCodeFormGroupClassName}>
                <label className="form-control-label">{Translations.Copy_General_ZipCode}</label>
                <input 
                    disabled={props.canEdit === false || isSubmitted === true}
                    value={companyToEditState.zipCode || ''} 
                    onChange={(event) => setCompanyToEdit({...companyToEditState, zipCode: event.currentTarget.value})} 
                    className="form-control text-box" 
                    type="text"
                 />
            </div>

            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_City}</label>
                <input 
                    disabled={props.canEdit === false || isSubmitted === true}
                    value={companyToEditState.city || ''} 
                    onChange={(event) => setCompanyToEdit({...companyToEditState, city: event.currentTarget.value})} 
                    className="form-control text-box" 
                    type="text"
                 />
            </div>
            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_Country}</label>
                <DropDownList 
                    disabled={props.canEdit === false || isSubmitted === true}
                    className="form-control" 
                    data={props.countries} 
                    selected={companyToEditState.country} 
                    onChange={country => setCompanyToEdit({...companyToEditState, country: country})}/>
            </div>
            <div className={telephoneFormGroupClassName}>
                <label className="form-control-label">{Translations.Copy_General_Telephone}</label>
                <input 
                    disabled={props.canEdit === false || isSubmitted === true}
                    value={companyToEditState.telephone || ''} 
                    onChange={(event) => setCompanyToEdit({...companyToEditState, telephone: event.currentTarget.value})} 
                    className="form-control text-box" 
                    type="text"
                 />
            </div>
            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_TimeZone}</label>
                <DropDownList 
                    disabled={props.canEdit === false || isSubmitted === true}
                    className="form-control" 
                    data={props.timezones} 
                    selected={companyToEditState.timezone} 
                    onChange={timezone => setCompanyToEdit({...companyToEditState, timezone: timezone})}/>

            </div>

            {props.isCoreSupport === true ? <div className="form-group">
                <SwitchButton 
                    inactiveText={Translations.Copy_General_Disabled}
                    activeText={Translations.Copy_General_Enabled}
                    isOn={companyToEditState.disabled !== true}
                    onChange={value => {
                        setCompanyToEdit({...companyToEditState, disabled: !value})
                    }}
                />

            </div> : null}

            {props.canEdit === true ? <div className="button-row">
                <button 
                    className="btn btn-primary" 
                    disabled={isSubmitted === true}
                    onClick={() => {
                        if(changesMade() !== true){
                            return
                        }
                        const validationConstraints = {
                            name: {
                                presence: {
                                    allowEmpty: false
                                }
                            }
                          };
                          const hasErrors = validate(companyToEditState, validationConstraints)
                          setValidationResult(hasErrors)
                          if(!hasErrors){
                            setIsSubmitted(true)
                            props.onSave(companyToEditState)
                          }
                    
                        }}>{Translations.Buttons_General_SaveButton}</button>
            </div> : null} 
         </div>
        
        </section>
    </article>
    </>
}

export default EditCompany