const StorageKeys = {
    // ColourStandardListing: {
    //     SearchBox: 'colour-standard-listing-search-box',
    //     Tags: 'colour-standard-listing-tags',
    //     ShowInactive: 'colour-standard-listing-show-inactive',
    //     SelectedColourListID: 'colour-standard-listing-selected-colour-list-id',
    //     SelectedStatus: 'colour-standard-listing-selected-status',
    //     SelectedPassFail: 'colour-standard-listing-selected-pass-fail'
    // },
    // ProjectColours: {
    //     SearchBox: 'project-colours-search-box',
    //     Tags: 'project-colours-tags',
    //     ShowInactive: 'project-colours-show-inactive',
    //     SelectedColourListID: 'project-colours-selected-colour-list-id',
    //     SelectedStatus: 'project-colours-selected-status',
    //     SelectedPassFail: 'project-colours-selected-pass-fail'
    // },
    // ProjectMeasurements: {
    //     SearchBox: 'project-measurements-search-box',
    //     Tags: 'project-measurements-tags',
    //     ShowInactive: 'project-measurements-show-inactive',
    //     SelectedColourListID: 'project-measurements-selected-colour-list-id',
    //     SelectedStatus: 'project-measurements-selected-status',
    //     SelectedPassFail: 'project-measurements-selected-pass-fail'
    // },
    // AddProjectColour: {
    //     SearchBox: 'add-project-colour-search-box',
    //     Tags: 'add-project-colour-tags',
    //     ShowInactive: 'add-project-colour-show-inactive',
    //     SelectedColourListID: 'add-project-colour-selected-colour-list-id',
    //     SelectedStatus: 'add-project-colour-selected-status',
    //     SelectedPassFail: 'add-project-colour-selected-pass-fail'
    // },
    LoggedInCompanyID: 'logged-in-company-id'
};

export default StorageKeys;