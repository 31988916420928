import ajax from '../../utils/ajax';
import buildUniqueActionTypes from '../../utils/actionTypeUtils'
import { spinnerActive, spinnerInactive } from './spinnerActions';
import messageToastActions from '@ncscolour/ncs-ui-utils/components/MessageToast/messageToastActions';
import { MESSAGE_TYPES } from '@ncscolour/ncs-ui-utils/components/MessageToast/messageToastReducer'
import { Translations } from '../../utils/translator';


export const CompanyActionTypes = buildUniqueActionTypes([
    'CREATE_COMPANY',
    'GET_COMPANY',
    'GET_COMPANY_SUCCESS',
    'UPDATE_COMPANY',
    'UPDATE_COMPANY_SUCCESS',
    'GET_COMPANY_LIST',
    'GET_COMPANY_LIST_SUCCESS',
    'SET_COMPANY_DATA'
  ])

export function createCompany(company, supervisor){
    return dispatch => { 
        dispatch({
            type: CompanyActionTypes.CREATE_COMPANY,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/Company/CreateCompany',
        type: 'POST',
        data: {      
            Name: company.name,
            IsSupplier: false,
            IsCustomer: true,
            TimeZone: company.timezone,
            Contact: {
                Address: company.postalAddress,
                Zip: company.zipCode,
                City: company.city,
                Country: company.country,
                Phone: company.telephone,
            },
            Supervisor: {
                Name: supervisor.email,
                ContactName: supervisor.contactName,
                CoreRole: 3,
                Password: supervisor.email,
                Contact: {
                    Mail: supervisor.email,
                    Address: supervisor.postalAddress,
                    City: supervisor.city,
                    Country: supervisor.country,
                    Zip: supervisor.zipCode,
                    Phone: supervisor.telephone
                }
            }
        },
        error: (errorData) => {
            console.error('Error trying to create company:', errorData);
        },
        success: (response) => {
            dispatch(spinnerInactive())
            if(response){
              window.location = '/V2/Company/CompanyList'
            } else {
              dispatch(messageToastActions.showMessage(Translations.Copy_Company_Create_Error, MESSAGE_TYPES.Error))
            }
        }
      })
    }
  }

  export function updateCompany(company){
    return dispatch => { 
        dispatch({
            type: CompanyActionTypes.UPDATE_COMPANY,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/Company/UpdateCompany',
        type: 'POST',
        data: company,
        error: (errorData) => {
            console.error('Error trying to create company:', errorData);
        },
        success: (response) => {
            dispatch(spinnerInactive())
            if(response.result === true){
              dispatch(messageToastActions.showMessage(Translations.Copy_Company_Edit_Update_Success, MESSAGE_TYPES.Success, 4))
              setTimeout(() => {
                window.location.reload();
              }, 4000)
            } else {
              dispatch(messageToastActions.showMessage(Translations.Copy_Company_Edit_Update_Error, MESSAGE_TYPES.Error))
            }
        }
      })
    }
  }

  export function getCompanyList(skip, take){
    return dispatch => { 
        dispatch({
            type: CompanyActionTypes.GET_COMPANY_LIST,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/Company/GetCompanyList',
        type: 'POST',
        data: {
          skip,
          take,
          includeDisabled : true
        },
        error: (errorData) => {
            console.error('Error trying to create company:', errorData);
        },
        success: (response) => {
            dispatch(spinnerInactive())
            dispatch({
              type: CompanyActionTypes.GET_COMPANY_LIST_SUCCESS,
              data: response.data,
              headers: response.headers,
              recordsTotal: response.recordsTotal,
              recordsFiltered: response.recordsFiltered,
              skip,
              take,
            })
        }
      })
    }
  }



  export function getCompany(companyId){
    return dispatch => { 
        dispatch({
            type: CompanyActionTypes.GET_COMPANY,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/Company/GetCompany',
        type: 'POST',
        data: {
          companyId
        },
        error: () => {
          dispatch(spinnerInactive())
          dispatch(messageToastActions.showMessage('Error trying to get company', MESSAGE_TYPES.Error))
        },
        success: (response) => {
          dispatch(spinnerInactive())
          if(response.status === 'fail'){
            dispatch(messageToastActions.showMessage(response.toast.message, MESSAGE_TYPES.Error))
            return
          }
            
          dispatch({
            type: CompanyActionTypes.GET_COMPANY_SUCCESS,
            companyToEdit: response
          })
        }
      })
    }
  }

  export function setNewCompanyData(company){
    return dispatch => { 
        dispatch({
            type: CompanyActionTypes.SET_COMPANY_DATA,
            company
        })
    }
  }
  