import { ExportActionTypes } from '../actions/exportActions'

const initialState = {
    url:null,
}

const exportReducer = (state = initialState, action) => {
    switch (action.type) {
        case ExportActionTypes.SET_EXPORT_URL:
            return {
                ...state,
                url: action.exportUrl
            }
        
            
        default:
            return state
    }
}
export default exportReducer