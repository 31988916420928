import React, { useState } from 'react'
import validate from 'validate.js';
import { Translations } from '../../../../utils/translator'

const CreateProject = (props) => {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [validationResult, setValidationResult] = useState(null)

    const checkFields = (fieldKey) => {
        return (validationResult && validationResult[fieldKey] && validationResult[fieldKey].length > 0)
    }

    const validationMessage = (fieldKey) => {
        return checkFields(fieldKey)?
                <div className="validation-message"><p className="help-block error">{validationResult[fieldKey].join(' ')}</p></div>
                :
                null
    }

    const baseClass = 'form-group'
    const descriptionFormGroupClassName = checkFields('description') ? `${baseClass} has-error` : baseClass
    const nameFormGroupClassName = checkFields('name') ? `${baseClass} has-error` : baseClass

    return <section className={props.inProgress ? 'capq-spinner' : ''}>
    <div className={nameFormGroupClassName}>
        <label className="form-control-label">{Translations.Copy_General_Name}</label>
         <input 
            value={name} 
            onChange={(event) => setName(event.currentTarget.value)} 
            className="form-control text-box" 
            type="text"
         />
         {validationMessage('name')}
    </div> 

    <div className={descriptionFormGroupClassName}>
        <label className="form-control-label">{Translations.Copy_General_Description}</label>
        <input 
            value={description} 
            onChange={(event) => setDescription(event.currentTarget.value)} 
            className="form-control text-box" 
            type="text"
         />
         {validationMessage('description')}
    </div>

    <div className="modal-footer">
        <button className="btn btn-secondary" onClick={() => props.hideModal()}>Close</button>
        <button className="btn btn-primary" onClick={() => {
                const project = {
                    name: name,
                    description: description
                }
                
                const validationConstraints = {
                    name: {
                        presence: {
                            allowEmpty: false
                        }
                    },
                    description: {
                        presence: {
                            allowEmpty: true
                        }
                    },
                  };
                  const valid = validate(project, validationConstraints)
                  setValidationResult(valid)
                  if(!valid){
                    props.onSave(project)
                  }
            
                }}>{Translations.Buttons_General_SaveButton}</button>
    </div>
</section>
}

export default CreateProject;