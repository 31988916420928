import { combineReducers } from 'redux'
import ncsSupport from './ncsSupportReducer'
import startPage from './startPageReducer'
import colourStandard from './colourStandardReducer'
import createAssignmentPage from './createAssignmentPageReducer'
import assignment from './assignmentReducer'
import account from './accountReducer'
import spinner from './spinnerReducer'
import company from './companyReducer'
import messageToastReducer from '@ncscolour/ncs-ui-utils/components/MessageToast/messageToastReducer'
import utils from './utilsReducer'
import guide from './guideReducer'
import connection from './connectionReducer'
import exporter from './exportReducer'
import review from './reviewReducer'

export default combineReducers({
    ncsSupport,
    startPage,
    colourStandard,
    assignment,
    createAssignmentPage,
    account,
    spinner,
    company,
    message: messageToastReducer,
    utils,
    guide,
    connection,
    exporter,
    review
})