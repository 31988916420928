import buildUniqueActionTypes from '../../utils/actionTypeUtils'

export const GuideActionTypes = buildUniqueActionTypes([
  'GO_TO_NEXT_STEP',
  'RETURN_TO_PREVIOUS_STEP',
])


  export function goToNextStep(){
    return {
      type: GuideActionTypes.GO_TO_NEXT_STEP
    } 
}


export function returnToPreviousStep(){
  return {
    type: GuideActionTypes.RETURN_TO_PREVIOUS_STEP
  }
}