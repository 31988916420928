import ajax from '../../utils/ajax';
import buildUniqueActionTypes from '../../utils/actionTypeUtils'
import { spinnerActive, spinnerInactive } from './spinnerActions';


export const UtilsActionTypes = buildUniqueActionTypes([
    'GET_TIMEZONES',
    'GET_TIMEZONES_SUCCESS',
    'GET_COUNTRIES',
    'GET_COUNTRIES_SUCCESS',
  ])

export function getTimezones(){
    return dispatch => { 
        dispatch({
            type: UtilsActionTypes.GET_TIMEZONES,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/Utils/GetTimeZones',
        type: 'POST',
        error: (errorData) => {
            console.error('Error trying to create company:', errorData);
        },
        success: (response) => {
            dispatch(spinnerInactive())
            dispatch({
              type: UtilsActionTypes.GET_TIMEZONES_SUCCESS,
              timezones: response.result,
            })
        }
      })
    }
  }

  export function getCountries(){
    return dispatch => { 
        dispatch({
            type: UtilsActionTypes.GET_COUNTRIES,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/Utils/GetCountries',
        type: 'POST',
        error: (errorData) => {
            console.error('Error trying to create company:', errorData);
        },
        success: (response) => {
            dispatch(spinnerInactive())
            dispatch({
              type: UtilsActionTypes.GET_COUNTRIES_SUCCESS,
              countries: response.result,
            })
        }
      })
    }
  }