import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { startDatabaseUpdate } from '../../actions/ncsSupportActions'

import './database-update.scss'

const DatabaseUpdateContainer = (props) => {
    const progressBarRef = useRef()
    const dispatch = useDispatch()

    const databaseUpdateInProgress = useSelector(state => state.ncsSupport.databaseUpdateInProgress)
    const databaseUpdateSeconds = useSelector(state => state.ncsSupport.databaseUpdateSeconds)
    const databaseUpdateErrors = useSelector(state => state.ncsSupport.databaseUpdateErrors)
    
    const [updateExecuted, setUpdateExecuted] = useState(false)
    const [updateSeconds, setUpdateSeconds] = useState(0)
    
    useEffect(() => {
        if(databaseUpdateSeconds > 0){
            setUpdateSeconds(databaseUpdateSeconds)
        }
    }, [databaseUpdateSeconds])


    const updateDone = () => {
        return updateExecuted && databaseUpdateInProgress === false && databaseUpdateSeconds > 0
    }

    const getProgressBarWidth = () => {
        let ratio = 2
        if(progressBarRef.current){
            const progressBarRefInfo = progressBarRef.current.getBoundingClientRect()
            if(updateDone()){
                return progressBarRefInfo.width
            }
            ratio = progressBarRefInfo.width / 30
        }
        return updateSeconds * ratio
    }

    const progressText = `${updateSeconds} sec ${updateDone() ? 'Done!' : ''}`

    if(props.inProgressOnLoad === true){
        return <section className="database-update">Database update already in progress...</section>
    }

    return <section className="database-update">
        <div ref={progressBarRef} className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: `${getProgressBarWidth()}px` }} aria-valuenow={updateSeconds} aria-valuemin="0" aria-valuemax="100">{progressText}</div>
        </div>
        
        {(updateExecuted === false && databaseUpdateInProgress !== true) ? <button className="btn btn-primary" onClick={() => {
            setUpdateExecuted(true)
            dispatch(startDatabaseUpdate())
        }}>Start database update!</button> : null}

        {updateDone() && databaseUpdateErrors.length > 0 ? <><h2>There were errors</h2><ul>{databaseUpdateErrors.map((errorText, i) => <li key={i}>{errorText}</li>)}</ul></> : null}

        {updateDone() ? <a href="/">To start page</a> : null}
    </section>
}

export default DatabaseUpdateContainer