import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import NcsLogo from '../../components/NcsLogo'
import LoginForm from '../../components/account/LoginForm/LoginForm'
import ForgotPasswordForm from '../../components/account/LoginForm/ForgotPasswordForm'
import { loginUser, recoverPassword } from '../../actions/accountActions'

import './login-page.scss'

const LoginContainer = () => {
    const dispatch = useDispatch()
    const loginErrorMessage = useSelector(state => state.account.loginErrorMessage)
    const recoverPasswordFail = useSelector(state => state.account.recoverPasswordFail)
    const recoverPasswordResultMessage = useSelector(state => state.account.recoverPasswordResultMessage)


    const [recoverExecuted, setRecoverExecuted] = useState(false)
    const [forgotPwdActive, setForgotPwdActive] = useState(false)

    useEffect(() => {
        if(forgotPwdActive === true && recoverExecuted === true && recoverPasswordFail === false && recoverPasswordResultMessage.length === 0){
            setRecoverExecuted(false)
            setForgotPwdActive(false)
        }
    }, [forgotPwdActive, recoverExecuted, recoverPasswordFail, recoverPasswordResultMessage])

    return <section className="login-page">
        <section className="login-page__top">
            <NcsLogo />
            <a className="login-page__top-link" href="https://support.capq.com/" target="_blank" rel="noreferrer">Need support?</a>
        </section>
        <section className="login-page__form-container">
            {forgotPwdActive !== true ? <LoginForm 
                errorMessage={loginErrorMessage} 
                actionLinkText="Forgot password?"
                onActionLinkClick={() => setForgotPwdActive(true)}
                onSubmit={(username, password) => dispatch(loginUser(username, password))} 
                />
            :
                <ForgotPasswordForm 
                actionLinkText="Back to login"
                fail={recoverPasswordFail}
                resultMessage={recoverPasswordResultMessage}
                onActionLinkClick={() => setForgotPwdActive(false)}
                onSubmit={email => {
                    setRecoverExecuted(true)
                    dispatch(recoverPassword(email))
                }} 
                />
            }
        </section>
    </section>
}

export default LoginContainer