import buildUniqueActionTypes from '../../utils/actionTypeUtils'
import ajax from '../../utils/ajax'
import {spinnerActive, spinnerInactive} from './spinnerActions'

export const ColourStandardActionTypes = buildUniqueActionTypes(['FETCHED_COLOUR_STANDARDS', 'CS_UPDATE_FILTER'])

export function fetchColourStandards(skip, take, filter, order, headers){
    let columns = [];
    if(order && order.length > 0){
        columns = headers.map(h => {
            return {data: h.ColumnData, name: h.ColumnName};
        })
    }
    return dispatch => {
        dispatch(spinnerActive())
        dispatch({
            type: ColourStandardActionTypes.CS_UPDATE_FILTER,
            filter
          })
        const postData = {
            start: skip,
            length: take,
            searchType: !filter ? '' : filter.searchType,
            searchbox: !filter ? '' : filter.searchBox,
            tags: JSON.stringify(filter.tags),
            order: order,
            columns: columns
        }
        postData['show-inactive'] = !filter ? false : filter.showInactive; //TODO: Change show-inactive to showInactive in backend, bad naming!
        postData['colour-lists'] = filter.colourListId  //TODO: Change colour-lists to colourLists in backend, bad naming!
        
        ajax({
            url: '/jsapi/ColourStandard/DataTable',
            type: 'POST',
            data: postData,
            error: (errorData) => {
                dispatch(spinnerInactive())
                console.error('Get colour standards error:', errorData);
            },
            success: (response) => {
                dispatch(spinnerInactive())
                dispatch({
                    type: ColourStandardActionTypes.FETCHED_COLOUR_STANDARDS,
                    data: response.data,
                    headers: response.headers,
                    recordsTotal: response.recordsTotal,
                    recordsFiltered: response.recordsFiltered,
                    order,
                    skip,
                    take,
                    colourLists: response.colourLists,
                    tagField: response.tagField
                })
            }
          })

    }
}



