import ajax from '../../utils/ajax'
import buildUniqueActionTypes from '../../utils/actionTypeUtils'
import getAntiForgeryToken from '../../utils/antiforgerytoken'
import { spinnerActive, spinnerInactive } from './spinnerActions'
import messageToastActions from '@ncscolour/ncs-ui-utils/components/MessageToast/messageToastActions'
import { MESSAGE_TYPES } from '@ncscolour/ncs-ui-utils/components/MessageToast/messageToastReducer'
import { Translations } from '../../utils/translator'
import Url from '../../utils/url'

export const AccountActionTypes = buildUniqueActionTypes([
    'ADD_USER_INPROGRESS',
    'ADD_USER',
    'GET_USER',
    'GET_USER_SUCCESS',
    'GET_USER_FAILED',
    'UPDATE_USER',
    'UPDATE_USER_SUCCESS',
    'UPDATE_USER_FAILED',
    'DELETE_USER',
    'DELETE_USER_SUCCESS',
    'DELETE_USER_FAILED',
    'LOGIN_USER_SUCCESS',
    'LOGIN_USER_FAILED',
    'LOCKUNLOCK_USER',
    'LOCKUNLOCK_USER_SUCCESS',
    'LOCKUNLOCK_USER_FAILED',
    'CHANGE_USER_PASSWORD',
    'CHANGE_USER_PASSWORD_SUCCESS',
    'CHANGE_USER_PASSWORD_FAILED',
    'GET_USERS',
    'GET_USERS_SUCCESS',
    'RECOVER_PASSWORD'
  ])

export function loginUser(userName, password){
  return dispatch => {
      dispatch(spinnerActive())

      ajax({
      url: '/jsapi/Account/TryLogin',
      type: 'POST',
      data: {
        __RequestVerificationToken: getAntiForgeryToken(),
        userName,
        password,
        returnUrl : Url.getQsValue('returnurl')
      },
      error: (errorData) => {
          dispatch(spinnerInactive())
          console.error('Error trying to login user:', errorData);
          dispatch({
            type: AccountActionTypes.LOGIN_USER_FAILED,
            loginErrorMessage: Translations.Copy_LoginFailed
          })
      },
      success: (response) => {
            if(response.loginSuccessful === true){
              dispatch({
                type: AccountActionTypes.LOGIN_USER_SUCCESS
              })

              //Redirect user after successful login
              if(response.dbNeedUpdate === true){
                window.location.href = '/V2/Account/DbUpdate';
                return
              } else if(response.loginAsCompany === true){
                const returnUrl = Url.getQsValue('returnurl')
                const returnUrlPart = (returnUrl && returnUrl.indexOf('login') < 0)? `&returnurl=${returnUrl}` : ''
                window.location.href = `/V2/Start?selectCompanyLogin=true${returnUrlPart}`;
                return

              } else if (response.redirectAfterLogin && response.redirectAfterLogin.length > 0){
                window.location.href = response.redirectAfterLogin;
                return
              }
              window.location.href = '/V2/Start';

            } else {
              dispatch(spinnerInactive())
              dispatch({
                type: AccountActionTypes.LOGIN_USER_FAILED,
                loginErrorMessage: response.loginErrorMessage
              })
            }
      }
    })

  }
}

export function recoverPassword(email){
  return dispatch => {
    dispatch(spinnerActive())

    ajax({
      url: '/jsapi/Account/RecoverPassword',
      type: 'POST',
      data: {
        __RequestVerificationToken: getAntiForgeryToken(),
        email,
      },
      error: (errorData) => {
          dispatch(spinnerInactive())
          console.error('Error trying to recover user password:', errorData);
          dispatch({
            type: AccountActionTypes.RECOVER_PASSWORD,
            recoverPasswordFail: true,
            recoverPasswordResultMessage: 'Technical error'
          })
      },
      success: (response) => {
        dispatch(spinnerInactive())

        dispatch({
          type: AccountActionTypes.RECOVER_PASSWORD,
          recoverPasswordFail: response.result !== true,
          recoverPasswordResultMessage: response.resultMessage
        })
        setTimeout(() => {
          dispatch({
            type: AccountActionTypes.RECOVER_PASSWORD,
            recoverPasswordFail: false,
            recoverPasswordResultMessage: ''
          })
        }, 5000)
      }
    })
  }
}

export function createUser(user){
    return dispatch => { 
        dispatch({
            type: AccountActionTypes.ADD_USER_INPROGRESS,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/Account/CreateOrInviteUser',
        type: 'POST',
        data: user,
        error: (errorData) => {
            dispatch(spinnerInactive())
            console.error('Error trying to add user:', errorData);
            dispatch({
              type: AccountActionTypes.ADD_USER,
              data: null
            })
        },
        success: (response) => {
            //For now we just redirect to current webforms User-list pag
            if(response.error === true){
              dispatch(spinnerInactive())
              dispatch(messageToastActions.showMessage(response.userAlreadyExist === true ? Translations.Copy_User_Create_Error_Exists : Translations.Copy_User_Create_Error, MESSAGE_TYPES.Error))
            } else {
              dispatch(messageToastActions.showMessage(Translations.Copy_User_Create_Success, MESSAGE_TYPES.Success)),setTimeout(() => {
                window.location = '/V2/Account/UserList'
              }, 2000)
            }
        }
      })
    }
  }

  export function getUser(id){
    return dispatch => { 
        dispatch({
            type: AccountActionTypes.GET_USER,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/Account/GetUser',
        type: 'POST',
        data: {
          userId: id
        },
        error: (errorData) => {
            console.error('Error trying to add user:', errorData);
            dispatch({
              type: AccountActionTypes.GET_USER_FAILED,
            })
        },
        success: (data) => {
            if(data.status === 'fail'){
              dispatch(messageToastActions.showMessage(data.toast.message, MESSAGE_TYPES.Error))
              return
            }

            dispatch({
              type: AccountActionTypes.GET_USER_SUCCESS,
              userToEdit: data.user
            })
            dispatch(spinnerInactive())
        }
      })
    }
  }

  export function updateUser(user){

    return dispatch => { 
        dispatch({
            type: AccountActionTypes.UPDATE_USER,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/Account/UpdateUser',
        type: 'POST',
        data: {
          updatedUser: user
        },
        error: (errorData) => {
            console.error('Error trying to add user:', errorData);
            dispatch({
              type: AccountActionTypes.UPDATE_USER_FAILED,
              data: null
            })
        },
        success: (response) => {
            dispatch(spinnerInactive())
            if(response){
                dispatch(messageToastActions.showMessage(Translations.Copy_General_Update_Success, MESSAGE_TYPES.Success))
            } else {
              dispatch(messageToastActions.showMessage(Translations.Copy_General_Update_Error, MESSAGE_TYPES.Error))
            }
        }
      })
    }
  }

  export function lockUser(userId){

    return dispatch => { 
        dispatch({
            type: AccountActionTypes.LOCKUNLOCK_USER,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/Account/LockUser',
        type: 'POST',
        data: {
          userId: userId
        },
        error: (errorData) => {
            console.error('Error trying to add user:', errorData);
            dispatch({
              type: AccountActionTypes.LOCKUNLOCK_USER_FAILED,
              isLockedOut: null
            })
        },
        success: (data) => {
            dispatch({
              type: AccountActionTypes.LOCKUNLOCK_USER_SUCCESS,
              isLockedOut: data.result
            })
            dispatch(messageToastActions.showMessage(Translations.Copy_EditUser_LockUnlockUser_Success, MESSAGE_TYPES.Success))
            dispatch(spinnerInactive())
        }
      })
    }
  }

  export function unlockUser(userId){

    return dispatch => { 
        dispatch({
            type: AccountActionTypes.LOCKUNLOCK_USER,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/Account/UnlockUser',
        type: 'POST',
        data: {
          userId: userId
        },
        error: (errorData) => {
            console.error('Error trying to add user:', errorData);
            dispatch({
              type: AccountActionTypes.LOCKUNLOCK_USER_FAILED,
              isLockedOut: null
            })
        },
        success: (data) => {
            dispatch({
              type: AccountActionTypes.LOCKUNLOCK_USER_SUCCESS,
              isLockedOut: data.result
            })
            dispatch(messageToastActions.showMessage(Translations.Copy_EditUser_LockUnlockUser_Success, MESSAGE_TYPES.Success))
            dispatch(spinnerInactive())
        }
      })
    }
  }

  export function changePassword(userId, newPassword, oldPassword, redirectToLogin){
    return dispatch => { 
        dispatch({
            type: AccountActionTypes.CHANGE_USER_PASSWORD,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/Account/ChangePassword',
        type: 'POST',
        data: {
          userId: userId,
          newPassword: newPassword,
          oldPassword: oldPassword
        },
        error: (errorData) => {
            console.error('Error trying to add user:', errorData);
            dispatch({
              type: AccountActionTypes.CHANGE_USER_PASSWORD_FAILED,
              data: null
            })
        },
        success: (data) => {
          if(data.result === true){
              dispatch({
              type: AccountActionTypes.CHANGE_USER_PASSWORD_SUCCESS,
            })
            dispatch(messageToastActions.showMessage(Translations.Copy_EditUser_ChangePassword_Success, MESSAGE_TYPES.Success, 4))
            if(redirectToLogin === true){
              setTimeout(() => {
                window.location.href = '/V2/Account/Login';
              }, 4000)
            }
          } else {
            dispatch(messageToastActions.showMessage(data.errorMessage, MESSAGE_TYPES.Error))
          }
          
            
            dispatch(spinnerInactive())
        }
      })
    }
  }

  export function deleteUser(userId){

    return dispatch => { 
        dispatch({
            type: AccountActionTypes.DELETE_USER,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/Account/DeleteUser',
        type: 'POST',
        data: {
          userId: userId
        },
        error: (errorData) => {
            console.error('Error trying to add user:', errorData);
            dispatch({
              type: AccountActionTypes.DELETE_USER_FAILED,
              data: null
            })
        },
        success: () => {
          dispatch(spinnerInactive())
          window.location = '/V2/Account/UserList'

        }
      })
    }
  }
  

  export function getUsersList(skip, take){

    return dispatch => { 
        dispatch({
            type: AccountActionTypes.GET_USERS,
        })
        dispatch(spinnerActive())

        ajax({
        url: '/jsapi/Account/GetUserList',
        type: 'POST',
        data: {
          skip: skip,
          take: take,
        },
        error: (errorData) => {
            console.error('Error trying to get users list:', errorData);
            dispatch({
              type: AccountActionTypes.GET_USER_FAILED,
              data: null
            })
        },
        success: (response) => {
            dispatch({
              type: AccountActionTypes.GET_USERS_SUCCESS,
              data: response.data,
              headers: response.headers,
              recordsTotal: response.recordsTotal,
              recordsFiltered: response.recordsFiltered,
              skip,
              take,
            })
            dispatch(spinnerInactive())
        }
      })
    }
  }