import React from 'react' 

import './switch-button.scss'

const SwitchButton = (props) => {
  const handleChange = () =>{
      if(typeof props.onChange === 'function'){
          props.onChange(!props.isOn)
      }
  }

  return <span className="switch-button">
      <span>{props.inactiveText}</span>
      <input
        checked={props.isOn}
        onChange={handleChange}
        className="switch-checkbox"
        id={'react-switch-new'}
        type="checkbox"
      />
      <label className={`switch-label ${props.isOn ? ' active': ''}`} htmlFor={'react-switch-new'}>
        <span className="switch-label-content" />
      </label>
      <span>{props.activeText}</span>
    </span>
}
export default SwitchButton
  