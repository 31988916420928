import React from 'react'
import { Translations } from '../../../../utils/translator'

const ConnectionDetailsTab = (props) => {
    return <>
    <div className="form-group">
        <label className="form-control-label">{Translations.Copy_Instrument_Brand}</label>
        <p>{props.brand}</p>
    </div> 

    <div className="form-group">
        <label className="form-control-label">{Translations.Copy_Instrument_Model}</label>
        <p>{props.model}</p>
    </div>
    <div className="form-group">
        <label className="form-control-label">{Translations.Copy_Instrument_SerialNo}</label>
        <p>{props.serialNo}</p>
    </div>

    <div className="form-group">
        <label className="form-control-label">{Translations.Copy_General_Description}</label>
        {props.authorizedToAdministrate === true ? <textarea 
            className="form-control text-box" 
            type="text"
            value={props.description} 
            onChange={(event) => props.onDescriptionChange(event.currentTarget.value)}
        /> : <p>{props.description}</p> }
    </div>
    <div className="form-group">
        <label className="form-control-label">{Translations.Copy_General_Location}</label>
        {props.authorizedToAdministrate === true ? <input 
            value={props.location} 
            onChange={(event) => props.onLocationChange(event.currentTarget.value)}
            className="form-control text-box" 
            type="text"
        /> : <p>{props.location}</p> }
    </div>
</>
}

export default ConnectionDetailsTab