import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SearchPanel from '../../components/common/SearchPanel'
import DataTable from '../../components/common/DataTable'
import { fetchAssignments } from '../../actions/assignmentActions'
import { Translations } from '../../../utils/translator'

let initialized = false

const AssignmentContainer = (props) => {
    const dispatch = useDispatch()

    const assignments = useSelector(state => state.assignment.assignments)
    const filter = useSelector(state => state.assignment.filter)
    const suppliersList = useSelector(state => state.assignment.suppliersList)
    const [clearFilterBridge, setClearFilterBridge] = useState(null)

    useEffect(() => {
        if(initialized !== true && assignments && assignments.headers.length <= 0){
            dispatch(fetchAssignments(0, assignments.take, filter, assignments.order, assignments.headers))
            initialized = true
        }
        
    }, [props, dispatch, assignments, assignments.take, filter, assignments.order, assignments.headers])

    const config = {
        'AssignmentID': (id, isHeader) => {
            if(isHeader){
                return <span>ID:</span>
            } else {
                return <span>{id}</span>
            }
        }
    }
    
    return <>
        <SearchPanel 
            filter={filter}
            suppliersList={suppliersList}
            extraFilters={[]}
            onInit={(clearFilterCallback) => {
                setClearFilterBridge(() => clearFilterCallback)
            } }
            onChange={filter => dispatch(fetchAssignments(0, assignments.take, filter, assignments.order, assignments.headers))}
            />
            
        <DataTable 
            config={config} 
            tableData={assignments} 
            navigateToNewPage={(selectedPageInfo) => dispatch(fetchAssignments(selectedPageInfo.selected * assignments.take, assignments.take, filter, assignments.order, assignments.headers))}
            updatePageSize={newSize => dispatch(fetchAssignments(0, newSize, filter, assignments.order, assignments.headers))}
            selectedItems={[]}
            selectRow={assignmentID => {
                const urlPath = props.userHaveAccess ? 'Edit' : 'View';
                const urlRedirect = `/V2/Assignment/${urlPath}/${assignmentID}`;
                window.location.href = urlRedirect;
            }}
            selectProp={'AssignmentID'}
            noRecordsText={Translations.Copy_Assignment_Listing_NoAssignments}
            noRecordsButtonText={Translations.Buttons_Assignments_NewAssignment}
            noRecordsButtonHref="/V2/Assignment/create"
            updateSortColumnOrder={(order) => dispatch(fetchAssignments(assignments.skip, assignments.take, filter, order, assignments.headers))}
            onClearFilter={() => {
                if(clearFilterBridge){
                    clearFilterBridge()
                }
            }}
            />
        </>
}

export default AssignmentContainer