import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SearchPanel from '../../components/common/SearchPanel'
import DataTable from '../../components/common/DataTable'
import { fetchColourStandards } from '../../actions/colourStandardActions'
import { Translations } from '../../../utils/translator'

let initialized = false

const ColourStandardContainer = (props) => {
    const dispatch = useDispatch()
    const colourStandards = useSelector(state => state.colourStandard.colourStandards)
    const filter = useSelector(state => state.colourStandard.filter)
    const colourLists = useSelector(state => state.colourStandard.colourLists)
    const tagField = useSelector(state => state.colourStandard.tagField)

    const [clearFilterBridge, setClearFilterBridge] = useState(null)

    useEffect(() => {
        if(initialized !== true && colourStandards && filter && colourStandards.headers.length <= 0){
            dispatch(fetchColourStandards(0, colourStandards.take, filter, colourStandards.order, colourStandards.headers))
            initialized = true
        }
    }, [dispatch, colourStandards, colourStandards.take, filter, colourStandards.order, colourStandards.headers])

    const config = {
        'ColourStandardID': (id, isHeader) => {//isSelected
            if(isHeader){
                return <span>ID:</span>
            } else {
                return <span>{id}</span>
            }
        },
        'RGB': (hexCode, isHeader) => {
            if(isHeader){
                return <span></span>
            } else {
                return <span className="iconHTML" style={{backgroundColor: hexCode}}></span>
            }
        }
    }
    
    return <>
        <SearchPanel 
            filter={filter}
            colourLists={colourLists}
            tagField={tagField}
            onInit={(clearFilterCallback) => {
                setClearFilterBridge(() => clearFilterCallback)
            } }
            onChange={(filter)=> { 
                dispatch(fetchColourStandards(0, colourStandards.take, filter, colourStandards.order, colourStandards.headers))
            }}/>
        <DataTable 
            config={config} 
            tableData={colourStandards} 
            navigateToNewPage={(selectedPageInfo) => dispatch(fetchColourStandards(selectedPageInfo.selected * colourStandards.take, colourStandards.take, filter, colourStandards.order, colourStandards.headers))}
            updatePageSize={newSize => dispatch(fetchColourStandards(0, newSize, filter, colourStandards.order, colourStandards.headers))}
            selectedItems={[]}
            selectRow={colourStandardID => {
                const urlPath = props.userHaveAccess ? 'Edit' : 'View';
                const urlRedirect = `/V2/ColourStandard/${urlPath}/${colourStandardID}`;
                window.location.href = urlRedirect;
            }}
            selectProp={'ColourStandardID'}
            noRecordsText={Translations.Copy_ColourStandard_No_ColourStandards}
            noRecordsButtonText={Translations.Buttons_ColourStandards_NewStandard}
            noRecordsButtonHref="/V2/ColourStandard/create"
            updateSortColumnOrder={(order) => {
                dispatch(fetchColourStandards(colourStandards.skip, colourStandards.take, filter, order, colourStandards.headers))
            }}
            onClearFilter={() => {
                if(clearFilterBridge){
                    clearFilterBridge()
                }
            }}
            />
        </>
}

export default ColourStandardContainer