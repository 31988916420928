import React from 'react'   /* eslint-disable-line */
import ColourDetailCard from './colour-detail-card/colour-detail-card'
import { Translations } from '../../../../utils/translator'
import BulkActionCard from './bulk-action-card/bulk-action-card'
import parseGlossValue from '../../../../utils/gloss'

const CreateAssignmentStep3 = (props) => {  
    
    const updateSelectedColour = (colour) =>{
        const selectedColoursCopy = {...props.selectedColours}
        selectedColoursCopy[colour.ColourStandardId] = colour
        props.updateSelectedColours(selectedColoursCopy)
    }

    const updateAllSelectedColours = (bulkValues) =>{
        const selectedColoursCopy = {...props.selectedColours}
        Object.values(selectedColoursCopy).forEach(selectedColour => {
            if(selectedColour.Selected === true){
                if(bulkValues.material.trim() !== '')
                    selectedColour.MaterialId = parseInt(bulkValues.material)

                if(bulkValues.gloss.trim() !== '')
                    selectedColour.Gloss60 = parseGlossValue(bulkValues.gloss)

                if(bulkValues.toleranceSetId.trim() !== '')
                    selectedColour.ToleranceSetId = parseInt(bulkValues.toleranceSetId)
            }  
        })
        props.updateSelectedColours(selectedColoursCopy);
    }

    if(props.currentStep !== 3) { // Prop: The current step
        return null
    }
    if(props.selectedColours && Object.keys(props.selectedColours).length === 0){
            return <div>{Translations.Copy_Assignment_Create_NoColoursSelectedWarning}</div>
    }

    return <div className="capq-guide__summary">
        <h2>{Translations.Copy_Assignment_Create_ProjectColourEditSelection}</h2>  
        <section className="capq-guide__measurements assignment-create__selected-list">
            <BulkActionCard 
                materials={props.materials} 
                toleranceSetList={props.toleranceSetList} 
                applyBulkValues={updateAllSelectedColours}
            />
            {Object.values(props.selectedColours).map((colour, i) => {
                return <ColourDetailCard 
                key={i} colour={colour} 
                materials={props.materials} 
                toleranceSetList={props.toleranceSetList} 
                updateColour={updateSelectedColour}
                ></ColourDetailCard>
            })}                                          
        </section>
    </div> 
    
}

export default CreateAssignmentStep3
