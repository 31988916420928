import React,{ useState, useRef, useEffect, useCallback } from 'react'
import * as d3 from 'd3' //https://www.freecodecamp.org/news/how-to-get-started-with-d3-and-react-c7da74a5bd9f/
import DropDownList from '@ncscolour/ncs-ui-utils/components/DropDownList'
import './bar-chart.scss'
import { Translations } from '../../../../../utils/translator'

const ConnectionReproducibilityChart = (props) => {
    const d3CanvasElement = useRef()
    const svgElementIdRef = useRef()    
    const prevReproducibility = useRef()
    const xscale = useRef()
    const yscale = useRef()
    const xAxis = useRef()
    const yAxis = useRef()
    const displayed = useRef()
    const bars = useRef()
    const xOverview = useRef()
    const yOverview = useRef()
    const subgroups = useRef()
    const xSubgroup = useRef()
    subgroups.current = ['Bar1', 'Bar2']
    const [selectedProfile, setSelectedProfile] = useState(props.activeProfile)
    const [selectedGloss, setSelectedGloss] = useState(props.activeProfile.isMultiGloss ? 0 : null)
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if(!props.reproducibility){
            return
        }
        if(initialized !== true){
            props.getReproducibilityResult(selectedProfile.id, selectedProfile.isMultiGloss ? 1 : null)
            setInitialized(true)
        } else {
            const reproducibilityChanged = props.reproducibility.items !== prevReproducibility.current
            if(reproducibilityChanged)
                renderChart()
        }

        

        prevReproducibility.current = props.reproducibility.items
    }, [props, props.reproducibility, renderChart, props.getReproducibilityResult, initialized, selectedProfile])

    const renderChart = useCallback(()=>{
        const data = [...props.reproducibility.items]
                    
        //const margin =  {top: 20, right: 10, bottom: 20, left: 40};
        const margin = {top: 20, right: 20, bottom: 40, left: 60};
        const marginOverview = {top: 30, right: 10, bottom: 20, left: 40};
        const selectorHeight = 40;
        const width = 800 - margin.left - margin.right;
        const height = 400 - margin.top - margin.bottom - selectorHeight;
        const heightOverview = 80 - marginOverview.top - marginOverview.bottom;
            
        const barWidth = 70;
        const numBars = Math.round(width/barWidth);
        const isScrollDisplayed = barWidth * data.length > width;
        
        xscale.current = d3.scaleBand()
                    .domain(data.slice(0,numBars).map(d => d.Group))
                    .range([0, width], .2);

        yscale.current = d3.scaleLinear()
                    .domain([0, props.reproducibility.maxValue * 1.1])
                    .range([height, 0]);

        xSubgroup.current = d3.scaleBand()
            .domain(subgroups.current)
            .range([0, xscale.current
            .bandwidth()])
            .padding([0.05])
        
        xAxis.current  = d3.axisBottom(xscale.current);
        yAxis.current  = d3.axisLeft(yscale.current);
  
        d3.select('svg').remove();
        const svg = d3.select(d3CanvasElement.current).append('svg')
                    .attr('width', width + margin.left + margin.right)
                    .attr('height', height + margin.top + margin.bottom + selectorHeight);
  
        const diagram = svg.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);
        diagram.append('g').attr('class', 'x axis').attr('transform', 'translate(0, ' + height + ')').call(xAxis.current);
        diagram.append('g').attr('class', 'y axis').call(yAxis.current);
  
        bars.current = diagram.append('g').attr('class', 'bars-g');
        
        const getSubGroup = d => subgroups.current.map(key => { 
            return {
                key: key, 
                value: d[key], 
                colour: d.Colours[key],
                tooltip: d.Tooltip,
                group: d.Group
            }; 
        });

        const barPosInfo = []

        bars.current.selectAll('g')
            .data(data.slice(0, numBars))
            .enter()
            .append('g')
            .attr('transform', d => `translate(${xscale.current(d.Group)},0)`)
            .selectAll('rect')
            .data(d => getSubGroup(d))
            .enter()
            .append('rect')
            .attr('x', d => {
                const xValue = d.key === 'Bar2' ? (xscale.current(d.group) +  xSubgroup.current(d.key)) : xscale.current(d.group)
                const yValue = yscale.current(d.value)
                barPosInfo.push({
                  x: xValue,
                  y: yValue,
                  barWidth: xscale.current.bandwidth(),
                  barHeight: height - yscale.current(d.value),
                  tooltip: d.tooltip
                })
               return xSubgroup.current(d.key)})
            .attr('y', d => yscale.current(d.value))
            .attr('width', xSubgroup.current.bandwidth())
            .attr('height', d => height - yscale.current(d.value))
            .attr('fill', d => `rgb(${d.colour[0]}, ${d.colour[1]}, ${d.colour[2]})`)

            //Render all tooltips
        const tooltipWidth = 390;
        const tooltipHeight = 40;
        barPosInfo.forEach(bpi => {
          const tooltipGroup = bars.current.append('g')
            .attr('class', 'focus')
            .style('display', 'none');

          const calcX = (bpi.x + tooltipWidth >= width)? width - tooltipWidth - 10 : bpi.x
          tooltipGroup.append('rect')
            .attr('class', 'capq-chart__tooltip')
            .attr('width', tooltipWidth)
            .attr('height', tooltipHeight)
            .attr('x', calcX)
            .attr('y', bpi.y)
            .attr('rx', 4)
            .attr('ry', 4);

          tooltipGroup.append('text')
            .attr('class', 'tooltip-value')
            .attr('x', calcX + 10)
            .attr('y', bpi.y + (tooltipHeight / 2) + 4)
            .text(bpi.tooltip);

          //Invisible overlay to handle mouse over to show/hide tooltip
          diagram.append('rect')
            .attr('class', 'capq-chart__overlay')
            //.attr('stroke', 'red')
            .attr('width', bpi.barWidth)
            .attr('height', bpi.barHeight)
            .attr('x', bpi.x)
            .attr('y', bpi.y)
            .on('mouseover', function() { 
              tooltipGroup.style('display', null); 
            })
            .on('mouseout', function() { 
              tooltipGroup.style('display', 'none'); 
            })
        })

        svg.append('text')
        .attr('text-anchor', 'end')
        .attr('x', 760)
        .attr('y', 350)
        .text(Translations.Copy_Instrument_Chart_Xaxis_Text);
    
        svg.append('text')
            .attr('text-anchor', 'end')
            .attr('transform', 'rotate(-90)')
            .attr('y', 15)
            .attr('x', -40)
            .text(Translations.Copy_Instrument_Chart_Yaxis_Text)

        if (isScrollDisplayed)
        {
            const subBarData = data.reduce((acc, x)=>{
                    acc.push(x.Bar1)
                    acc.push(x.Bar2)
                    return acc
                },[])
            

            xOverview.current = d3.scaleBand()
                        .domain(subBarData.map((d, index)=>index+1))
                        .range([0, width], .2);

            yOverview.current = d3.scaleLinear().range([heightOverview, 0]);
            yOverview.current.domain(yscale.current.domain());

            diagram.append('g').attr('class', 'subBar');
            //subbars
            const subBars = diagram.selectAll('.subBar')
            .data(subBarData)

            subBars.enter()
            .append('rect')
            .classed('subBar', true)
            .attr('height', (d) => heightOverview - yOverview.current(d))
            .attr('width', () => xOverview.current.bandwidth())
            .attr('x', (d, index) => xOverview.current(index + 1))
            .attr('y', (d) => height + heightOverview + yOverview.current(d))
        }

            svg.append('g')
                .attr('transform', `translate(0, ${yscale.current(props.reproducibility.average)})`)
                .append('line')
                .attr('x2', 800)
                .style('stroke', '#dbe386')
                .style('stroke-width', '1px')


            displayed.current = d3.scaleQuantize()
                        .domain([0, width])
                        .range(d3.range(data.length));

            diagram.append('rect')
                .attr('transform', `translate(0, ${height + margin.bottom})`)
                .attr('class', 'mover')
                .attr('x', 0)
                .attr('y', -10)
                .attr('height', selectorHeight)
                .attr('width', Math.round(parseFloat(numBars * width)/data.length))
                .attr('pointer-events', 'all')
                .attr('cursor', 'ew-resize')
                .call(d3.drag().on('drag', function(event) {
                    const x = parseInt(d3.select(this).attr('x'));
                    const newX = x + event.dx;
                    const scrollHandleWidth = parseInt(d3.select(this).attr('width'));
                    
                    if ( newX < 0 || newX + scrollHandleWidth > width ) return;
                
                    d3.select(this).attr('x', newX);
                
                    const barPos = displayed.current(x);
                    const newBarPos = displayed.current(newX);
                
                    if ( barPos === newBarPos ) return;
                
                    const newData = data.slice(newBarPos, newBarPos + numBars);
                    xscale.current.domain(newData.map(d => d.Group));
                    diagram.select('.x.axis').call(xAxis.current);

                    xSubgroup.current = d3.scaleBand()
                        .domain(subgroups.current)
                        .range([0, xscale.current.bandwidth()])
                        .padding([0.05])

                    const newBarPosInfo = []

                    d3.select('.bars-g').remove();

                    bars.current = diagram.append('g')
                        .attr('class', 'bars-g');

                    bars.current.selectAll('g')
                        .data(newData)
                        .enter()
                        .append('g')
                        .attr('transform', d => `translate(${xscale.current(d.Group)},0)`)
                        .selectAll('rect')
                        .data(d => getSubGroup(d))
                        .enter()
                        .append('rect')
                        .attr('x', d => {
                            const xValue = d.key === 'Bar2' ? (xscale.current(d.group) + xSubgroup.current(d.key)) : xscale.current(d.group)
                            const yValue = yscale.current(d.value)
                            newBarPosInfo.push({
                              x: xValue,
                              y: yValue,
                              barWidth: xscale.current.bandwidth(),
                              barHeight: height - yscale.current(d.value),
                              tooltip: d.tooltip
                            })
                           return xSubgroup.current(d.key)})
                        .attr('y', d => yscale.current(d.value))
                        .attr('width', xSubgroup.current.bandwidth())
                        .attr('height', d => height - yscale.current(d.value))
                        .attr('fill', d => `rgb(${d.colour[0]}, ${d.colour[1]}, ${d.colour[2]})`)

                               //Render all tooltips
                        const tooltipWidth = 390;
                        const tooltipHeight = 40;
                        newBarPosInfo.forEach(bpi => {
                        const tooltipGroup = bars.current.append('g')
                            .attr('class', 'focus')
                            .style('display', 'none');

                        const calcX = (bpi.x + tooltipWidth >= width)? width - tooltipWidth - 10 : bpi.x
                        tooltipGroup.append('rect')
                            .attr('class', 'capq-chart__tooltip')
                            .attr('width', tooltipWidth)
                            .attr('height', tooltipHeight)
                            .attr('x', calcX)
                            .attr('y', bpi.y)
                            .attr('rx', 4)
                            .attr('ry', 4);

                        tooltipGroup.append('text')
                            .attr('class', 'tooltip-value')
                            .attr('x', calcX + 10)
                            .attr('y', bpi.y + (tooltipHeight / 2) + 4)
                            .text(bpi.tooltip);

                        //Invisible overlay to handle mouse over to show/hide tooltip
                        diagram.append('rect')
                            .attr('class', 'capq-chart__overlay')
                            .attr('width', bpi.barWidth)
                            .attr('height', bpi.barHeight)
                            .attr('x', bpi.x)
                            .attr('y', bpi.y)
                            .on('mouseover', function() { 
                            tooltipGroup.style('display', null); 
                            })
                            .on('mouseout', function() { 
                            tooltipGroup.style('display', 'none'); 
                            })
                        })
                }));
            
    }, [props.reproducibility, xscale, yscale, xAxis, yAxis, displayed, bars, subgroups]);


    return <div>
        {props.authorizedToAdministrate === true ?  <div className='content-row'>
                        <span className="label">{Translations.Copy_Instrument_Connection}:</span>
                <DropDownList 
                    className='form-control profiles-dropdown' 
                    data={props.profiles.data}
                    contentProp='label'
                    valueProp='id'
                    selected={selectedProfile.id} 
                    onChange={profileId => {
                            const newSelectedProfile = props.profiles.data.find(p=>p.id.toString() === profileId)
                            setSelectedProfile(newSelectedProfile)
                            props.getReproducibilityResult(newSelectedProfile.id, newSelectedProfile.isMultiGloss ? 1 : null)
                    }}
                />
            </div> : null }
            {selectedProfile.isMultiGloss ? 
                      <div className="content-row">
                      <span className="label">{Translations.Copy_Instrument_GlossLevel}:</span>
                    <DropDownList 
                    className="form-control profiles-dropdown" 
                    data={selectedProfile.glossLevels}
                    selected={selectedGloss} 
                    onChange={gloss => {
                        setSelectedGloss(gloss)
                        props.getReproducibilityResult(selectedProfile.id, Number.parseInt(gloss) + 1)
                        }}/>
                </div> : null}  
                        
    <div id={svgElementIdRef.current} ref={d3CanvasElement}></div>
    <div className="svg-legend-parent">
            <div className="legend-item">
            <div className="legend-item-blurb legend-item-blurb__yellow">
            </div>
            <span className="legend-item-text">{Translations.Copy_Instrument_Reproducibility_Average_Legend}</span>

            </div>
            <div className="legend-item">
            <div className="legend-item-blurb legend-item-blurb__green">
            </div>
            <span className="legend-item-text">{Translations.Copy_Instrument_Reproducibility_ThisInstrument_Legend}</span>

            </div>
            <div className="legend-item">
            <div className="legend-item-blurb legend-item-blurb__blue">
            </div>
            <span className="legend-item-text">{Translations.Copy_Instrument_Reproducibility_Reference_Legend}</span>

            </div>
            <div className="legend-item">
            <div className="legend-item-blurb legend-item-blurb__red">
            </div>
            <span className="legend-item-text">{Translations.Copy_Instrument_Reproducibility_Maximum_Legend}</span>

            </div>
        </div>
    </div>

}

export default ConnectionReproducibilityChart