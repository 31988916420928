import React from 'react';  
import { renderGlossValue } from '../../../../utils/gloss';
import { Translations } from '../../../../utils/translator';


const CreateAssignmentStep4 = (props) => {  
    const renderProject = () => {
        if(props.projects.length > 0){
            if(props.assignment.projectId === null){
                return <span>{props.projects[0].name}</span>
            } 
             else {
             const item = props.projects.find(p=>p.id === parseInt(props.assignment.projectId));
             if(item && item.name.length > 0){
                 return <span>{item.name}</span>
             }
            }
        }
        return null
    }
    const renderAuctomaticApproval = () => {
        if(props.assignment.automaticApproval){
            return <span>{Translations.Copy_Assignment_Create_AutomaticApprovalByCAPQ}</span>
        }
        return <span>{Translations.Copy_Assignment_Create_ManualApproval}</span>
    }

    const renderSuppliersDetails = () =>{
        const selectedSuppliersData = Object.values(props.assignment.suppliers);
        if(selectedSuppliersData.length == 0){
            return <span>{Translations.Copy_Assignment_Create_NoSuppliersSelected}</span>
        }
        const rows = selectedSuppliersData.map((supplier, i) => {
            return <tr key={`Supplier_${i}`}>
                    <td></td>
                    <td>{supplier.Name}</td>
                    <td>{supplier.City}</td>
                    <td>{supplier.Country}</td>
                </tr>
        });

        return <table className="capq-guide__summary-measurements">
            <thead>
                <tr>
                    <th></th>
                    <th>{Translations.Copy_General_Name}</th>
                    <th>{Translations.Copy_General_City}</th>
                    <th>{Translations.Copy_General_Country}</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    }

    const findToleranceSetName = (colour) =>{
        const item = props.toleranceSetList.find(t=> t.Id === parseInt(colour.ToleranceSetId));
            return item && item.Name !== undefined ? item.Name : '' 
    }
    
    const renderColourStandardDetails = () =>{
        const selectedColoursData = Object.values(props.selectedColours);
        if(selectedColoursData.length == 0){
            return <span>{Translations.Copy_Assignment_Create_NoColoursSelected}</span>
        }
        const rows = selectedColoursData.reduce((acc, colour, i) => {
            if(colour.Selected === true){
                acc.push(<tr key={'Colour_' + i}>
                        <td><span className="iconHTML" style={{backgroundColor: colour.HtmlColour}}></span></td>
                        <td>{colour.ProjectColourName}</td>
                        <td>{colour.ColourName}</td>
                        <td>{colour.Description}</td>
                        <td>{renderGlossValue(colour.Gloss60)}</td>
                        <td>{findToleranceSetName(colour)}</td>
                    </tr>
                )
            } 
            return acc
        },[]);

        return <div className="dataTables_wrapper">
            <div className="datatables-table">
                <table className="table datatable capq-guide__summary-measurements">
                     <thead>
                <tr>
                    <th></th>
                    <th>{Translations.Copy_General_Name}</th>
                    <th>{Translations.Copy_General_Colour}</th>
                    <th>{Translations.Copy_General_Description}</th>
                    <th>{Translations.Copy_General_Gloss}</th>
                    <th>{Translations.Copy_General_ToleranceSet}</th>
                </tr>
            </thead>
              <tbody>
                {rows}
            </tbody>
        </table>
        </div>
        </div>
    }
    return <div className="capq-guide__summary">
                <div className="assignment-create__step4-head">
                    <div className="assignment-create__step4-head-info">
                        <span>{Translations.Copy_General_Assignment}:</span>
                        <span>{props.assignment.name}</span>
                    </div>
                    <div className="assignment-create__step4-head-info">
                        <span>{Translations.Copy_General_Project}:</span>
                        {renderProject()}
                    </div>
                    <div className="assignment-create__step4-head-info">
                        <span>{Translations.Copy_General_Description}:</span>
                        <span>{props.assignment.description}</span>
                    </div>
                    <div className="assignment-create__step4-head-info">
                        <span>{Translations.Copy_Assignment_Create_AutomaticApproval}:</span>
                        {renderAuctomaticApproval()}
                    </div>
                    <div className="assignment-create__step4-head-info">
                        <span>{Translations.Copy_Assignment_Create_StartDate}:</span>
                        <span>{props.assignment.startDate !== null ? props.assignment.startDate.toLocaleDateString() : 'N/A' }</span>
                    </div>
                    <div className="assignment-create__step4-head-info">
                        <span>{Translations.Copy_Assignment_Create_EndDate}:</span>
                        <span>{props.assignment.endDate !== '' ? props.assignment.endDate.toLocaleDateString() : 'N/A' }</span>
                    </div>
                </div>
                <div className="main-panel-tabs">
                    <div className="form-group">
                        <label>{Translations.Copy_Assignment_Edit_SharedWith}: </label>
                        {renderSuppliersDetails()}
                    </div>
                    <div className="form-group">
                        <label>{Translations.Copy_Assignment_Create_SelectedColours}: </label>
                        {renderColourStandardDetails()}
                    </div>
                
                </div>
                                                        
            </div>

}

export default CreateAssignmentStep4
