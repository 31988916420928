import buildUniqueActionTypes from '../../utils/actionTypeUtils'
import { spinnerActive, spinnerInactive } from './spinnerActions';
import ajax from '../../utils/ajax';
import messageToastActions from '@ncscolour/ncs-ui-utils/components/MessageToast/messageToastActions';
import { MESSAGE_TYPES } from '@ncscolour/ncs-ui-utils/components/MessageToast/messageToastReducer'
import { Translations } from '../../utils/translator';
import formatDate from '../../utils/date';
import { setExportUrl } from './exportActions'

export const ConnectionActionTypes = buildUniqueActionTypes([
  'GET_CONNECTIONS_SUCCESS',
  'GET_CONNECTIONS_FAILED',
  'GET_CONNECTIONS',
  'GET_CONNECTION_SUCCESS',
  'GET_CONNECTION_FAILED',
  'GET_CONNECTION',
  'GET_CONNECTION_RESULT_SUCCESS',
  'GET_CONNECTION_RESULT_FAILED',
  'GET_CONNECTION_RESULT',
  'UPDATE_CONNECTION_SUCCESS',
  'UPDATE_CONNECTION_FAILED',
  'UPDATE_CONNECTION',
  'UPDATE_PROFILE_SUCCESS',
  'UPDATE_PROFILE_FAILED',
  'UPDATE_PROFILE',
  'GET_CONNECTION_RESULT_TABLE_SUCCESS',
  'GET_CONNECTION_RESULT_TABLE_FAILED',
  'GET_REPRODUCIBILITY_RESULT_SUCCESS',
  'GET_REPRODUCIBILITY_RESULT_FAILED'
])


export function fetchConnectionList(skip, take, filter, order, headers){
    let columns = [];
    if(order.length > 0){
      columns = headers.map(h => {
        return {data: h.ColumnData, name: h.ColumnName};
      })
    }
    return dispatch => {
      dispatch(spinnerActive())
      dispatch({
        type: ConnectionActionTypes.GET_CONNECTIONS,
        filter: filter
      })
      ajax({
        url: '/jsapi/Connection/ConnectionDataTable',
        type: 'POST',
        data: {
          start: skip,
          length: take,
          searchType: filter === undefined ? '' : filter.searchType,
          searchbox: filter === undefined ? '' : filter.searchBox,
          order: order,
          columns: columns,
          geometry: filter.geometry,
          instrumentModelId: filter.instrumentModelId,
        },
        error: (errorData) => {
            console.error('fetchConnectionList error:', errorData);
            dispatch({
              type: ConnectionActionTypes.GET_CONNECTIONS_FAILED,
              data: null
            })
        },
        success: (response) => {
            const parsedData = fixUnicodeInPhysicalPropertyDescription(response.data)
            dispatch({
              type: ConnectionActionTypes.GET_CONNECTIONS_SUCCESS,
              data: parsedData,
              headers: response.headers,
              recordsTotal: response.recordsTotal,
              recordsFiltered: response.recordsFiltered,
              localReferenceExist: response.localReferenceExist,
              instrumentModels: response.instrumentModels,
              geometryList: response.geometryList,
              order,
              skip,
              take,
            })
            dispatch(spinnerInactive())
        }
      })
    }
  }

  export function getConnection(instrumentSettingsId){
    return dispatch => {
      dispatch(spinnerActive())
      ajax({
        url: '/jsapi/Connection/GetConnectionInfo',
        type: 'POST',
        data: {
          instrumentSettingsId: instrumentSettingsId
        },
        error: (errorData) => {
            console.error('getConnection error:', errorData);
            dispatch({
              type: ConnectionActionTypes.GET_CONNECTION_FAILED,
              data: null
            })
        },
        success: (response) => {
            const parsedData = fixOneUnicodeInPhysicalPropertyDescription(response)
            dispatch({
              type: ConnectionActionTypes.GET_CONNECTION_SUCCESS,
              connectionDetails: parsedData
            })
            dispatch(spinnerInactive())
        }
      })
    }
  }

  function fixUnicodeInPhysicalPropertyDescription(data){
    const parsedData = data.reduce((acc, item) => { //Containing unicode codes in result from database, like degrees-character. This is a was to parse it
      acc.push(fixOneUnicodeInPhysicalPropertyDescription(item))
      return acc
    }, [])
    return parsedData
  }

  function fixOneUnicodeInPhysicalPropertyDescription(item){
    const itemCopy = {...item}
    itemCopy.physicalPropertyDescription = JSON.parse(`{"name": "${item.physicalPropertyDescription}"}`).name
    return itemCopy
  }

  export function updateInstrumentDescription(description, location, instrumentId){
    return dispatch => {
      dispatch(spinnerActive())
      ajax({
        url: '/jsapi/Connection/UpdateInstrumentDescription',
        type: 'POST',
        data: {
          description,
          location,
          instrumentId
        },
        error: (errorData) => {
            console.error('updateInstrumentDescription error:', errorData);
            dispatch({
              type: ConnectionActionTypes.UPDATE_CONNECTION_FAILED,
              data: null
            })
        },
        success: (response) => {
            dispatch({
              type: ConnectionActionTypes.UPDATE_CONNECTION_SUCCESS,
            })
            if(response){
              dispatch(messageToastActions.showMessage(Translations.Copy_General_Update_Success, MESSAGE_TYPES.Success))
          } else {
            dispatch(messageToastActions.showMessage(Translations.Copy_General_Update_Error, MESSAGE_TYPES.Error))
          }
            dispatch(spinnerInactive())
        }
      })
    }
  }

  export function getConnectionResult(id, gloss, skip, take, order, headers){
    let columns = [];
    if(order.length > 0){
      columns = headers.map(h => {
        return {data: h.ColumnData, name: h.ColumnName};
      })
    }
    return dispatch => {
      dispatch(spinnerActive())
      ajax({
        url: '/jsapi/Connection/GetConnectionResult',
        type: 'POST',
        data: {
          profileId: id,
          gloss,
          start: skip,
          length: take,
          order,
          columns
        },
        error: (errorData) => {
            console.error('getConnectionResult error:', errorData);
            dispatch({
              type: ConnectionActionTypes.GET_CONNECTION_RESULT_FAILED,
              data: null
            })
        },
        success: (response) => {
            dispatch({
              type: ConnectionActionTypes.GET_CONNECTION_RESULT_SUCCESS,
              connectionResultData: {
                skip: skip,
                take: take,
                order: order,
                maxValue: response.connectionResult.maxValue,
                items: response.connectionResult.items,
                headers: response.connectionResult.headers,
                data: response.connectionResult.datatable,
                recordsTotal: response.connectionResult.recordsTotal,
                recordsFiltered: response.connectionResult.recordsFiltered,
                reproducibility: response.connectionResult.reproducibility
              }
            })
            dispatch(spinnerInactive())
        }
      })
    }
  }

  export function getConnectionResultTable(id, gloss){
    return dispatch => {
      dispatch(spinnerActive())
      ajax({
        url: '/jsapi/Connection/GetConnectionResultTable',
        type: 'POST',
        data: {
          profileId: id,
          gloss
        },
        error: (errorData) => {
            console.error('getConnectionResultTable error:', errorData);
            dispatch({
              type: ConnectionActionTypes.GET_CONNECTION_RESULT_TABLE_FAILED,
              data: null
            })
        },
        success: (response) => {
            dispatch({
              type: ConnectionActionTypes.GET_CONNECTION_RESULT_TABLE_SUCCESS,
              connectionResultTable: response.connectionResultTable,
            })
            dispatch(spinnerInactive())
        }
      })
    }
  }

  export function getReproducibilityResult(id, gloss){
    return dispatch => {
      dispatch(spinnerActive())
      ajax({
        url: '/jsapi/Connection/getReproducibilityResult',
        type: 'POST',
        data: {
          profileId: id,
          gloss
        },
        error: (errorData) => {
            console.error('getReproducibilityResult error:', errorData);
            dispatch({
              type: ConnectionActionTypes.GET_REPRODUCIBILITY_RESULT_FAILED,
              data: null
            })
        },
        success: (response) => {
            dispatch({
              type: ConnectionActionTypes.GET_REPRODUCIBILITY_RESULT_SUCCESS,
              reproducibility: response.reproducibility,
            })
            dispatch(spinnerInactive())
        }
      })
    }
  }

  export function updateProfile(id, expiryDate, enabled, instrumentSettingsId){
    return dispatch => {
      dispatch(spinnerActive())
      ajax({
        url: '/jsapi/Connection/UpdateProfile',
        type: 'POST',
        data: {
          instrumentSettingsId,
          profileId: id,
          expiryDate: formatDate(expiryDate),
          enabled
        },
        error: (errorData) => {
            console.error('updateProfile error:', errorData);
            dispatch({
              type: ConnectionActionTypes.UPDATE_PROFILE_FAILED,
              data: null
            })
        },
        success: (response) => {
          if(response.result){
            dispatch(getConnection(instrumentSettingsId))
            dispatch(messageToastActions.showMessage(Translations.Copy_General_Update_Success, MESSAGE_TYPES.Success))
          } else {
            dispatch(messageToastActions.showMessage(Translations.Copy_General_Update_Error, MESSAGE_TYPES.Error))
          }
       
          dispatch(spinnerInactive())
        }
      })
    }
  }

  export function deleteProfile(profileId, instrumentSettingsId){
    return dispatch => {
      dispatch(spinnerActive())
      ajax({
        url: '/jsapi/Connection/DeleteProfile',
        type: 'POST',
        data: {
          profileId
        },
        error: (errorData) => {
            console.error('deleteProfile error:', errorData);
            // dispatch({
            //   type: ConnectionActionTypes.,
            //   data: null
            // })
        },
        success: () => {
          dispatch(getConnection(instrumentSettingsId))
          dispatch(spinnerInactive())
        }
      })
    }
  }

  export function downloadProfileMeasurement(id, referenseMeasurements){
    return dispatch => {
      dispatch(spinnerActive())
      ajax({
        url: '/jsapi/Connection/DownloadConnectionMeasurement',
        type: 'POST',
        data: {
          profileId: id,
          referenseMeasurements
        },
        error: (errorData) => {
            console.error('downloadProfile error:', errorData);
        },
        success: (data) => {   
          dispatch(setExportUrl(`/jsapi/File/Download?fileGuid=${data.FileGuid}&filename=${data.FileName}`));
          dispatch(spinnerInactive())
        }
      })
    }
  }

  downloadProfileMeasurement

