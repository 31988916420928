import React from 'react'

const NcsLogo = () => {
    return <section className="ncs-logotype">
    <svg id="ncsLogo" width="54" height="30" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 552.76 237.53">
        <path d="M566.28,231.53a11.88,11.88,0,1,1-11.86-11.86A11.78,11.78,0,0,1,566.28,231.53Zm-21.4,0a9.52,9.52,0,1,0,9.54-9.72A9.51,9.51,0,0,0,544.88,231.53Zm9.62-6.82c3.24,0,4.86,1.73,4.86,4a3.85,3.85,0,0,1-3,4l3.39,5.19h-2.43l-3.16-5h-1.92v5H550V224.71Zm0,1.92h-2.25V231h2.25c1.76,0,2.54-.88,2.54-2.21S556.26,226.63,554.5,226.63Z" transform="translate(-13.52 -11.55)"/><polygon className="cls-1" points="127.66 5.73 101.84 5.73 101.84 172.84 25.67 5.73 0 5.73 0 231.8 25.75 231.8 25.75 69.38 102.72 231.8 127.66 231.8 127.66 5.73"/>
        <path d="M394.58,129.76,326.1,90.23l23.57-23.58a80.6,80.6,0,0,1,18.49,32.22h27.7C386.45,66.14,366.56,41,339.58,26,290.47-1.35,228.47,11.35,192,53.66A118.08,118.08,0,0,0,167.5,99.27c-10.76,39.32.55,83.92,28.74,113.36s72.27,42.69,112,33.65,70.27-35.34,85.72-84H367.13a103,103,0,0,1-17.91,30.16l-23.13-23.11ZM281.5,223A96.08,96.08,0,0,1,219,197.36l62.49-62.51V223Zm4.19-151.29,33.62,19.41-33.62,33.63Zm0,63.19,33.6,33.58-33.6,19.4Zm2.93-3h94.05l-59.61,34.42Zm94.05-4.2h-94l34.39-34.4ZM346.81,63.58,322.34,88.05,285.69,66.89V37C308.46,37.56,330.74,47.66,346.81,63.58ZM281.5,37v87.78l-62.06-62C235.77,46.37,256.47,37.44,281.5,37Zm-76,43.1a109.91,109.91,0,0,1,11-14.32l62,61.92H190.25A96.22,96.22,0,0,1,205.48,80.06Zm-15.31,51.8h88.38L216,194.47a93.78,93.78,0,0,1-14.78-19.31A86,86,0,0,1,190.17,131.86Zm156.22,63.66C330.9,212,309.93,222.41,285.69,223V192.63l36.64-21.15Z" transform="translate(-13.52 -11.55)"/>
        <path d="M486.55,117.42c-30.67-14.46-46.5-23.66-46.5-45.8,0-20.64,14.14-31.28,33.93-31.28,11.69,0,21.19,5.52,26.69,16.77,4.89,10,4.66,22.59,2.75,37.17l26.12.13C537.92,43,512.92,14,474.57,14c-44.6,0-60.8,29.86-60.8,58.66,0,30.32,18.27,49.44,56.68,65.93,27.29,11.71,41.49,23.16,41.49,46.59,0,21-13.1,36.32-34.86,36.32-23.73,0-40.69-16.11-36.54-59.22H415.48c-.77,9.22-3,30.68,4.67,49.5,9.37,22.93,31.12,34.85,57.81,34.85,33.47,0,60-25.69,60-63.77C537.93,153,523.05,134.61,486.55,117.42Z" transform="translate(-13.52 -11.55)"/><polygon className="cls-1" points="127.66 5.73 101.84 5.73 101.84 172.84 25.67 5.73 0 5.73 0 231.8 25.75 231.8 25.75 69.38 102.72 231.8 127.66 231.8 127.66 5.73"/>
        <path d="M394.58,129.76,326.1,90.23l23.57-23.58a80.6,80.6,0,0,1,18.49,32.22h27.7C386.45,66.14,366.56,41,339.58,26,290.47-1.35,228.47,11.35,192,53.66A118.08,118.08,0,0,0,167.5,99.27c-10.76,39.32.55,83.92,28.74,113.36s72.27,42.69,112,33.65,70.27-35.34,85.72-84H367.13a103,103,0,0,1-17.91,30.16l-23.13-23.11ZM281.5,223A96.08,96.08,0,0,1,219,197.36l62.49-62.51V223Zm4.19-151.29,33.62,19.41-33.62,33.63Zm0,63.19,33.6,33.58-33.6,19.4Zm2.93-3h94.05l-59.61,34.42Zm94.05-4.2h-94l34.39-34.4ZM346.81,63.58,322.34,88.05,285.69,66.89V37C308.46,37.56,330.74,47.66,346.81,63.58ZM281.5,37v87.78l-62.06-62C235.77,46.37,256.47,37.44,281.5,37Zm-76,43.1a109.91,109.91,0,0,1,11-14.32l62,61.92H190.25A96.22,96.22,0,0,1,205.48,80.06Zm-15.31,51.8h88.38L216,194.47a93.78,93.78,0,0,1-14.78-19.31A86,86,0,0,1,190.17,131.86Zm156.22,63.66C330.9,212,309.93,222.41,285.69,223V192.63l36.64-21.15Z" transform="translate(-13.52 -11.55)"/>
    </svg>
  </section>
}

export default NcsLogo