import React from 'react';
import { Translations } from '../../../../utils/translator';

import './guide.scss'

const Guide = (props) => {  
    
    return <article className="guide-container">
        <div className="capq-guide__entity-header">
            <h1>{props.title}</h1>
        </div>
        {props.children[props.currentStep - 1]}

        <section>
        <div className="button-row-bottom">
            <div className="button-group">
            {props.currentStep !== 1 ? <button type="button" className="btn btn-primary" onClick={props.onPreviousClicked}><i className="fas fa-arrow-circle-left"></i>{Translations.Buttons_General_Back}</button> : null}
            <button onClick={props.onCancelClick} type="button" className="btn btn-secondary">{Translations.Buttons_General_CancelButton}</button>
            </div>
            <div></div>
            { props.currentStep !== props.totalSteps ? 
                <button className="btn btn-primary" onClick={props.onNext}><i className="fas fa-arrow-circle-right"></i>{Translations.Copy_General_Next}</button> : 
                <button type="button" onClick={props.onNext} className="btn btn-primary"><i className="fas fa-cloud-upload-alt"></i>{Translations.Buttons_General_SaveButton}</button>}
        </div>
        </section>         
    </article>

}

export default Guide;