import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCompanyList } from '../../actions/companyActions'
import { Translations } from '../../../utils/translator'
import CompanyList from '../../components/company/CompanyList'

const CompanyListContainer = () =>  {
    const dispatch = useDispatch()
    const companyList = useSelector(state => state.company.companyList)

    useEffect(()=> {
        if(companyList && companyList.headers.length <= 0){
            dispatch(getCompanyList(companyList.skip, companyList.take))
        }
    }, [dispatch,companyList, companyList.skip, companyList.take, companyList.skip, companyList.headers])

    return <>
            <header className="entity-header">
                <h1>{Translations.Copy_Company_CompanyList}</h1>
            </header>
            <CompanyList 
                companyList={companyList}
                getCompanyList={(skip, take) => dispatch(getCompanyList(skip, take))}
            />
        </>
}

export default CompanyListContainer