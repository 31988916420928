import buildUniqueActionTypes from '../../utils/actionTypeUtils'
import ajax from '../../utils/ajax'
import {spinnerActive, spinnerInactive} from './spinnerActions'
import getAntiForgeryToken from '../../utils/antiforgerytoken'

export const NCSSupportActionTypes = buildUniqueActionTypes(['DATABASE_UPDATE_START','DATABASE_UPDATE_SET_PROGRESS', 'DATABASE_UPDATE_DONE'])
export const checkDatabaseUpdateIntervalSec = 3

export function startDatabaseUpdate(){
    return dispatch => {
        dispatch(spinnerActive())
        ajax({
            url: '/jsapi/Account/StartDatabaseUpdate',
            type: 'POST',
            data: {
                __RequestVerificationToken: getAntiForgeryToken(),
            },
            error: (errorData) => {
                dispatch(spinnerInactive())
                console.error('executeDatabaseUpdate error:', errorData);
                dispatch({
                    type: NCSSupportActionTypes.DATABASE_UPDATE_START,
                    started: false
                })
            },
            success: (response) => {
                dispatch(spinnerInactive())
                dispatch({
                    type: NCSSupportActionTypes.DATABASE_UPDATE_START,
                    started: response.result === true
                })
                setTimeout(() => {
                    dispatch(checkDatabaseUpdateStatus())
                }, checkDatabaseUpdateIntervalSec * 1000)
            }
          })

    }
}

function checkDatabaseUpdateStatus(){
    return dispatch => {
        dispatch({
            type: NCSSupportActionTypes.DATABASE_UPDATE_SET_PROGRESS
        })
        ajax({
            url: '/jsapi/Account/CheckDatabaseUpdateStatus',
            type: 'POST',
            data: {
                __RequestVerificationToken: getAntiForgeryToken(),
            },
            error: (errorData) => {
                console.error('checkDatabaseUpdateStatus error:', errorData);
                dispatch({
                    type: NCSSupportActionTypes.DATABASE_UPDATE_DONE
                })
            },
            success: (response) => {
                if(response.inProgress !== true){
                    dispatch({
                        type: NCSSupportActionTypes.DATABASE_UPDATE_DONE,
                        errors: response.errors
                    })
                } else {
                    setTimeout(() => {
                        dispatch(checkDatabaseUpdateStatus())
                    }, checkDatabaseUpdateIntervalSec * 1000)
                }
            }
          })

    }
}