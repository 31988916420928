/*global $*/

/*
Wrapper to jQuery Ajax to not use that all over our code.
This way we can log or do other stuff on each ajax call, and also later switch to native fetch or similar instead of jQuery
*/
const ajax = (config) => {
    //console.log('An Ajax call was made!');
    return $.ajax(config);
};

export default ajax;