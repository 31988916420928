import React from 'react'
import DataTable from '../../common/DataTable'
import { Translations } from '../../../../utils/translator'

import './user-list.scss'

const UserList = (props) => {

    const renderMultiCompanyIcon = () => {
        return <i className="fas fa-user-friends" title={Translations.Copy_UserList_MultiOrganization}></i>
    }

    const renderLockedIcon = () => {
        return <i className="fas fa-lock"></i>
    }

    const config = {
        'UserId': (id, isHeader) => {
            if(isHeader){
                return <span></span>
            } else {
                const user = props.userList.data.find(u => u.UserId === id)
                return <span>
                    {user.CompanyCount > 1 ? renderMultiCompanyIcon() : null}
                    {user.IsLockedOut === true ? renderLockedIcon() : null}
                </span>
            }
        },
        'CoreRole': (role, isHeader) => {
            if(isHeader){
                return <span>Role</span>
            } else {
            return <span>{role === 1 ? 'User' : role === 2 ? 'Admin' : role === 3 ? 'Supervisor' : 'Unspecified role'}</span>
            }
        },
        'SendNotifications': (sendNotifications, isHeader) => {
            if(isHeader){
                return <span>Notifications</span>
            } else  if(sendNotifications){
                return <div className="fas fa-check-circle text-success" style={{width:'100%', textAlign:'center'}}></div>
            }
            return ''
        }
    }

     return <section className="user-list">
            <div className="button-row">
                <a id="NewUserBtn" className="btn btn-primary" href="/V2/Account/NewUser"><i className="fas fa-plus"></i> Add user</a>
            </div>
            <DataTable
                config={config}
                tableData={props.userList}
                selectedItems={[]}
                navigateToNewPage={(selectedPageInfo) => props.getUsersList(selectedPageInfo.selected * props.userList.take, props.userList.take)}
                updatePageSize={newSize => props.getUsersList(0, newSize)}
                selectProp={'UserId'}
                selectRow={(id) => {
                    window.location = `/V2/Account/EditUser/${id}`
                }}
            />
        </section>
}

export default UserList