export default function closestBySelector (element, selector) {
    while (element) {
        if (element.classList.contains(selector) || element === selector) {
            return element;
        }
        element = element.parentElement;
    }
    return null;
}

export function getSelectedRadioElement(element, radioInputsName) {
    const selector = !radioInputsName ? 'input[type="radio"]:checked' : 'input[type="radio"][name="' + radioInputsName + '"]:checked';
    return qs(element, selector);
}

export function qs(element, selector){
    return element.querySelector(selector);
}

export function qsa(element, selector){
    const nodeList = element.querySelectorAll(selector);
    return [].map.call(nodeList, function(node){
        return node;
    });
}

export function getFirstElementChild(node){
    if(!node){
        return null;
    }
    if(node.firstElementChild){
        return node.firstElementChild;
    }
    const elements = [].filter.call(node.childNodes, child => {
        return (child.nodeType === 1);
    });
    return (elements.length > 0) ? elements[0] : null;
}

export function addTd(content, colspan) {
    const td = document.createElement('td');
    td.innerHTML = content;
    if (colspan) {
        td.setAttribute('colspan', colspan);
    }
    return td;
}