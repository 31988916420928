import React, { useEffect, useState, useCallback } from 'react';

import { Translations } from '../../../../utils/translator';
import DropDownList from '@ncscolour/ncs-ui-utils/components/DropDownList';
import filterModel from '../../../models/filterModel'
import Url, { urlSearchKeys } from '../../../../utils/url'
import TagField from './TagField'

let savedDefaultFilter = null
let initialized = false

const SearchPanel = ({ colourLists, extraFilters, statusList, suppliersList, tagField, filter, onChange, onInit }) => {
    if(!savedDefaultFilter){
        savedDefaultFilter = {...filter}
    }
    
    const [filterState, setFilterState] = useState(filter)
    const [subFilterRowVisible, setSubFilterRowVisible] = useState(false)

    useEffect(() => {
        if(initialized !== true && (!tagField || (tagField && tagField.initialized === true))){
            initialized = true
            Url.addPopStateHandlers(() => {
                setFiltersByUrl();  
            });

            setFiltersByUrl()
            onInit(handleClearButtonClicked)
        }
    }, [tagField, setFiltersByUrl, onInit, handleClearButtonClicked])


    const setFiltersByUrl = useCallback(() => {
        const haveSearchQueryString = Url.haveSearchQueryString()
        if(haveSearchQueryString){
            setSubFilterRowVisible(true)
        } else {
            setSubFilterRowVisible(false)
        }
        
        const newFilterState = {...savedDefaultFilter}
        newFilterState.searchBox = Url.getQsValue(urlSearchKeys.SearchBox)
        const stype = Url.getQsValue(urlSearchKeys.SearchType)
        newFilterState.searchType = stype ? stype : 'contains'
        if(tagField && tagField.initialized === true){
            const validTagInfos = Url.getValidSearchTagInfos(tagField.suggestions);
            newFilterState.tags = validTagInfos
        }

        const colourListId = parseInt(Url.getQsValue(urlSearchKeys.SelectedColourListID))
        if(!isNaN(colourListId)){
            newFilterState.colourListId = colourListId
        }
        const supplierListId = parseInt(Url.getQsValue(urlSearchKeys.SelectedSuppliersListID))
        if(!isNaN(supplierListId)){
            newFilterState.supplierListId = supplierListId
        }

        newFilterState.showInactive = Url.getQsValue(urlSearchKeys.ShowInactive) === 'true'

        extraFilters ? extraFilters.forEach(extraFilter => {
            if(extraFilter.urlKeyType === 'int'){
                const filterId = parseInt(Url.getQsValue(urlSearchKeys[extraFilter.urlKey]))
                if(!isNaN(filterId)){
                    newFilterState[extraFilter.filterKey] = filterId
                }
            } else {
                const filterId = Url.getQsValue(urlSearchKeys[extraFilter.urlKey])
                if(filterId !== ''){
                    newFilterState[extraFilter.filterKey] = filterId
                }
            }
        }) : null

        //TODO: StatusList, PassFailList
        setFilterState(newFilterState)
        onChange(newFilterState)
    }, [onChange, tagField, extraFilters])


    const handleClearButtonClicked = useCallback(() => {
        const clearedFilter = Object.keys(filterModel).reduce((acc, filterKey) => {
            acc[filterKey] = savedDefaultFilter[filterKey]
            return acc
        }, {})
        if(tagField){
            const newFilterState = {
                ...filterState,
                tags: []
                }
            setFilterState(newFilterState)
            
        }
        setFilterState(clearedFilter)
        Url.clearSearchQs();
        Url.changeAddressQs(null, null, false, true);
        onChange(clearedFilter)
    }, [onChange, tagField, filterState])


    
    const renderFilterButton = () => {
        if(!allFiltersHidden()){
            return <div className="form-group">
                <button type="button" className="btn btn-outline-secondary" onClick={() => setSubFilterRowVisible(!subFilterRowVisible)}>
                    <i className="fas fa-filter"></i>
                    {Translations.Buttons_General_FiltersButton}
                </button>
            </div>
        }
        return null;
    }

    const allFiltersHidden = () => {
        return filterState.hideShowInactiveControl === true && 
                filterState.hidePassFailControl === true && 
                !filterState.tagField &&
                (!extraFilters || extraFilters.length === 0) &&
                (!colourLists || colourLists.length === 0) &&
                (!suppliersList || suppliersList.length === 0) &&
                (!statusList || statusList.length === 0)
    }

    const renderColourLists = () => {
        if(!colourLists || colourLists.length === 0) return null

        return <div className="dropdown-select form-group">
            <label className="form-control-label">{Translations.Copy_ColourStandard_General_ColourList}:</label>
            <DropDownList className="form-control" data={colourLists} contentProp={'Text'} valueProp={'Value'} selected={filterState.colourListId} onChange={colourListId => {
                const newFilterState = {
                    ...filterState,
                    colourListId: parseInt(colourListId)
                }
                setFilterState(newFilterState)
                Url.changeAddressQs(urlSearchKeys.SelectedColourListID, `${newFilterState.colourListId}`)
                onChange(newFilterState)
            }} />
        </div>
    }

    const renderStatusList = () => {
        if(!filterState.statusList || filterState.statusList.length === 0) return null

        return <div className="dropdown-select form-group">
                <label className="form-control-label">{Translations.Copy_General_Status}:</label>
                <DropDownList className="form-control" data={filterState.statusList} contentProp={'Text'} valueProp={'Value'} onChange={() => console.log('TODO')}/>
            </div>
    }

    const renderSupplierList = () => {
        if(!suppliersList || suppliersList.length === 0) return null

        return <div className="dropdown-select form-group">
            <label className="form-control-label">{Translations.Copy_Assignment_Edit_SharedWith}:</label>
            <DropDownList className="form-control" data={suppliersList} contentProp={'Text'} valueProp={'Value'} selected={filterState.supplierListId} onChange={supplierListId => {
                const newFilterState = {
                    ...filterState,
                    supplierListId: parseInt(supplierListId)
                }
                setFilterState(newFilterState)
                Url.changeAddressQs(urlSearchKeys.SelectedSuppliersListID, `${newFilterState.supplierListId}`)
                onChange(newFilterState)
            }} />
        </div>
    }


    const renderPassFail = () => {
        if(!filterState.hidePassFailControl){
            return <div className="dropdown-select form-group">
                <label htmlFor="passfail" className="form-control-label">{Translations.Copy_General_PassedTolerance}</label>
                <select className="custom-select form-control form-control-sm dropdown-filter js_passFailFilter" id="passfail" name="passfail">
                    <option value=""></option>
                    <option value="pass">Yes</option>
                    <option value="fail">No</option>
                </select>
            </div>
        }
        return null
    }

    const renderInactive = () => {
        if(!filterState.hideShowInactiveControl){
            return <div className="form-group form-check">
                <input id="show-inactive" className="checkbox-filter" type="checkbox" checked={filterState.showInactive} onChange={() => {
                    const newFilterState = {
                        ...filterState,
                        showInactive: !filterState.showInactive
                    }
                    setFilterState(newFilterState)
                    if(newFilterState.showInactive === true){
                        Url.changeAddressQs(urlSearchKeys.ShowInactive, 'true')
                    } else {
                        Url.changeAddressQs(urlSearchKeys.ShowInactive, null)
                    }
                    onChange(newFilterState)
                }} />
                <label htmlFor="show-inactive">{Translations.Copy_ColourStandard_General_ShowInactiveColourStandards}</label>
            </div>
        }
        return null
    }

    const updateSearchType = (event) => {
        const newFilterState = {
            ...filterState,
            searchType: event.target.value
            }
        setFilterState(newFilterState)
        Url.changeAddressQs(urlSearchKeys.SearchType, event.target.value)
        onChange(newFilterState)
    }

    return <div className="entitylisting__action-panel js_searchPanel">
                <div className="action-bar1">
                    <div className="input-group js_searchType">
                        <div className="input-group-prepend">
                            <select className="btn btn-outline-secondary dropdown-toggle js_dropDownButton action-bar1__search-type" value={filterState.searchType} onChange={updateSearchType}>
                                <option className="dropdown-item js_item" value={'contains'}>{Translations.InputFields_General_Contains}</option>
                                <option className="dropdown-item js_item" value={'starts with'}>{Translations.InputFields_General_StartsWith}</option>
                                <option className="dropdown-item js_item" value={'ends with'}>{Translations.InputFields_General_EndsWith}</option>
                            </select>
                        </div>
                        <input type="text" name="searchbox" id="searchbox" className="form-control js_searchBox" aria-label="Text input with dropdown button" value={filterState.searchBox} 
                        onChange={event => {
                            setFilterState(
                                {
                                    ...filterState,
                                    searchBox: event.target.value
                                })
                        }} 
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                Url.changeAddressQs(urlSearchKeys.SearchBox, filterState.searchBox)
                                onChange(filterState)
                            }
                        }}
                        placeholder={Translations.InputFields_General_SearchPlaceholder}/>
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary js_searchButton" type="button" onClick={() => onChange(filterState)} >{Translations.Buttons_General_SearchButton}</button>
                        </div>
                    </div>
                    {renderFilterButton()}
                    <div className="form-group clear-filter">
                        <button type="button" className="btn btn-outline-secondary js_clearFilter" onClick={handleClearButtonClicked}>
                        {Translations.Buttons_General_ClearButton}
                        </button>
                    </div>
                </div>

                {allFiltersHidden() ? null : <div className="action-bar2">
                    <div className={subFilterRowVisible === true ? '' : 'collapse'}>
                        <div className="filters-row">
                            {tagField && tagField.initialized === true ? <TagField
                                tags={filterState.tags}
                                suggestions={tagField.suggestions}
                                onChange={tags => {
                                    const newFilterState = {
                                        ...filterState,
                                        tags: tags
                                    }
                                    setFilterState(newFilterState)
                                    Url.changeAddressQs(urlSearchKeys.Tags, tags.map(t => t.name).join(','))
                                    console.log('newFilterState', newFilterState)
                                    onChange(newFilterState)
                                }}
                            /> : null }

                            {renderColourLists()}
                            {renderStatusList()}
                            {renderSupplierList()}
                            {extraFilters ? extraFilters.map((ef, index)=> {
                                return <div key={index} className="dropdown-select form-group">
                                    <label className="form-control-label">{ef.title}:</label>
                                    <DropDownList className="form-control" selected={ef.selected} data={ef.data} contentProp={'Text'} valueProp={'Value'} onChange={ef.onChange}/>
                                </div> 
                                }) : null}
                            {renderPassFail()}
                            {renderInactive()}
                        </div>
                    </div>
                </div>}

            </div>
}
export default SearchPanel