import { SpinnerActionTypes } from '../actions/spinnerActions'
const initialState = {
    active: false
}
let currentCalls = 0

const spinnerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SpinnerActionTypes.SET_SPINNER_ACTIVE:
            currentCalls++
            return {
                ...state,
                active: true
            }

        case SpinnerActionTypes.SET_SPINNER_INACTIVE:
            currentCalls--
            return {
                ...state,
                active: (currentCalls > 0)
            }
            
        default:
            return state
    }
}
export default spinnerReducer