if (typeof Object.assign !== 'function') {
    console.log('Will polyfill Object.prototype.assign()')
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, 'assign', {
      value: function assign(target, varArgs) { //eslint-disable-line
        'use strict';
        if (target === null || target === undefined) {
          throw new TypeError('Cannot convert undefined or null to object');
        }
  
        const to = Object(target);
  
        for (let index = 1; index < arguments.length; index++) {
          const nextSource = arguments[index];
  
          if (nextSource !== null && nextSource !== undefined) { 
            for (let nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }