import { NCSSupportActionTypes, checkDatabaseUpdateIntervalSec } from '../actions/ncsSupportActions'

const initialState = {
    databaseUpdateInProgress: false,
    databaseUpdateSeconds: 0,
    databaseUpdateErrors: []
}

const ncsSupportReducer = (state = initialState, action) => {
    switch (action.type) {
        case NCSSupportActionTypes.DATABASE_UPDATE_START:
            return {
                ...state,
                databaseUpdateInProgress: action.started,
                databaseUpdateSeconds: 0
            }
       
        case NCSSupportActionTypes.DATABASE_UPDATE_DONE:
            return {
                ...state,
                databaseUpdateInProgress: false,
                databaseUpdateErrors: action.errors
            }

        case NCSSupportActionTypes.DATABASE_UPDATE_SET_PROGRESS:
            return {
                ...state,
                databaseUpdateSeconds: state.databaseUpdateSeconds + checkDatabaseUpdateIntervalSec
            }
        
        default:
            return state
    }
}

export default ncsSupportReducer