/*global capqConfig */
const config = capqConfig || {};

// KEEP THESE COLOURS IN SYNC WITH settings.scss
config.black = '#000000';
config.capqRed = '#E2004A';
config.mediumGray = '#cccccc';
config.green = '#28a745';
config.darkGray = '#1d342d';
config.gray = '#666666';


export default config;