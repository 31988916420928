import React, { useState } from 'react'
import validate from 'validate.js';
import { Translations } from '../../../../utils/translator'

const NewUser = (props) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [userRole, setUserRole] = useState(1)
    const [sendNotifications, setSendNotifications] = useState(false)
    const [validationResult, setValidationResult] = useState(null)

    const checkFields = (fieldKey) => {
        return (validationResult && validationResult[fieldKey] && validationResult[fieldKey].length > 0)
    }

    const validationMessage = (fieldKey) => {
        return checkFields(fieldKey)?
                <div className="validation-message"><p className="help-block error">{validationResult[fieldKey].join(' ')}</p></div>
                :
                null
    }


    const baseClass = 'form-group'
    const emailFormGroupClassName = checkFields('email') ? `${baseClass} has-error` : baseClass
    const nameFormGroupClassName = checkFields('name') ? `${baseClass} has-error` : baseClass

     return <section className="new-user__form">
            <div className={nameFormGroupClassName}>
                <label className="form-control-label">{Translations.Copy_NewUser_ContactName}</label>
                 <input 
                    value={name} 
                    onChange={(event) => setName(event.currentTarget.value)} 
                    className="form-control text-box" 
                    type="text"
                 />
                 {validationMessage('name')}
            </div> 

            <div className={emailFormGroupClassName}>
                <label className="form-control-label">{Translations.Copy_General_Email}</label>
                <input 
                    value={email} 
                    onChange={(event) => setEmail(event.currentTarget.value)} 
                    className="form-control text-box" 
                    type="text"
                 />
                 {validationMessage('email')}
            </div>

            <div className="form-group">
                    <label className="form-control-label">{Translations.Copy_NewUser_UserRole}:</label>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="userRole" id="userRole1" value="1" defaultChecked onChange={(event) => setUserRole(parseInt(event.currentTarget.value))} />
                        <label className="form-check-label">User</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="userRole" id="userRole2" value="2" onChange={(event) => setUserRole(parseInt(event.currentTarget.value))}/>
                        <label className="form-check-label">Admin</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="userRole" id="userRole3" value="3" onChange={(event) => setUserRole(parseInt(event.currentTarget.value))}/>
                        <label className="form-check-label">Supervisor</label>
                    </div>
            </div>

            <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" checked={sendNotifications} onChange={(event) => setSendNotifications(event.currentTarget.checked)} />
                        <label className="form-check-label">User want to receive notifications</label>
                    </div>
                </div>

            <div className="form-group">
                <button className="btn btn-primary" onClick={() => {
                        const user = {
                            name: name,
                            email: email,
                            userRole: userRole,
                            sendNotifications: sendNotifications
                        }
                        
                        const validationConstraints = {
                            name: {
                                presence: {
                                    allowEmpty: false
                                }
                            },
                            email: {
                              email: true
                            },
                            userRole:{
                                presence: {
                                    allowEmpty: false
                                }
                            }
                          };
                          const hasErrors = validate(user, validationConstraints)
                          setValidationResult(hasErrors)
                          if(!hasErrors){
                            props.onSave(user)
                          }
                    
                        }}>{Translations.Buttons_General_SaveButton}</button>
            </div>
        </section>
}

export default NewUser