import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
    getCompany,  
    updateCompany
    } from '../../actions/companyActions'
import EditCompany from '../../components/company/EditCompany'
import { getTimezones, getCountries } from '../../actions/utilsActions'

const EditCompanyContainer = (props) => {
    const dispatch = useDispatch()
    const companyToEdit = useSelector(state => state.company.companyToEdit)
    const timezones = useSelector(state => state.utils.timezones)
    const countries = useSelector(state => state.utils.countries)

    useEffect(()=> {
        if(companyToEdit.companyId === undefined){
            dispatch(getCompany(props.companyId))
            dispatch(getTimezones())
            dispatch(getCountries())
        }
    }, [dispatch, companyToEdit.companyId, props.companyId])

    return (companyToEdit.companyId !== '' && Object.keys(timezones).length > 0) ? <EditCompany 
                companyId={props.companyId}
                companyToEdit={companyToEdit}
                isCoreSupport={props.isCoreSupport}
                isSupervisor={props.isSupervisor}
                canEdit={props.canEdit}
                timezones={timezones}
                countries={countries}
                onSave={updatedCompany => dispatch(updateCompany(updatedCompany))}
            /> : null
}

export default EditCompanyContainer