import Browser from './browser';

const Events = {
    sendEvent: (name, data) => {
        if (Browser.isIE()) {
            const ieEvent = document.createEvent('Event');
            ieEvent.initEvent(name, true, true);    // Deprecated way but only way that works in old IE
            ieEvent.detail = data;
            document.dispatchEvent(ieEvent);
            //console.log(`EVENT "${name}" FOR INTERNET EXPLORER WAS SENT!`, data);
        } else {
            const event = new CustomEvent(name, { detail: data });    // Correct way but no support in IE
            document.dispatchEvent(event);
            //console.log(`EVENT "${name}" WAS SENT!`, data);
        } return true;
    },
    AssignmentTabClicked: 'capq_assignment-tab-clicked',
    ProjectMeasurementReview: 'capq_project-measurement-review',
    ProjectMeasurementStatusChanged: 'capq_project-measurement-status-change',
    TabClicked: 'capq_tab-clicked',
    DialogLoaded: 'capq_dialog-loaded',
    DialogClosed: 'capq_dialog_closed',
    DialogInitOpenerEvent: 'capq_dialog-init-opener-event',
    FileUploadFileChosen: 'capq_file-upload-file-chosen',
    SelectedColourChange: 'capq_selected_colour_change',
    SearchUpdate: 'capq_search-update',
    SearchClear: 'capq_search-clear',
    EditTolerance: 'capq_edit-tolerance',
    EditColour: 'capq_edit-colour',
    ToleranceUpdated: 'capq_tolerance_updated',
    SearchSuggestNewItem: 'capq_search_suggest_new_item',
    TagsChange: 'capq_tags-change',
    TagsLoaded: 'capq_tags-loaded',
    SearchFieldSuggestDataLoaded: 'capq_search-field-suggest-loaded',
    EntitySearchInProgress: 'capq_entity_search_inprogress',
    EntitySearchDone: 'capq_entity_search_done',
    EntityCreateInProgress: 'capq_entity_create_inprogress',
    EntityCreateDone: 'capq_entity_create_done',
    ProjectColourStatusChanged: 'capq_project-colour-status-changed',
    ProjectColoursTableUpdated: 'capq_project-colours-table-updated',
    ProjectColourAdded: 'capq_project-colour-added',
    SupplierStatusChanged: 'capq_supplier-status-changed',
    SuppliersShowInactiveClicked: 'capq_suppliers-show-inactive-clicked',
    SuppliersAdded: 'capq_suppliers-added',
    ColourStandardCreateToggleMeasurement: 'capq_colour-standard-create-toggle-measurement',
    ColourStandardChangeToleranceSet: 'capq_colour-standard-change-toleranceset',
    ColourStandardChangeGloss: 'capq_colour-standard-change-gloss',
    GTM: {
        Entity: {
            Download: 'gtm_entity-download', //Is set in C#!
            Upload: 'gtm_entity-upload', //Is set in C#!
            Create: {
                FileSection: 'gtm_entity-create-file-section',
                EditSection: 'gtm_entity-create-edit-section',
                ReviewSection: 'gtm_entity-create-review-section',
                Success: 'gtm_entity-create-step-success'
            },
            Activate: 'gtm_entity-activate', //Is set in C#!
            Deactivate: 'gtm_entity-deactivate', //Is set in C#!
            Delete: 'gtm_entity-delete' //Is set in C#!
        },
        Language:{
            Changed: 'gtm_language-changed'
        }
    }
};

export default Events;