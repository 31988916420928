import UrlParse from 'url-parse'; //https://github.com/unshiftio/url-parse
import objectsAreEqualShallow from './obj';

export const urlSearchKeys = {
    SearchBox: 'search',
    Tags: 'tags',
    ShowInactive: 'inactive',
    SelectedColourListID: 'clid',
    SelectedStatus: 'status',
    SelectedPassFail: 'tolerance',
    SearchType: 'stype',
    SelectedSuppliersListID: 'suppid',
    EntityId: 'entityId',
    InstrumentModel: 'instrumentmodelid',
    Geometry: 'geometry'
};

const parseQs = () => {
    if(window.location.search === '') {
         return {};
    }
    const qs = window.location.search.substring(1).split('&');
    const qsObj = qs.reduce((acc, item) => {
        const parts = item.split('=');
        acc[parts[0].toLowerCase()] = parts[1] ? parts[1].toLowerCase() : '';
        return acc;
    }, {});
    return qsObj;
};

const popStateHandlers = [];

class Url {
    constructor(){
        this.parser = new UrlParse(null, null, parseQs);
        this.addPopStateHandlers = (popStateHandler) => popStateHandlers.push(popStateHandler); //To have all popstate handling in one place!

        window.onpopstate = event => {
            this.setQsByState(event.state);
            this.changeAddressQs(null, null, true);
            popStateHandlers.forEach(popStateHandler => {
                popStateHandler(event);
            });
        };
    }

    changeAddress(address, state, replace){
        if(replace) {
            window.history.replaceState(state, '', address);
        } else {
            window.history.pushState(state, '', address);
        }
    }

    changeAddressQs(queryStringKey, queryStringValue, replace, forceChange){
        const previousState = {...this.parser.query};
        if(queryStringValue && queryStringValue.length > 0){
            this.parser.query[queryStringKey] = queryStringValue;
        } else if(typeof queryStringKey !== undefined && queryStringKey !== null) {
            delete this.parser.query[queryStringKey];            
        }
        const newState = {...this.parser.query};
        if(forceChange || !objectsAreEqualShallow(newState, previousState)){ //If new query are exact same as previous, dont touch address
            this.changeAddress(this.getFullAddress(), newState, replace);
        }
    }

    setQsByState(state){
        this.parser.query = typeof state === 'undefined' || state === null ? {} : {...state};
    }

    getQueryStringAsString(){
        const qsParts = Object.keys(this.parser.query).reduce((acc, qsKey) => {
            acc.push(`${qsKey}=${this.parser.query[qsKey]}`);
            return acc;
        }, []);
        const qsString = qsParts.join('&');
        return qsString.length > 0 ? `?${qsParts.join('&')}` : '';
    }

    getFullAddress(){
        return `${this.parser.pathname}${this.getQueryStringAsString()}`;
    }

    haveQueryString(optionalQueryStringKey){
        if(optionalQueryStringKey && optionalQueryStringKey.length > 0){
            return this.parser.query[optionalQueryStringKey] && this.parser.query[optionalQueryStringKey].length > 0;
        } else {
            return Object.keys(this.parser.query).length > 0;
        }
    }

    haveSearchQueryString(){
        let exists = false;
        Object.keys(urlSearchKeys).forEach(key => { //Only clear search related querystrings, not others!
            if(this.parser.query[urlSearchKeys[key]] !== undefined) {
                exists = true;
            }
        });
        return exists;
    }

    getQsValue(queryStringKey){
        const qsValue = this.parser.query[queryStringKey];
        return qsValue !== undefined ? decodeURI(qsValue) : '';
    }

    getValidSearchTagInfos(tagSuggestionList){
        const urlTagsValue = this.getQsValue(urlSearchKeys.Tags);
        if(urlTagsValue.length === 0){
            return [];
        }
        const urlTags = urlTagsValue.split(',');
        const validTagInfos = urlTags.reduce((acc, urlTagName)=> {
            const validUrlTags = tagSuggestionList.filter(suggestion => {
                return suggestion.name.toLowerCase() === urlTagName.toLowerCase();
            });
            if(validUrlTags.length > 0){
                let objectToAdd = {...validUrlTags[0]};
                acc.push(objectToAdd);
            }
            return acc;
        }, []);
        return validTagInfos;
    }

    clearSearchQs(){
        Object.keys(urlSearchKeys).forEach(key => { //Only clear search related querystrings, not others!
            delete this.parser.query[urlSearchKeys[key]];
        });
    }
}
const urlInstance = new Url();
export default urlInstance;