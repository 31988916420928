/* global dataLayer */
import Events from './events';

class GTMEventsService {
    constructor(companyId, companyName, userId, userName) {
        this.actions = {
            download: 'Download',
            create: {
                fileUploaded: 'Create - file(s) uploaded',
                fileSection: 'Create - file section',
                editSection: 'Create - edit section',
                reviewSection: 'Create - review section',
                success: 'Create - success'
            },
            activate: 'Activate',
            deactivate: 'Deactivate',
            delete: 'Delete'
        };

        this.eventName = 'CAPQcustomEvent'; //If changing this value, it also needs to be changed in Google TagManager!
        this.companyId = companyId;
        this.companyName = companyName;
        this.userName = userName;
        this.userId = userId;
        //console.log(`Setting up GTM with ${companyId}: ${companyName}`);
    }

    setupEvents() {

        document.addEventListener(Events.GTM.Entity.Create.FileSection, data => {
            const d = this.getGTMdata(this.eventName, data.detail.entityName, this.actions.create.fileSection, data.detail.entityID);
            dataLayer.push(d);
        });

        document.addEventListener(Events.GTM.Entity.Download, data => {
            const d = this.getGTMdata(this.eventName, data.detail.entityName, this.actions.download, data.detail.entityIDs.join(','), 200);
            dataLayer.push(d);
        });

        document.addEventListener(Events.GTM.Entity.Upload, data => {
            const d = this.getGTMdata(this.eventName, data.detail.entityName, this.actions.create.fileUploaded, data.detail.entityID);
            dataLayer.push(d);
        });

        // Colour Standard Create, Step 2 shown, show edit section. Send amount of available standards
        document.addEventListener(Events.GTM.Entity.Create.EditSection, data => {
            const d = this.getGTMdata(this.eventName, data.detail.entityName, this.actions.create.editSection, `File amount:${data.detail.fileAmount}`);
            dataLayer.push(d);
        });

        // Colour Standard Create, Step 3 shown, show review section. Send amount of chosen standards
        document.addEventListener(Events.GTM.Entity.Create.ReviewSection, data => {
            const d = this.getGTMdata(this.eventName, data.detail.entityName, this.actions.create.reviewSection, `Amount:${data.detail.checkedMeasurementsAmount}`);
            dataLayer.push(d);
        });

        // Colour Standard Create, Success, Send amount of uploaded standards
        document.addEventListener(Events.GTM.Entity.Create.Success, data => {
            const d = this.getGTMdata(this.eventName, data.detail.entityName, this.actions.create.success, `Imported:${data.detail.importSuccessAmount}`);
            dataLayer.push(d);
        });

        // Colour Standard Deactivate, label is the Colour Standard name and ID.
        document.addEventListener(Events.GTM.Entity.Deactivate, data => { 
            const d = this.getGTMdata(this.eventName, data.detail.entityName, this.actions.deactivate, data.detail.entityIDs.join(','));
            dataLayer.push(d);
        });

        // Colour Standard Activate, label is the Colour Standard name and ID.
        document.addEventListener(Events.GTM.Entity.Activate, data => {
            const d = this.getGTMdata(this.eventName, data.detail.entityName, this.actions.activate, data.detail.entityIDs.join(','));
            dataLayer.push(d);
        });

        // Colour Standard Delete, label is the Colour Standard name and ID.
        document.addEventListener(Events.GTM.Entity.Delete, data => { 
            const d = this.getGTMdata(this.eventName, data.detail.entityName, this.actions.delete, data.detail.entityIDs.join(','));
            dataLayer.push(d);
        });

        document.addEventListener(Events.GTM.Language.Changed, data => {
            const d = this.getGTMdata(this.eventName, 'Language', 'Changed', data.detail.culture);
            dataLayer.push(d);
        });
    }

    getGTMdata(event, category, action, label, value){
        const gtmData = {
            event: event,
            category: category,
            action: action,
            label: label,
        };
        if(value && parseInt(value) > 0) { //Note: GTM value can only be an integer, GA rule!
            gtmData.value = value;
        }
        if (this.userId) {
            gtmData.userId = this.userId;
        }
        if (this.userName) {
            gtmData.userName = this.userName;
        }
        if (this.companyId) {
            gtmData.companyId = this.companyId;
        }
        if (this.companyName) {
            gtmData.companyName = this.companyName;
        }
        return gtmData;
    }
}
export default GTMEventsService;