import React, { useRef, useState, useEffect } from 'react'
import ReactTags from 'react-tag-autocomplete'  //https://github.com/i-like-robots/react-tags
import { Translations } from '../../../../utils/translator';

import './tag-field.scss';

const TagField = (props) => {
    const tagRef = useRef()
    
    const [tags, setTags] = useState([])

    useEffect(() => {
        setTags(props.tags)
    }, [props.tags])
   
    return <div className="dropdown-select form-group">
            <label className="form-control-label">{Translations.InputFields_General_Tags}:</label>
            <ReactTags
            ref={tagRef}
            tags={tags}
            classNames={{
                root: 'tag-field form-control',
                rootFocused: 'is-focused',
                selected: 'tag-field__selected',
                selectedTag: 'tag-field__selected-tag',
                selectedTagName: 'tag-field__selected-tag-name',
                search: 'tag-field__search',
                searchWrapper: 'tag-field__search-wrapper',
                searchInput: 'tag-field__search-input',
                suggestions: 'tag-field__suggestions',
                suggestionActive: 'is-active',
                suggestionDisabled: 'is-disabled',
                suggestionPrefix: 'tag-field__suggestion-prefix'
            }}
            suggestions={props.suggestions}
            onDelete={tagIndex => {
                const newTags = [...tags]
                newTags.splice(tagIndex, 1)
                setTags(newTags)
                props.onChange(newTags)
            }}
            onAddition={tag => {
                const newTags = [...tags]
                newTags.push(tag)
                setTags(newTags)
                props.onChange(newTags)
            }}
            />
        </div>
}

export default TagField