import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import NewUser from '../../components/account/NewUser'
import { createUser } from '../../actions/accountActions'
import { Translations } from '../../../utils/translator'

const PageHeader = () => {
        return <div className="entity-header">
            <h1>{Translations.Copy_NewUser}</h1>
        </div>
}

const NewUserContainer = () => {
    const dispatch = useDispatch()
    const inProgress = useSelector(state => state.account.addInProgress)

    return <div>
            <PageHeader />
            <NewUser 
                onSave={(user) => dispatch(createUser(user))} 
                inProgress={inProgress}
            />
        </div>

}

export default NewUserContainer