import { CreateAssignmentActionTypes } from '../actions/createAssignmentActions';
import filterModel from '../models/filterModel';
import datatableModel from '../models/datatableModel';

const initialState = {
    loading: false,
    loadingAV: false,
    projects: [],
    suppliers: {
        ...datatableModel,
        headers: [],
        data: [],
        order: [],
    },
    selectAllSuppliers: false,
    filter: {
        ...filterModel,
    },
    availableColours: {
        ...datatableModel,
        headers: [],
        data: [],
        materials: [],
        toleranceSetList: [],
        cacheGuids: [],
        order: [],
    },
    selectAllColours: false,
    selectedColours: {},
    assignment : {
        name: '',
        description: '',
        startDate: new Date(),
        endDate: '',
        suppliers: {},
        projectId: null,
        automaticApproval: false,
    },
    modalVisible: false
};

const createAssignmentPageReducer = (state = initialState, action) => {

    switch(action.type){
        case CreateAssignmentActionTypes.SAVE_ASSIGNMENT_STEP_1:
            return {
                ...state,
                assignment: action.assignment
            }

        case CreateAssignmentActionTypes.FETCHED_PROJECTS:
            return {
                ...state,
                projects: action.data,
                loading: false
            }
        case CreateAssignmentActionTypes.LOADING_PROJECTS || CreateAssignmentActionTypes.LOADING_SUPPLIERS:{
            return {
                ...state,
                loading: true
            }
        }
                
        case CreateAssignmentActionTypes.SHOW_CREATE_PROJECT_MODAL:
            return {
                ...state,
                modalVisible: true
            }
        
        case CreateAssignmentActionTypes.HIDE_CREATE_PROJECT_MODAL:
            return {
                ...state,
                modalVisible: false
            }  
        case CreateAssignmentActionTypes.PROJECT_CREATED:{
            return {
                ...state,
                assignment:{
                    ...state.assignment,
                    projectId: action.data
                },
            }  
        }

        case CreateAssignmentActionTypes.FETCHED_SUPPLIERS:{
            return {
                ...state,
                suppliers: {
                    ...state.suppliers,
                    data: action.data,
                    headers: action.headers
                },
                loading: false
            }
        }
        case CreateAssignmentActionTypes.SELECT_ALL_SUPPLIERS:
            return {
                ...state,
                selectAllSuppliers: action.selectAll
            }

        case CreateAssignmentActionTypes.LOADING_AVAILABLE_COLOURS:
            return {
                ...state,
                loadingAV: true
            }

        case CreateAssignmentActionTypes.FETCHED_AVAILABLE_COLOURS: {
            return {
                ...state,
                availableColours: {
                    ...state.availableColours,
                    data: action.data,
                    headers: action.headers,
                    recordsFiltered: action.recordsFiltered,
                    recordsTotal: action.recordsTotal,
                    skip: action.skip,
                    take: action.take,
                    materials: action.materials,
                    toleranceSetList: action.toleranceSetList,
                    cacheGuids: action.cacheGuids,
                    order: action.order,
                },
                loadingAV: false
            }   
         }  
         
         case CreateAssignmentActionTypes.UPDATE_FILTER:
            return {
                ...state,
                filter: {
                    ...action.filter
                }
            }

            case CreateAssignmentActionTypes.UPDATE_SELECTED_COLOURS:
                return {
                    ...state,
                    selectedColours: action.newList
                }

            case CreateAssignmentActionTypes.CLEAR_FILTER:
                return {
                    ...state,
                    filter: {
                        ...filterModel,
                        colourList: [],
                    }
                }
            case CreateAssignmentActionTypes.SELECT_ALL_COLOURS:
                return {
                    ...state,
                    selectAllColours: action.selectAll
                }
            case CreateAssignmentActionTypes.FETCHED_ALL_COLOURS:
                return {
                    ...state,
                    selectedColours: action.newList,
                    loadingAV: false,
                }
            case CreateAssignmentActionTypes.CREATING_ASSIGNMENT:
                return {
                    ...state,
                    loadingAV: true,
                }
        default: 
            return state
        
    }
}

export default createAssignmentPageReducer;