import ajax from '../../utils/ajax';
import getAntiForgeryToken from '../../utils/antiforgerytoken';
import formatDate from '../../utils/date';
import buildUniqueActionTypes from '../../utils/actionTypeUtils'
import { spinnerInactive, spinnerActive } from './spinnerActions';

export const CreateAssignmentActionTypes = buildUniqueActionTypes([
  'SAVE_ASSIGNMENT_STEP_1',
  'LOADING_PROJECTS',
  'FETCHED_PROJECTS',
  'SHOW_CREATE_PROJECT_MODAL',
  'HIDE_CREATE_PROJECT_MODAL',
  'CREATE_NEW_PROJECT',
  'PROJECT_CREATED',
  'LOADING_SUPPLIERS',
  'FETCHED_SUPPLIERS',
  'UPDATE_SELECTED_SUPPLIERS',
  'LOADING_AVAILABLE_COLOURS',
  'FETCHED_AVAILABLE_COLOURS',
  'UPDATE_FILTER',
  'CLEAR_FILTER',
  'UPDATE_SELECTED_COLOURS',
  'CREATING_ASSIGNMENT',
  'ASSINGMENT_CREATED_SUCCESS',
  'SELECT_ALL_COLOURS',
  'FETCHED_ALL_COLOURS',
  'SELECT_ALL_SUPPLIERS'
])
  
  export function saveAssigmentStep1(assignment){
    return {
      type: CreateAssignmentActionTypes.SAVE_ASSIGNMENT_STEP_1,
      assignment: assignment
    }
  }
  
  export function fetchProjects(){
    return dispatch => {
      dispatch({
        type: CreateAssignmentActionTypes.LOADING_PROJECTS
      })
  
      ajax({
        url: '/jsapi/Project/GetCompanyProjects',
        type: 'POST',
        data: null,
        error: (errorData) => {
            console.error('fetchProjects error:', errorData);
            dispatch({
              type: CreateAssignmentActionTypes.FETCHED_PROJECTS,
              data: null
            })
        },
        success: (response) => {
            dispatch({
              type: CreateAssignmentActionTypes.FETCHED_PROJECTS,
              data: response.data
            })
        }
      })
    }
  }
    
    // -----------------------------------------------------------
    // modal actions
    // -----------------------------------------------------------
    export function showCreateProjectModal(){
        return {
            type: CreateAssignmentActionTypes.SHOW_CREATE_PROJECT_MODAL
        }
    }
    export function hideCreateProjectModal(){
      return {
        type: CreateAssignmentActionTypes.HIDE_CREATE_PROJECT_MODAL
      }
    }
  
    export function createNewProject(newProject){
      return dispatch => {
        dispatch({
          type: CreateAssignmentActionTypes.CREATE_NEW_PROJECT
        })
    
        ajax({
          url: '/jsapi/Project/NewProject',
          type: 'POST',
          data: {
            name: newProject.name,
            description: newProject.description
          },
          error: (errorData) => {
              console.error('newProject error:', errorData);
              dispatch({
                type: CreateAssignmentActionTypes.PROJECT_CREATED,
                data: null
              })
          },
          success: (response) => {
              dispatch({
                type: CreateAssignmentActionTypes.PROJECT_CREATED,
                data: response.id,      
              });
              dispatch( hideCreateProjectModal());
              dispatch(fetchProjects()); 
          }
        })
      }
    }

    export function fetchSuppliers(){
      return dispatch => {
        dispatch({
          type: CreateAssignmentActionTypes.LOADING_SUPPLIERS
        })
    
        ajax({
          url: '/jsapi/Assignment/SuppliersToAddDataTable',
          type: 'POST',
          data: null,
          error: (errorData) => {
              console.error('fetchSuppliers error:', errorData);
              dispatch({
                type: CreateAssignmentActionTypes.FETCHED_SUPPLIERS,
                data: null
              })
          },
          success: (response) => {
              dispatch({
                type: CreateAssignmentActionTypes.FETCHED_SUPPLIERS,
                data: response.data,
                headers: response.headers
              })
          }
        })
      }
    }

    export function fetchAvailableColours(skip, take, filter, order, headers){
      let columns = [];
      if(order.length > 0){
        columns = headers.map(h => {
          return {data: h.ColumnData, name: h.ColumnName};
        })
      }
      return dispatch => {
        dispatch(spinnerActive())
        dispatch({
          type: CreateAssignmentActionTypes.LOADING_AVAILABLE_COLOURS
        })
        dispatch({
          type: CreateAssignmentActionTypes.UPDATE_FILTER,
          filter: filter
        })
        ajax({
          url: '/jsapi/Assignment/ProjectColourToAddDataTable',
          type: 'POST',
          data: {
            start: skip,
            length: take,
            searchType: filter === undefined ? '' : filter.searchType,
            searchbox: filter === undefined ? '' : filter.searchBox,
            order: order,
            columns: columns
          },
          error: (errorData) => {
              console.error('fetchAvailableColours error:', errorData);
              dispatch({
                type: CreateAssignmentActionTypes.FETCHED_AVAILABLE_COLOURS,
                data: null
              })
          },
          success: (response) => {
              dispatch({
                type: CreateAssignmentActionTypes.FETCHED_AVAILABLE_COLOURS,
                data: response.data,
                headers: response.headers,
                recordsTotal: response.recordsTotal,
                recordsFiltered: response.recordsFiltered,
                materials: response.materials,
                toleranceSetList: response.toleranceSetList,
                cacheGuids: response.cacheGuids,
                order: order,
                skip: skip,
                take: take
              })
              dispatch(spinnerInactive())
          }
        })
      }
    }

    export function updateSelectedSuppliersList(id) {
      return {
        type: CreateAssignmentActionTypes.UPDATE_SELECTED_SUPPLIERS,
        id: id
      }
    }

    export function updateFilter(filter){
      return {
        type: CreateAssignmentActionTypes.UPDATE_FILTER,
        filter: filter
      }
    }

    export function clearFilter(){
      return {
        type: CreateAssignmentActionTypes.CLEAR_FILTER,
      }
    }

    export function updateSelectedColours(newList){
      return {
        type: CreateAssignmentActionTypes.UPDATE_SELECTED_COLOURS,
        newList: newList
      }
    }

    export function createAssignment(assignment, selectedColours, projects){
      const suppliers = Object.values(assignment.suppliers);
      if(assignment.projectId === null){
        assignment.projectId = projects[0].id;
      }
      return dispatch => {
        dispatch({
          type: CreateAssignmentActionTypes.CREATING_ASSIGNMENT
        })
        ajax({
          url: '/jsapi/Assignment/CreateAssignment',
          type: 'POST',
          data: {
              __RequestVerificationToken: getAntiForgeryToken(),
              AssignmentName: assignment.name,
              Description: assignment.description,
              ProjectId : assignment.projectId,
              AutomaticApproval: assignment.automaticApproval,
              ProjectColoursToAdd: selectedColours,
              SuppliersToAdd: suppliers,
              StartDateString: formatDate(assignment.startDate),
              EndDateString: formatDate(assignment.endDate)
          },
          error: (errorData) => {
              console.error('createAssignment error:', errorData);
          },
          success: (response) => {
            if(response > 0)
              window.location.href = `/V2/Assignment/Edit/${response}`;
          }
        })
      }
    }

    export function selectAllColours(selectAll){
      return {
        type: CreateAssignmentActionTypes.SELECT_ALL_COLOURS,
        selectAll: selectAll
      }
    }

    export function selectAllSuppliers(selectAll){
      return {
        type: CreateAssignmentActionTypes.SELECT_ALL_SUPPLIERS,
        selectAll: selectAll
      }
    }

