import datatableModel from '../models/datatableModel'
import { AccountActionTypes } from '../actions/accountActions'

const initialState = {
    loginErrorMessage: '',
    recoverPasswordFail: false,
    recoverPasswordResultMessage: '',
    addInProgress: false,
    inProgress: false,
    userToEdit: {
        city: '',
        contactName: '',
        coreRole: 1,
        country: '',
        email: '',
        isLockedOut: false,
        postalAddress: '',
        telephone: '',
        userId: '',
        zipCode: '',
        companies: []
    },
    showChangeModal: false,
    showDeleteModal: false,
    showLockUnlockModal: false,
    userList: {
        ...datatableModel,
        headers: [],
        data: [],
    }
}

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case AccountActionTypes.LOGIN_USER_SUCCESS:
            return {
                ...state,
                loginErrorMessage: ''
            }

        case AccountActionTypes.LOGIN_USER_FAILED:
            return {
                ...state,
                loginErrorMessage: action.loginErrorMessage
            }

        case AccountActionTypes.RECOVER_PASSWORD:
            return {
                ...state,
                recoverPasswordFail: action.recoverPasswordFail,
                recoverPasswordResultMessage: action.recoverPasswordResultMessage
            }
        
        case AccountActionTypes.ADD_USER_INPROGRESS:
            return {
                ...state,
                addInProgress: true
            }
        
        case AccountActionTypes.ADD_USER:
                return {
                    ...state,
                    addInProgress: false
                }
        case AccountActionTypes.GET_USER_SUCCESS:{
            const stateCopy = {...state}
            stateCopy.userToEdit = {
                ...stateCopy.userToEdit,
                city: action.userToEdit.City,
                contactName: action.userToEdit.ContactName,
                coreRole: action.userToEdit.CoreRole,
                country: action.userToEdit.Country,
                email: action.userToEdit.EMail,
                isLockedOut: action.userToEdit.IsLockedOut,
                postalAddress: action.userToEdit.PostalAddress,
                telephone: action.userToEdit.Telephone,
                userId: action.userToEdit.UserId,
                zipCode: action.userToEdit.ZipCode,
                companies: action.userToEdit.Companies,
                sendNotifications: action.userToEdit.SendNotifications
            }
            return stateCopy
        }

        case AccountActionTypes.CHANGE_USER_PASSWORD:
            return {
                ...state,
                inProgress: true
            }

        case AccountActionTypes.CHANGE_USER_PASSWORD_FAILED:
            return {
                ...state,
                inProgress: false
            }

        case AccountActionTypes.CHANGE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                inProgress: false,
                showChangeModal: false,
                showDeleteModal: false,
                showLockUnlockModal: false,
            }

        case AccountActionTypes.UPDATE_USER:
            return {
                ...state,
                inProgress: true
            }

        case AccountActionTypes.UPDATE_USER_FAILED:
            return {
                ...state,
                inProgress: false
            }

            case AccountActionTypes.UPDATE_USER_SUCCESS:
                return {
                    ...state,
                    inProgress: false,
                    userToEdit : {
                    city: action.updatedUser.City,
                    contactName: action.updatedUser.ContactName,
                    coreRole: action.updatedUser.CoreRole,
                    country: action.updatedUser.Country,
                    email: action.updatedUser.EMail,
                    isLockedOut: action.updatedUser.IsLockedOut,
                    postalAddress: action.updatedUser.PostalAddress,
                    telephone: action.updatedUser.Telephone,
                    userId: action.updatedUser.UserId,
                    zipCode: action.updatedUser.ZipCode
                    }
                }

        case AccountActionTypes.LOCKUNLOCK_USER:
            return {
                ...state,
                inProgress: true
            }

        case AccountActionTypes.LOCKUNLOCK_USER_FAILED:
            return {
                ...state,
                inProgress: false
            }
        case AccountActionTypes.LOCKUNLOCK_USER_SUCCESS: {
            return {
                ...state,
                inProgress: false,
                userToEdit : {
                    ...state.userToEdit,
                    isLockedOut: action.isLockedOut
                },
                showChangeModal: false,
                showDeleteModal: false,
                showLockUnlockModal: false,
            }
        }
  


            case AccountActionTypes.GET_USERS_SUCCESS: {
                return {
                    ...state,
                    userList: {
                        ...state.userList,
                        data: action.data,
                        headers: action.headers,
                        recordsFiltered: action.recordsFiltered,
                        recordsTotal: action.recordsTotal,
                        skip: action.skip,
                        take: action.take
                    },
                }
            }
      
        
            
        default:
            return state
    }
}
export default accountReducer