import { AssignmentActionTypes } from '../actions/assignmentActions'
import filterModel from '../models/filterModel'
import datatableModel from '../models/datatableModel';

const initialState = {
    assignments: {
        ...datatableModel,
        headers: [],
        data: [],
        order: [],//{column: 6, dir: 'desc'}
    },
    suppliersList: [],
    filter: {
        ...filterModel,
        hideShowInactiveControl: false
    }
}

const assignmentReducer = (state = initialState, action) => {
    switch(action.type){
        case AssignmentActionTypes.FETCHED_ASSIGNMENTS:{
            return {
                ...state,
                assignments: {
                    ...state.assignments,
                    data: action.data,
                    headers: action.headers,
                    recordsFiltered: action.recordsFiltered,
                    recordsTotal: action.recordsTotal,
                    order: action.order,
                    skip: action.skip,
                    take: action.take
                },
                filter: {
                    ...state.filter
                },
                suppliersList: action.suppliersList
            }
        }

        case AssignmentActionTypes.ASSIGNMENT_UPDATE_FILTER:
            return {
                ...state,
                filter: {
                    ...action.filter
                }
            }

        default:
            return state
    }
}
export default assignmentReducer