import buildUniqueActionTypes from '../../utils/actionTypeUtils'
import ajax from '../../utils/ajax'
import {spinnerActive, spinnerInactive} from './spinnerActions'

export const AssignmentActionTypes = buildUniqueActionTypes(['FETCHED_ASSIGNMENTS', 'ASSIGNMENT_UPDATE_FILTER'])

export function fetchAssignments(skip, take, filter, order, headers){
    let columns = [];
    if(order && order.length > 0){
        columns = headers.map(h => {
            return {data: h.ColumnData, name: h.ColumnName};
        })
    }
    return dispatch => {
        dispatch(spinnerActive())
        dispatch({
            type: AssignmentActionTypes.ASSIGNMENT_UPDATE_FILTER,
            filter
          })
        const postData = {
            start: skip,
            length: take,
            searchType: !filter ? '' : filter.searchType,
            searchbox: !filter ? '' : filter.searchBox,
            tags: JSON.stringify(filter.tags),
            order: order,
            columns: columns
        }
        postData['show-inactive'] = !filter ? false : filter.showInactive; //TODO: Change show-inactive to showInactive in backend, bad naming!
        postData['suppliers-list'] = filter.supplierListId  //TODO: Change suppliers-list to suppliersList in backend, bad naming!
        
        ajax({
            url: '/jsapi/Assignment/DataTable',
            type: 'POST',
            data: postData,
            error: (errorData) => {
                dispatch(spinnerInactive())
                console.error('Get assignments error:', errorData);
                
            },
            success: (response) => {
                dispatch(spinnerInactive())
                dispatch({
                    type: AssignmentActionTypes.FETCHED_ASSIGNMENTS,
                    data: response.data,
                    headers: response.headers,
                    recordsTotal: response.recordsTotal,
                    recordsFiltered: response.recordsFiltered,
                    order,
                    skip,
                    take,
                    suppliersList: response.suppliersList
                })
            }
          })

    }
}



