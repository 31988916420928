const allActionTypes = {}

export default function buildUniqueActionTypes(actionTypeNames){
    const builtActionType = {} 
    actionTypeNames.forEach(actionTypeName => {
        const actionTypeNameUpperCase = actionTypeName.toUpperCase()
        if(allActionTypes[actionTypeNameUpperCase]){
            throw `(${actionTypeNameUpperCase} already exist.): Action type name must be unique for that particular action/reducer only!!!`
        } else {
            allActionTypes[actionTypeNameUpperCase] = `${actionTypeNameUpperCase}`
            builtActionType[actionTypeNameUpperCase] = `${actionTypeNameUpperCase}`
        }
    })
    return builtActionType
}