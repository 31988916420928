import buildUniqueActionTypes from '../../utils/actionTypeUtils'

export const SpinnerActionTypes = buildUniqueActionTypes([
                                    'SET_SPINNER_ACTIVE',
                                    'SET_SPINNER_INACTIVE'
                                  ])

export function spinnerActive(){
    return {
      type: SpinnerActionTypes.SET_SPINNER_ACTIVE
    }
  }
  
  export function spinnerInactive(){
    return {
      type: SpinnerActionTypes.SET_SPINNER_INACTIVE
    }
  }