import React, {Component} from 'react'; 
import { Translations } from '../../../../../utils/translator';
import parseGlossValue from '../../../../../utils/gloss';


export default class BulkActionCard extends Component{
    constructor(props){
        super(props)
        this.state = {
            material: '',
            gloss: '',
            toleranceSetId: ''
        }
        this.onApplyHandler = this.onApplyHandler.bind(this);
        this.handleGlossChange = this.handleGlossChange.bind(this);
    }

    onApplyHandler(){
        const itemToUpdate = {...this.state};
        itemToUpdate.gloss = parseGlossValue(itemToUpdate.gloss) === -1 ? '' : itemToUpdate.gloss;
        if(typeof this.props.applyBulkValues === 'function')
            this.props.applyBulkValues(itemToUpdate);
    }

    checkIfEnabled(){
        if(this.state.material.trim() !== '' || this.state.gloss.trim() !== '' || this.state.toleranceSetId.trim() !== '' )
            return false

        return true
    }

    handleGlossChange(evt) {
        const glossValue = evt.currentTarget.value.replace(',', '.');
        evt.currentTarget.value = glossValue;
        const gloss = evt.currentTarget.value;
        this.setState({ gloss });
      }

    render(){
      return <div className="capq-guide__bulk-container">
      <h5 className="capq-guide__bulk-heading" data-toggle="collapse" data-target="#collapseBulk" aria-expanded="false" aria-controls="collapseBulk">
          {Translations.Copy_ColourStandard_Create_BulkHeading}
          <i className="fas fa-chevron-down"></i>
      </h5>
      <div id="collapseBulk" className="capq-guide__bulk-content collapse">
          <div>
              <div className="capq-guide__bulk-content-details">
                    {/* {this.renderMaterial(this.props.materials)} */}
                  <div className="form-group">
                      <label>{Translations.Copy_General_Gloss}</label>
                      <input className="form-control text-box js_gloss" type="text" pattern="[0-9]*" value={this.state.gloss} onChange={this.handleGlossChange}/>
                  </div>
                  {this.renderToleranceSelectBox(this.props.toleranceSetList)}
              </div>
          </div>

          <button className="btn btn-secondary js_bulkApply" disabled={this.checkIfEnabled()} onClick={this.onApplyHandler}>{Translations.Copy_ColourStandard_Create_BulkApplyButtonText}</button>
      </div>

  </div>
    }

renderMaterial(materials) {
        return<div className="form-group">
                    <label>{Translations.Copy_General_Material}</label>
                    <select className="form-control js_material" id="material_select" name={ColourPropertyNames.materialId} value={this.state.material} onChange={(event)=> this.setState({material: event.currentTarget.value})}>
                        <option value=""></option>
                    {materials.map((material) => {
                        return <option key={material.Id} value={material.Id}>{material.Name}</option>;
                    })}
                    </select>
                </div>
    }
    
    renderToleranceSelectBox(toleranceSetList){ //TODO: Compare with CSharp AssignmnetWizardBl.GetAllToleranceSets() to filter out sets
        return<div className="form-group">
        <label>{Translations.Copy_General_ToleranceSet}</label>
         <select className="form-control js_tolerance" name={ColourPropertyNames.toleranceSetId} value={this.state.toleranceSetId} onChange={(event)=> this.setState({toleranceSetId: event.currentTarget.value})}>
                 <option value=""></option>
                {toleranceSetList.map((toleranceSet, i) => {
                    if(!toleranceSet.ColourStandardId) {
                        return <option key={i} value={toleranceSet.Id}>{toleranceSet.Name}</option>;
                    }
                    return '';
                })}
        </select>
        </div>
    }

}

const ColourPropertyNames = {
    description: 'Description',
    gloss60: 'Gloss60',
    toleranceSetId: 'ToleranceSetId',
    materialId: 'MaterialId',
}