import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import MessageToast from '@ncscolour/ncs-ui-utils/components/MessageToast';

import './app.scss'

const AppContainer = (props) => {
    const spinnerActive = useSelector(state => state.spinner.active)
    const message = useSelector(state => state.message.message)
    const exportUrl = useSelector(state => state.exporter.url)

    useEffect(() => {
        if(spinnerActive === true){
            document.body.classList.add('capq-spinner') //Easy fix for now until we can change and remove all non-react code
        } else {
            document.body.classList.remove('capq-spinner') //Easy fix for now until we can change and remove all non-react code
        }
        
    }, [spinnerActive])

    return <section className="capq-app-child">
        {message !== null ? <MessageToast message={message} className={`message-toast message-toast--top-right ${message ? message.className : ''}`}/> : null}   
        {props.children}
        {(exportUrl && exportUrl.length > 0) ? <iframe id="file-download" src={exportUrl} /> : null }
        </section>
}

export default AppContainer