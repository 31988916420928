import { ReviewActionTypes } from '../actions/reviewActions'

const initialState = {
    summaryData: {
        status: '',
        inTolerance: false,
        criticalTolerances:[],
        comments:[],
    },
    ncsTabData: {
        notations: [],
        standard: {
            sRGB: '',
            name: '',
            ncs: []
        },
        measurement: {
            sRGB: '',
            name: '',
            ncs: []
        }
    },
    spectralTabData: {
        lineData: [],
        rawLineData: []
    },
    glossData: {
        samples:[],
        gloss20:'',
        gloss60:'',
        gloss85:'',
        standardGlossValues:[],
        standardName:''
    },
    cielabData: {
        lightSourceObserverViewModels:[],
        measurements: [],
        toleranceColumns: [],
        measurementsData: []
    },
    activeLightSourceObserver:{
        LightObserverName: '',
        MeasurementsCielabDataViewModel: [],
        ToleranceEllipses: []
    },
    toleranceData: {
        toleranceValues:[],
        name: '',
        description: ''
    },
    measurementInfo: {
        toleranceSet: '',
        uploadedTime: '',
        measurementTime: '',
        geometrySettings: '',
        serialNo: '',
        instrument: '',
        measuredBy: '',
        colourName: '',
        colourStandardName: '',
        statusName: '',
        assignmentName: '',
        sRGB: '',
        acceptEnabled: false,
        rejectEnabled: false,
        sendEnabled: false,
        annulEnabled: false,
        isOwner: false,
        projectMeasurementName: '',
    }

}

const reviewReducer = (state = initialState, action) => {
    switch (action.type) {

        case ReviewActionTypes.GET_NCSDATA_SUCCESS: {
            return {
                ...state,
                ncsTabData: {
                    ...state.ncsTabData,
                    notations: action.notations,
                    measurement: action.measurement,
                    standard: action.standard
                }
            }
        }


            case ReviewActionTypes.GET_SUMMARY_SUCCESS:
                return {
                    ...state,
                    summaryData: action.summaryData
                }

            case ReviewActionTypes.GET_MEASUREMENTINFO_SUCCESS:
                return {
                    ...state,
                    measurementInfo: action.measurementInfo
                }

            case ReviewActionTypes.GET_REVIEW_GLOSS_SUCCESS:{
                 return {
                    ...state,
                    glossData: action.glossData
                } 
            }
              

            case ReviewActionTypes.GET_CIELAB_DATA_SUCCESS:{
                return {
                    ...state,
                    cielabData: action.cielabData,
                    activeLightSourceObserver: action.cielabData.lightSourceObservers[0]
                }
            }


            case ReviewActionTypes.GET_TOLERANCEDATA_SUCCESS:
                return {
                    ...state,
                    toleranceData: action.toleranceData
                }

            case ReviewActionTypes.GET_SPECTRALDATA_SUCCESS: {

                return {
                    ...state,
                    spectralTabData: {
                        ...state.spectralTabData,
                        rawLineData: action.rawLineData,
                        lineData: action.lineData
                    }
                }
            }
                

        
                
        default:
            return state
    }
}
export default reviewReducer