import React, { useState } from 'react';
import validate from 'validate.js';
import { Translations } from '../../../../utils/translator';
import DropDownList from '@ncscolour/ncs-ui-utils/components/DropDownList'

const CreateCompany = (props) => {
    const [newCompanyState, setCompanyToEdit] = useState(props.newCompany)
    const [validationResult, setValidationResult] = useState(null)

    const checkFields = (fieldKey) => {
        return (validationResult && validationResult[fieldKey] && validationResult[fieldKey].length > 0)
    }

    const validationMessage = (fieldKey) => {
        return checkFields(fieldKey)?
                <div className="validation-message"><p className="help-block error">{validationResult[fieldKey].join(' ')}</p></div>
                :
                null
    }

    const updateCompany = (propName, value) => {
        const newCompanyStateCopy = {...newCompanyState};
        newCompanyStateCopy[propName] = value;
        newCompanyStateCopy.isValid = validateCompany(newCompanyStateCopy) //TODO: Hack for now until rebuild it all
        setCompanyToEdit(newCompanyStateCopy)
        props.onChange(newCompanyStateCopy)
    }

    const validateCompany = (company) => {
        const validationConstraints = {
                        name: {
                            presence: {
                                allowEmpty: false
                            }
                        },
                        country: {
                            presence: {
                                allowEmpty: false
                            }
                        },
                        timezone: {
                            presence: {
                                allowEmpty: false
                            }
                        }
                    }
        const validationResult = validate(company, validationConstraints)
        setValidationResult(validationResult)
        return validationResult === undefined
    }

    const baseClass = 'form-group'
    const nameFormGroupClassName = checkFields('name') ? `${baseClass} has-error` : baseClass
     return <section>
         <div>
         <div className={nameFormGroupClassName}>
                <label className="form-control-label">{Translations.Copy_General_CompanyName}</label>
                 <input 
                   value={newCompanyState.name} 
                   onChange={(event) => updateCompany('name', event.currentTarget.value)} 
                    className="form-control text-box" 
                    type="text"
                 />
                 {validationMessage('name')}
            </div> 

            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_Adress}</label>
                <input 
                    value={newCompanyState.postalAddress} 
                    onChange={(event) => updateCompany('postalAddress', event.currentTarget.value)} 
                    className="form-control text-box" 
                    type="text"
                 />
            </div>
            
            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_ZipCode}</label>
                <input 
                    value={newCompanyState.zipCode} 
                    onChange={(event) => updateCompany('zipCode', event.currentTarget.value)} 
                    className="form-control text-box" 
                    type="text"
                 />
            </div>

            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_City}</label>
                <input 
                    value={newCompanyState.city} 
                    onChange={(event) => updateCompany('city', event.currentTarget.value)} 
                    className="form-control text-box" 
                    type="text"
                 />
            </div>
            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_Country}</label>
                <DropDownList 
                    className="form-control" 
                    data={props.countries} 
                    selected={newCompanyState.country} 
                    onChange={country => updateCompany('country', country)} 
                    />
            </div>
            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_Telephone}</label>
                <input 
                    value={newCompanyState.telephone} 
                    onChange={(event) => updateCompany('telephone', event.currentTarget.value)} 
                    className="form-control text-box" 
                    type="text"
                 />
            </div>
            <div className="form-group">
                <label className="form-control-label">{Translations.Copy_General_TimeZone}</label>
                <DropDownList 
                    className="form-control" 
                    data={props.timezones} 
                    selected={newCompanyState.timezone} 
                    onChange={value => updateCompany('timezone', value)} 
                    />
            </div>
            </div>
        </section>
}

export default CreateCompany