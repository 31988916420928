export default function parseGlossValue(value){
    let trimmedValue = (typeof value === 'number')? value : value.trim();
    const parsedValue = new Number(trimmedValue); //Number is stricter than parseFloat(), e.g parseFloat() takes 3.4 out of string '3.4abcd' 

    if(trimmedValue === '' || isNaN(parsedValue) || parsedValue < 0){
        return -1;
    }
    return parseFloat(parsedValue); //parseFloat needed as comparing 2 Number()-objects returns false, even if its value is same
}

export function renderGlossValue(value){
    return value >=0 ? value : '';
}
