import buildUniqueActionTypes from '../../utils/actionTypeUtils'
import ajax from '../../utils/ajax'
import {spinnerActive, spinnerInactive} from './spinnerActions'
import messageToastActions from '@ncscolour/ncs-ui-utils/components/MessageToast/messageToastActions'
import { MESSAGE_TYPES } from '@ncscolour/ncs-ui-utils/components/MessageToast/messageToastReducer'
import { encodeHueString } from '../../utils/color-notation'
import { RoundNumberToDecimal } from '../../utils/decimal-converter'
import config from '../../utils/config'

export const ReviewActionTypes = buildUniqueActionTypes([
                                    'GET_NCSDATA',
                                    'GET_NCSDATA_SUCCESS',
                                    'GET_CIELAB_DATA',
                                    'GET_CIELAB_DATA_SUCCESS',
                                    'GET_SUMMARY',
                                    'GET_SUMMARY_SUCCESS',
                                    'GET_REVIEW_GLOSS',
                                    'GET_REVIEW_GLOSS_SUCCESS',
                                    'GET_SPECTRALDATA',
                                    'GET_SPECTRALDATA_SUCCESS',
                                    'GET_TOLERANCEDATA',
                                    'GET_TOLERANCEDATA_SUCCESS',
                                    'ADD_COMMENT',
                                    'ADD_COMMENT_SUCCESS',
                                    'GET_MEASUREMENTINFO',
                                    'GET_MEASUREMENTINFO_SUCCESS',
                                    'UPDATE_STATUS'

                                  ])

  export function getMeasurementInfo(projectMeasurementId, assignmentId){
    return dispatch => { 
        dispatch({
            type: ReviewActionTypes.GET_MEASUREMENTINFO,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/ProjectMeasurement/GetMeasurementInfo',
        type: 'POST',
        data: {
          projectMeasurementId,
          assignmentId
        },
        error: () => {
          dispatch(spinnerInactive())
          dispatch(messageToastActions.showMessage('Error trying to get measurement info', MESSAGE_TYPES.Error))
        },
        success: (response) => {
          dispatch(spinnerInactive())          
          dispatch({
            type: ReviewActionTypes.GET_MEASUREMENTINFO_SUCCESS,
            measurementInfo: response,
          })
        }
      })
    }
  }                                  

  export function getNcsData(projectMeasurementId){
    return dispatch => { 
        dispatch({
            type: ReviewActionTypes.GET_NCSDATA,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/ProjectMeasurement/ReviewNCS',
        type: 'POST',
        data: {
          projectMeasurementId
        },
        error: () => {
          dispatch(spinnerInactive())
          dispatch(messageToastActions.showMessage('Error trying to get NCS data', MESSAGE_TYPES.Error))
        },
        success: (response) => {
          dispatch(spinnerInactive())    
          
          if(response === null){
            dispatch(messageToastActions.showMessage('Error trying to get NCS data', MESSAGE_TYPES.Error))
            return
          }

          const notations = []
          notations.push({
              metadata: {
                  name: response.standard.name,
                  hex: response.standard.sRGB
              },
              ncs: {
                  blackness: response.standard.ncs[0],
                  chromaticness: response.standard.ncs[1],
                  whiteness: 100 - response.standard.ncs[0] - response.standard.ncs[1],
                  hue: response.standard.ncs[2]
              }
          });
      
          notations.push({
              metadata: {
                  name: response.measurement.name,
                  hex: response.measurement.sRGB
              },
              ncs: {
                  blackness: response.measurement.ncs[0],
                  chromaticness: response.measurement.ncs[1],
                  whiteness: 100 - response.measurement.ncs[0] - response.measurement.ncs[1],
                  hue: response.measurement.ncs[2]
              }
          });

          
          dispatch({
            type: ReviewActionTypes.GET_NCSDATA_SUCCESS,
            measurement: {
              blackness: RoundNumberToDecimal(response.measurement.ncs[0], 2),
              chromaticness: RoundNumberToDecimal(response.measurement.ncs[1], 2),
              whiteness: RoundNumberToDecimal(100 - response.measurement.ncs[0] - response.measurement.ncs[1], 2),
              hue: encodeHueString(response.measurement.ncs[2]),
              name: response.measurement.name,
              hex: response.measurement.sRGB
          },
          standard: {
              blackness: RoundNumberToDecimal(response.standard.ncs[0], 2),
              chromaticness: RoundNumberToDecimal(response.standard.ncs[1], 2),
              whiteness: RoundNumberToDecimal(100 - response.standard.ncs[0] - response.standard.ncs[1], 2),
              hue: encodeHueString(response.standard.ncs[2]),
              name: response.standard.name,
              hex: response.standard.sRGB
          },
          notations: notations

          })
        }
      })
    }
  }


  export function getReviewSummary(projectMeasurementId){
    return dispatch => { 
        dispatch({
            type: ReviewActionTypes.GET_SUMMARY,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/ProjectMeasurement/ReviewSummary',
        type: 'POST',
        data: {
          projectMeasurementId
        },
        error: () => {
          dispatch(spinnerInactive())
          dispatch(messageToastActions.showMessage('Error trying to get summary data', MESSAGE_TYPES.Error))
        },
        success: (response) => {
          dispatch(spinnerInactive())
          if(response === null){
            dispatch(messageToastActions.showMessage('Error trying to get summary data', MESSAGE_TYPES.Error))
            return
          }
            
          dispatch({
            type: ReviewActionTypes.GET_SUMMARY_SUCCESS,
            summaryData: response
          })
        }
      })
    }
  }

  export function reviewUpdateStatus(projectMeasurementId, assignmentId, status){

    const projectMeasurementIds = []
    projectMeasurementIds.push(projectMeasurementId)
      return dispatch => { 
        dispatch({
          type: ReviewActionTypes.UPDATE_STATUS,
      })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/ProjectMeasurement/MeasurementsUpdateStatus',
        type: 'POST',
        data: {
          projectMeasurementIds,
          assignmentId,
          status
        },
        error: () => {
          dispatch(spinnerInactive())
          dispatch(messageToastActions.showMessage('Error trying to update status', MESSAGE_TYPES.Error))
        },
        success: (response) => {
          dispatch(spinnerInactive())
          if(response === 'null'){
            dispatch(messageToastActions.showMessage('Error trying to update status', MESSAGE_TYPES.Error))
            return
          }      
          dispatch(getReviewSummary(projectMeasurementId))
          dispatch(getMeasurementInfo(projectMeasurementId, assignmentId))
        }
      })
    }
  }

  export function getReviewGloss(projectMeasurementId){
    return dispatch => { 
        dispatch({
            type: ReviewActionTypes.GET_REVIEW_GLOSS,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/ProjectMeasurement/ReviewGloss',
        type: 'POST',
        data: {
          projectMeasurementId
        },
        error: () => {
          dispatch(spinnerInactive())
          dispatch(messageToastActions.showMessage('Error trying to get gloss data', MESSAGE_TYPES.Error))
        },
        success: (response) => {
          dispatch(spinnerInactive())
          if(response === 'null'){
            dispatch(messageToastActions.showMessage('Error trying to get gloss data', MESSAGE_TYPES.Error))
            return
          }

          const samples = response.samples.map(measurement=>{
            return {
                sampleName: measurement.SampleName,
                gloss20: RoundNumberToDecimal(measurement.Gloss20, 2),
                gloss20Difference: RoundNumberToDecimal(measurement.Gloss20Difference, 2),
                gloss20OutOfTolerance: measurement.Gloss20OutOfTolerance,
                gloss60: RoundNumberToDecimal(measurement.Gloss60, 2),
                gloss60Difference: RoundNumberToDecimal(measurement.Gloss60Difference, 2),
                gloss60OutOfTolerance: measurement.Gloss60OutOfTolerance,
                gloss85: RoundNumberToDecimal(measurement.Gloss85, 2),
                gloss85Difference: RoundNumberToDecimal(measurement.Gloss85Difference, 2),
                gloss85OutOfTolerance: measurement.Gloss85OutOfTolerance,
                toleranceSet: measurement.ToleranceSet
            }
        })
            
          dispatch({
            type: ReviewActionTypes.GET_REVIEW_GLOSS_SUCCESS,
            glossData:{
              gloss20: response.gloss20,
              gloss60: response.gloss60,
              gloss85: response.gloss85,
              samples: samples,
              standardGlossValues: response.standardGlossValues,
              standardName: response.standardName
          }
          })
        }
      })
    }
  }

  export function getReviewSpectralData(projectMeasurementId){
    return dispatch => { 
        dispatch({
            type: ReviewActionTypes.GET_SPECTRALDATA,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/ProjectMeasurement/ReviewSpectralData',
        type: 'POST',
        data: {
          projectMeasurementId
        },
        error: () => {
          dispatch(spinnerInactive())
          dispatch(messageToastActions.showMessage('Error trying to get spectral data', MESSAGE_TYPES.Error))
        },
        success: (response) => {
          dispatch(spinnerInactive())
          if(response === 'null'){
            dispatch(messageToastActions.showMessage('Error trying to get spectral data', MESSAGE_TYPES.Error))
            return
          }

          const lineData = [
            {
                spectralData: response.colourStandardCoordinates,
                colour: config.black,
                dashed: false,
                spectralRangeStart: response.colourStandardStart,
                spectralRangeEnd: response.colourStandardEnd,
                spectralRangeInterval: response.colourStandardInterval,
                lineName: 'Standard'
            },
            {
                spectralData: response.measurementCoordinates,
                colour: config.capqRed,
                dashed: false,
                spectralRangeStart: response.measurementStart,
                spectralRangeEnd: response.measurementEnd,
                spectralRangeInterval: response.measurementInterval,
                lineName: 'Measurement'
            }

        ]
        const rawLineData = [
            {
                spectralData: response.rawColourStandardCoordinates,
                colour: config.black,
                dashed: true,
                spectralRangeStart: response.colourStandardStart,
                spectralRangeEnd: response.colourStandardEnd,
                spectralRangeInterval: response.colourStandardInterval,
                lineName: 'Standard (raw)'
            },
            {
                spectralData: response.rawMeasurementCoordinates,
                colour: config.capqRed,
                dashed: true,
                spectralRangeStart: response.measurementStart,
                spectralRangeEnd: response.measurementEnd,
                spectralRangeInterval: response.measurementInterval,
                lineName: 'Measurement (raw)'
            }
        ]
            
          dispatch({
            type: ReviewActionTypes.GET_SPECTRALDATA_SUCCESS,
            lineData: lineData,
            rawLineData: rawLineData
          })
        }
      })
    }
  }

  export function getCIELabData(projectMeasurementId){
    return dispatch => { 
        dispatch({
            type: ReviewActionTypes.GET_CIELAB_DATA,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/ProjectMeasurement/ReviewCIELab',
        type: 'POST',
        data: {
          projectMeasurementId
        },
        error: () => {
          dispatch(spinnerInactive())
          dispatch(messageToastActions.showMessage('Error trying to get CIELab data', MESSAGE_TYPES.Error))
        },
        success: (response) => {
          dispatch(spinnerInactive())
          if(response === 'null'){
            dispatch(messageToastActions.showMessage('Error trying to get CIELab data', MESSAGE_TYPES.Error))
            return
          }

          
          const measurementsData = [];
          response.measurements.map(measurement => {
            response.lightSourceObservers.map((vm) => {
                  let dataRow = [];
                  measurement.toleranceValues.map((tv) => {
                      if (vm.lightObserverName == tv.lightObserver) {
                          dataRow.push(tv);
                      }
                  });
                  if (dataRow.length !== 0) {
                      measurementsData.push({ name: measurement.name, light: vm.lightObserverName, toleranceValues: dataRow });
                  }
            
              });
          });

            
          dispatch({
            type: ReviewActionTypes.GET_CIELAB_DATA_SUCCESS,
            cielabData:{
              measurementsCielabData: response.measurementsCielabData,
              lightSourceObservers: response.lightSourceObservers,
              measurements: response.measurements,
              toleranceColumns: response.toleranceColumns,
              measurementsData: measurementsData,
          },
          activeLightSourceObserver: response.lightSourceObservers[0]
          })
        }
      })
    }
  }

  export function getToleranceData(projectMeasurementId){
    return dispatch => { 
        dispatch({
            type: ReviewActionTypes.GET_TOLERANCEDATA,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/ProjectMeasurement/ReviewTolerance',
        type: 'POST',
        data: {
          projectMeasurementId
        },
        error: () => {
          dispatch(spinnerInactive())
          dispatch(messageToastActions.showMessage('Error trying to get tolerance data', MESSAGE_TYPES.Error))
        },
        success: (response) => {
          dispatch(spinnerInactive())
          if(response === null){
            dispatch(messageToastActions.showMessage('Error trying to get tolerance data', MESSAGE_TYPES.Error))
            return
          }
            
          dispatch({
            type: ReviewActionTypes.GET_TOLERANCEDATA_SUCCESS,
            toleranceData: response
          })
        }
      })
    }
  }

  export function addComment(projectMeasurementId, comment){
    return dispatch => { 
        dispatch({
            type: ReviewActionTypes.ADD_COMMENT,
        })
        dispatch(spinnerActive())
        ajax({
        url: '/jsapi/ProjectMeasurement/AddComment',
        type: 'POST',
        data: {
          projectMeasurementId,
          comment
        },
        error: () => {
          dispatch(spinnerInactive())
          dispatch(messageToastActions.showMessage('Error trying to add comment', MESSAGE_TYPES.Error))
        },
        success: (response) => {
          dispatch(spinnerInactive())
          if(response === null){
            dispatch(messageToastActions.showMessage('Error trying to add comment', MESSAGE_TYPES.Error))
            return
          } else {
            dispatch(getReviewSummary(projectMeasurementId))
          }
        }
      })
    }
  }
  