import React from 'react'
import { Translations } from '../../../../../utils/translator'
import DropDownList from '@ncscolour/ncs-ui-utils/components/DropDownList'

const ColourDetailCard = (props) => {
    const ColourPropertyNames = {
        projectColourName: 'ProjectColourName',
        description: 'Description',
        gloss60: 'Gloss60',
        toleranceSetId: 'ToleranceSetId',
        materialId: 'MaterialId',
    }

    const onChangeHandler = (event) => {
        const itemToUpdate = {...props.colour};
        if(event.currentTarget.name === ColourPropertyNames.gloss60){
            const glossValue = event.currentTarget.value.replace(',', '.');
            event.currentTarget.value = glossValue;
            itemToUpdate[event.currentTarget.name] = event.currentTarget.value;
            props.updateColour(itemToUpdate);
            
        } else {
            if(event.currentTarget.name !== ColourPropertyNames.gloss60){
                itemToUpdate[event.currentTarget.name] = event.currentTarget.value
            } else {
                itemToUpdate[event.currentTarget.name] = parseInt(event.currentTarget.value)
            }
           
            props.updateColour(itemToUpdate);
        }
    }

    const onToleranceSetChangeHandler = (toleranceSetInfo) => {
        const itemToUpdate = {...props.colour}
        itemToUpdate.ToleranceSetId = toleranceSetInfo.Id
        itemToUpdate.ToleranceSetName = toleranceSetInfo.Name
        props.updateColour(itemToUpdate)
    }

    const onCheckboxClick = () => {
        const itemToUpdate = {...props.colour}
        itemToUpdate.Selected = !props.colour.Selected
        props.updateColour(itemToUpdate)
    }

    
    return  <div className="measurement">
        <div className="form-group measurement__checkbox_create">
            <input type="checkbox" id={props.colour.ColourStandardId} checked={props.colour.Selected} onChange={onCheckboxClick}/>
        </div>
        <span className="iconHTML" style={{backgroundColor: props.colour.HtmlColour}}></span>
        <section className="measurement__content">
            <section className="measurement__content-row detail-row">
                <div className="form-group">
                    <label>{Translations.Copy_General_ColourStandard}</label>
                    <input className="form-control text-box" type="text" value={props.colour.ProjectColourName} name={ColourPropertyNames.projectColourName} onChange={onChangeHandler}/>
                </div>
        
                <div className="form-group">
                    <label>{Translations.Copy_General_Colour}</label>
                    <p>{props.colour.ColourName}</p>
                </div>

                {/* {renderMaterial(props.materials, props.colour.MaterialId)}*/}

                <div className="form-group">
                    <label>{Translations.Copy_General_Description}</label>
                    <input className="form-control text-box" type="text" value={props.colour.Description || ''} name={ColourPropertyNames.description} onChange={onChangeHandler}/>
                </div>
        
                <div className="form-group form-group--small">
                    <label>{Translations.Copy_General_Gloss}</label>
                    <input className="form-control text-box" type="text" value={props.colour.Gloss60 === -1 ? '' : props.colour.Gloss60} name={ColourPropertyNames.gloss60} onChange={onChangeHandler}/>
                </div>
        
                <div className="form-group">
                    <label>{Translations.Copy_General_ToleranceSet}</label>
                    <DropDownList 
                        className="form-control" 
                        data={props.toleranceSetList.filter(toleranceSet => {
                            return (!toleranceSet.ColourStandardId || (toleranceSet.ColourStandardId && toleranceSet.ColourStandardId === props.colour.ColourStandardId))
                        })} 
                        contentProp="Name"
                        valueProp="Id"
                        selected={props.colour.ToleranceSetId}
                        onChange={id => {
                            const parsedId = parseInt(id)
                            const toleranceSet = props.toleranceSetList.find(ts => ts.Id === parsedId)
                            onToleranceSetChangeHandler(toleranceSet)
                        }}
                    />
                </div>

            </section>
        </section>
    </div>
    

    // const renderMaterial = (materials, selectedId) => {
    //     return<div className="form-group">
    //         <label>{Translations.Copy_General_Material}</label>
    //         {/* TODO: Use DropDownList instead */}
    //         <select className="form-control js_material" name={ColourPropertyNames.materialId} value={selectedId} onChange={onChangeHandler}>
    //         {materials.map(material => {
    //             return <option key={material.Id} value={material.Id}>{material.Name}</option>;
    //         })}
    //         </select>
    //     </div>
    // }
}
export default ColourDetailCard