const datatableModel = {
    headers: null,
    data: null,
    recordsFiltered: 0,
    recordsTotal:  0,
    skip: 0,
    take: 15,
    order: null,
}

export default {...datatableModel};