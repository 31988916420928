import { RoundNumberToDecimal } from './decimal-converter';

export function encodeHueString(hue, decimal) {
        if (hue < 100) {
            return 'N';
        } else if (hue === 100) {
            return 'Y';
        } else if (hue < 200) {
            const val = hue % 100;
            if (val.toString().length == 1) {
                return `Y0${val}R`;
            }
            return `Y${RoundNumberToDecimal(hue % 100, decimal)}R`;
        } else if (hue === 200) {
            return 'R';
        } else if (hue < 300) {
            const val = hue % 100;
            if (val.toString().length == 1) {
                return `R0${val}B`;
            }
            return `R${RoundNumberToDecimal(hue % 100, decimal)}B`;
        } else if (hue === 300) {
            return 'B';
        } else if (hue < 400) {
            const val = hue % 100;
            if (val.toString().length == 1) {
                return `B0${val}G`;
            }
            return `B${RoundNumberToDecimal(hue % 100, decimal)}G`;
        } else if (hue === 400) {
            return 'G';
        } else if (hue < 500) {
            const val = hue % 100;
            if (val.toString().length == 1) {
                return `G0${val}Y`;
            }
            return `G${RoundNumberToDecimal(hue % 100, decimal)}Y`;
        } else {
            return 'NOT VALID STRING';
        }

  }

export function findClosestHexFromHue(hueInt, ncsHexMapping) {
    const encodedHueString = encodeHueString(hueInt, 0);
    let hex = '';
    let highestFoundChromaticness = 0;
    Object.keys(ncsHexMapping).forEach(ncsNotation => {
      const isMatch = encodedHueString.length === 1 ? ncsNotation.toUpperCase().substring(ncsNotation.length -2) === `-${encodedHueString}` : ncsNotation.toUpperCase().endsWith(encodedHueString);
      if(isMatch){
        const chromaticness = parseInt(ncsNotation.replace(`-${encodedHueString}`, '').substring(2), 10);
        if(chromaticness > highestFoundChromaticness){
          highestFoundChromaticness = chromaticness;
          hex = ncsHexMapping[ncsNotation];
        }
      }
    });
    return hex;
  }

  export const hexMap = {
    '0502-Y':'f1efe3',
    '0505-Y':'f2efdb',
    '0507-Y':'f3eed4',
    '0510-Y':'f0e9c6',
    '0515-Y':'f7edbd',
    '0520-Y':'f6e8b1',
    '0530-Y':'fae599',
    '0540-Y':'ffe588',
    '0550-Y':'f7da6c',
    '0560-Y':'fbd553',
    '0570-Y':'f8d538',
    '0580-Y':'ffd300',
    '0505-Y10R':'f3edd7',
    '0510-Y10R':'f7eac4',
    '0515-Y10R':'f9e8ba',
    '0520-Y10R':'fae7b2',
    '0530-Y10R':'fdde98',
    '0540-Y10R':'ffda84',
    '0550-Y10R':'ffd66e',
    '0560-Y10R':'ffcd57',
    '0570-Y10R':'ffcc38',
    '0580-Y10R':'ffc400',
    '0505-Y20R':'f5edd5',
    '0507-Y20R':'f5ebcf',
    '0510-Y20R':'f8e7c4',
    '0515-Y20R':'f9e4b8',
    '0520-Y20R':'fcdfa9',
    '0530-Y20R':'ffd696',
    '0540-Y20R':'ffd080',
    '0550-Y20R':'ffc76c',
    '0560-Y20R':'ffbd57',
    '0570-Y20R':'ffb940',
    '0580-Y20R':'ffae17',
    '0585-Y20R':'ffb100',
    '0505-Y30R':'f6ead4',
    '0510-Y30R':'f8e5c7',
    '0515-Y30R':'faddb5',
    '0520-Y30R':'fad8ad',
    '0530-Y30R':'ffce93',
    '0540-Y30R':'ffc47f',
    '0550-Y30R':'ffb96c',
    '0560-Y30R':'ffb25c',
    '0570-Y30R':'ffa647',
    '0580-Y30R':'ff9e28',
    '0585-Y30R':'ff940e',
    '0505-Y40R':'f7ead7',
    '0507-Y40R':'f6e6d0',
    '0510-Y40R':'f9e2c5',
    '0515-Y40R':'fbdbb9',
    '0520-Y40R':'fed5ad',
    '0530-Y40R':'ffc896',
    '0540-Y40R':'ffb87e',
    '0550-Y40R':'ffac6c',
    '0560-Y40R':'ffa85e',
    '0570-Y40R':'ff984a',
    '0580-Y40R':'fc8d2f',
    '0585-Y40R':'f9851e',
    '0502-Y50R':'f1ebe3',
    '0505-Y50R':'f3e5d5',
    '0510-Y50R':'f8e2cd',
    '0515-Y50R':'fbd8bc',
    '0520-Y50R':'fbceae',
    '0530-Y50R':'ffc399',
    '0540-Y50R':'ffb484',
    '0550-Y50R':'ffa570',
    '0560-Y50R':'ff9b62',
    '0570-Y50R':'f98a4a',
    '0580-Y50R':'fe8034',
    '0585-Y50R':'fb7b28',
    '0505-Y60R':'f5e9dc',
    '0507-Y60R':'f7e5d5',
    '0510-Y60R':'fae1cf',
    '0515-Y60R':'fbd8c1',
    '0520-Y60R':'fdd0b4',
    '0530-Y60R':'fdbc9a',
    '0540-Y60R':'feae89',
    '0550-Y60R':'fc9f76',
    '0560-Y60R':'ff9667',
    '0570-Y60R':'fc8452',
    '0580-Y60R':'f37039',
    '0585-Y60R':'f4672d',
    '0505-Y70R':'f3e6dd',
    '0510-Y70R':'fadfcf',
    '0515-Y70R':'f9d5c3',
    '0520-Y70R':'facbb7',
    '0530-Y70R':'ffbda4',
    '0540-Y70R':'fea98e',
    '0550-Y70R':'ff9c7c',
    '0560-Y70R':'f9886a',
    '0570-Y70R':'f77958',
    '0580-Y70R':'f36644',
    '0585-Y70R':'f05831',
    '0505-Y80R':'f4e8e2',
    '0507-Y80R':'f6e5dd',
    '0510-Y80R':'fadfd4',
    '0515-Y80R':'fad7cb',
    '0520-Y80R':'fdcec0',
    '0530-Y80R':'ffbcac',
    '0540-Y80R':'fda897',
    '0550-Y80R':'fc9584',
    '0560-Y80R':'fb8372',
    '0570-Y80R':'f46f5c',
    '0580-Y80R':'e75846',
    '0585-Y80R':'e54536',
    '0505-Y90R':'f2e6e1',
    '0510-Y90R':'f7dfd8',
    '0515-Y90R':'f6d5cd',
    '0520-Y90R':'faccc3',
    '0530-Y90R':'fcbbb2',
    '0540-Y90R':'fba79f',
    '0550-Y90R':'f39189',
    '0560-Y90R':'f27b76',
    '0570-Y90R':'ea6764',
    '0580-Y90R':'e44c4c',
    '1085-Y90R':'c93236',
    '0502-R':'f0eae7',
    '0505-R':'f2e7e4',
    '0507-R':'f2e3e0',
    '0510-R':'f4ddda',
    '0515-R':'f5d4d2',
    '0520-R':'f7cac8',
    '0530-R':'f9bab8',
    '0540-R':'f7a5a5',
    '0550-R':'f08d91',
    '0560-R':'ea797f',
    '0565-R':'e86a74',
    '1070-R':'d6525e',
    '1080-R':'c23447',
    '0505-R10B':'eee4e4',
    '0510-R10B':'f3dfdf',
    '0515-R10B':'f2d6d8',
    '0520-R10B':'f3cbce',
    '0530-R10B':'f6bac3',
    '0540-R10B':'f5a7b2',
    '0550-R10B':'ee909f',
    '1060-R10B':'e06d83',
    '1070-R10B':'d4526c',
    '1575-R10B':'b83655',
    '0505-R20B':'efe5e7',
    '0507-R20B':'f0e2e5',
    '0510-R20B':'efdde0',
    '0515-R20B':'f0d6dd',
    '0520-R20B':'f0ced6',
    '0530-R20B':'f5c0cf',
    '0540-R20B':'efa8bc',
    '1050-R20B':'de82a0',
    '1060-R20B':'d8678b',
    '1070-R20B':'d04d7a',
    '0505-R30B':'f0e8eb',
    '0510-R30B':'efdfe5',
    '0520-R30B':'f1d1de',
    '0530-R30B':'ecbbd1',
    '0540-R30B':'efabcd',
    '1050-R30B':'dd84b0',
    '1060-R30B':'d567a0',
    '0505-R40B':'ebe4e8',
    '0507-R40B':'ece4ea',
    '0510-R40B':'ece1e8',
    '0515-R40B':'ecdae9',
    '0520-R40B':'ead0e4',
    '0530-R40B':'e4c0dc',
    '1040-R40B':'d6a1ca',
    '1050-R40B':'cf8ac1',
    '2060-R40B':'aa5795',
    '0502-R50B':'ebe9eb',
    '0505-R50B':'e8e5ea',
    '0510-R50B':'e9e2ea',
    '0520-R50B':'ded0e5',
    '0530-R50B':'d7bee2',
    '1040-R50B':'c7a4d5',
    '2050-R50B':'a474b0',
    '3055-R50B':'88508e',
    '0505-R60B':'e8e8ee',
    '0507-R60B':'e7e6ef',
    '0510-R60B':'e2e1ec',
    '0515-R60B':'dbdaeb',
    '0520-R60B':'d5d4e9',
    '0525-R60B':'cec9e8',
    '1030-R60B':'beb8dd',
    '1040-R60B':'b7a9dd',
    '2050-R60B':'8f7abb',
    '3555-R60B':'5f4886',
    '0505-R70B':'e5e8ec',
    '0510-R70B':'dce1ec',
    '0520-R70B':'ced5ec',
    '0525-R70B':'c6cfec',
    '1030-R70B':'b2bcdf',
    '1040-R70B':'a2acdf',
    '1550-R70B':'8895d3',
    '1555-R70B':'7e89cd',
    '2060-R70B':'6d71bf',
    '0505-R80B':'e3e8ed',
    '0507-R80B':'dfe7ee',
    '0510-R80B':'dae4ed',
    '0515-R80B':'cddceb',
    '0520-R80B':'c5d8ec',
    '0530-R80B':'b0caea',
    '1040-R80B':'93b3e0',
    '1550-R80B':'709ad5',
    '1555-R80B':'658ece',
    '2060-R80B':'4f7ac3',
    '2565-R80B':'3660ad',
    '0505-R90B':'e3e9ec',
    '0510-R90B':'d9e6ef',
    '0515-R90B':'cbddea',
    '0520-R90B':'c1daeb',
    '0530-R90B':'a6cce7',
    '0540-R90B':'90c0e7',
    '1050-R90B':'68abdf',
    '1560-R90B':'388dca',
    '2065-R90B':'0076bb',
    '0502-B':'e8edee',
    '0505-B':'dee9ec',
    '0507-B':'dce9ee',
    '0510-B':'d3e4ea',
    '0515-B':'c7e0ea',
    '0520-B':'bddfeb',
    '0530-B':'a2d3e7',
    '0540-B':'86c6df',
    '1050-B':'57afd3',
    '1060-B':'17a5d6',
    '1565-B':'008ec0',
    '0510-B10G':'d1e6e9',
    '0520-B10G':'b7dfe8',
    '0530-B10G':'9ad4e3',
    '0540-B10G':'7ecddf',
    '1050-B10G':'45b5d1',
    '1555-B10G':'13a5c4',
    '3060-B10G':'007898',
    '0505-B20G':'e0eced',
    '0507-B20G':'dcebec',
    '0515-B20G':'c4e3e7',
    '1040-B20G':'74c3d1',
    '1050-B20G':'3cb8ca',
    '2555-B20G':'008ea4',
    '3060-B20G':'00798f',
    '0510-B30G':'d1e8e8',
    '0520-B30G':'b7e1e4',
    '0530-B30G':'97d9de',
    '0540-B30G':'7ad2d8',
    '1050-B30G':'40bcc8',
    '2555-B30G':'0095a2',
    '3060-B30G':'00818f',
    '0520-B40G':'b7e1e2',
    '0530-B40G':'9bdcdd',
    '1040-B40G':'68c4c8',
    '1050-B40G':'3bbec3',
    '2555-B40G':'009297',
    '3060-B40G':'00848a',
    '0502-B50G':'e7edec',
    '0505-B50G':'e1eceb',
    '0510-B50G':'d7ebe9',
    '0515-B50G':'c8e7e5',
    '0520-B50G':'bce5e2',
    '0530-B50G':'a0e1df',
    '1040-B50G':'6cc7c5',
    '1050-B50G':'37c1be',
    '2060-B50G':'009c9b',
    '0520-B60G':'b9e5df',
    '0530-B60G':'9ddfd8',
    '1040-B60G':'71cbc3',
    '1050-B60G':'3cc5bc',
    '2555-B60G':'009991',
    '0510-B70G':'d5ece6',
    '0520-B70G':'bae7de',
    '0530-B70G':'a0e1d3',
    '1040-B70G':'6ecabe',
    '1050-B70G':'3bc8b7',
    '2060-B70G':'00a293',
    '0505-B80G':'e4eeea',
    '0507-B80G':'e0eee9',
    '0515-B80G':'cae9df',
    '1040-B80G':'78cfba',
    '1050-B80G':'4cc8b1',
    '2555-B80G':'009682',
    '0510-B90G':'d9ede3',
    '0520-B90G':'bfe8d6',
    '0530-B90G':'a6e6cc',
    '1040-B90G':'7ccfb4',
    '1050-B90G':'55c7a6',
    '1055-B90G':'3bc19d',
    '2060-B90G':'00a07d',
    '0502-G':'e8ece8',
    '0505-G':'e5eee6',
    '0507-G':'e3f0e5',
    '0510-G':'dbeedf',
    '0515-G':'d1ecd9',
    '0520-G':'c6ebd2',
    '0530-G':'aae2bf',
    '0540-G':'92deb6',
    '1050-G':'63c79a',
    '1060-G':'3abd88',
    '1565-G':'00a770',
    '0505-G10Y':'e8efe7',
    '0510-G10Y':'dfefdf',
    '0520-G10Y':'c7eacc',
    '0530-G10Y':'b1e2ba',
    '0540-G10Y':'9edeaa',
    '0550-G10Y':'85d899',
    '0560-G10Y':'6acd84',
    '0565-G10Y':'58c57a',
    '1070-G10Y':'2cb166',
    '0505-G20Y':'e5ece1',
    '0510-G20Y':'e0ecda',
    '0515-G20Y':'d5e9d0',
    '0520-G20Y':'cee9c9',
    '0530-G20Y':'b7e4b3',
    '0540-G20Y':'a6e0a3',
    '0550-G20Y':'95d68d',
    '0560-G20Y':'85d47a',
    '0570-G20Y':'68c461',
    '0575-G20Y':'5ac052',
    '0505-G30Y':'e7ede1',
    '0510-G30Y':'e2edd6',
    '0520-G30Y':'d3e6c0',
    '0530-G30Y':'c2e3ad',
    '0540-G30Y':'b1dd97',
    '0550-G30Y':'a7d883',
    '0560-G30Y':'9ad36f',
    '0570-G30Y':'87cb59',
    '0580-G30Y':'76c339',
    '0505-G40Y':'ecf1e3',
    '0507-G40Y':'e9efdc',
    '0510-G40Y':'e8f0d5',
    '0515-G40Y':'e2edcc',
    '0520-G40Y':'dbeabc',
    '0530-G40Y':'d0e6aa',
    '0540-G40Y':'c5e294',
    '0550-G40Y':'bbdd7c',
    '0560-G40Y':'afd466',
    '0570-G40Y':'9ccc47',
    '0575-G40Y':'9fcd3b',
    '0502-G50Y':'edefe6',
    '0505-G50Y':'edf1e0',
    '0510-G50Y':'e6ecd4',
    '0520-G50Y':'e3f0bd',
    '0530-G50Y':'d5e7a2',
    '0540-G50Y':'cee08d',
    '0550-G50Y':'c6de76',
    '0560-G50Y':'c0db61',
    '0565-G50Y':'b6d14b',
    '1070-G50Y':'a7bd3b',
    '1075-G50Y':'a0bb2d',
    '0505-G60Y':'edf0df',
    '0510-G60Y':'e8edd1',
    '0515-G60Y':'e6ecc4',
    '0520-G60Y':'e2eab7',
    '0530-G60Y':'dde89f',
    '0540-G60Y':'dce98a',
    '0550-G60Y':'d5e473',
    '0560-G60Y':'d0df5b',
    '0570-G60Y':'c6d83c',
    '0575-G60Y':'c5d728',
    '0505-G70Y':'edeedd',
    '0510-G70Y':'eef1ce',
    '0520-G70Y':'edf0b8',
    '0530-G70Y':'eaefa1',
    '0540-G70Y':'e4eb88',
    '0550-G70Y':'e1e771',
    '0560-G70Y':'dce156',
    '0570-G70Y':'d7de38',
    '0575-G70Y':'d1d61f',
    '0505-G80Y':'efeedc',
    '0507-G80Y':'eeeed5',
    '0510-G80Y':'eeeecb',
    '0515-G80Y':'eeedc1',
    '0520-G80Y':'f0edb4',
    '0530-G80Y':'eeeb9e',
    '0540-G80Y':'ebe784',
    '0550-G80Y':'e8e46e',
    '0560-G80Y':'e5de55',
    '0570-G80Y':'e1da38',
    '1075-G80Y':'cbc21c',
    '0505-G90Y':'f3f0dc',
    '0510-G90Y':'f2eecd',
    '0515-G90Y':'f3ecbe',
    '0520-G90Y':'f0e9b1',
    '0530-G90Y':'f8ed9d',
    '0540-G90Y':'f6e884',
    '0550-G90Y':'f1e16d',
    '0560-G90Y':'eedd52',
    '0570-G90Y':'f1db35',
    '0575-G90Y':'edd614',
};