import React from 'react'
//import { Translations } from '../../../../utils/translator'

const ConnectionSettingsTab = (props) => {
    return <>
    {(props.physicalPropertyDescription && props.physicalPropertyDescription.length > 0) ? <div className="form-group">
        <label className="form-control-label">{props.physicalPropertyName}</label>
        <p>{props.physicalPropertyDescription}</p>
    </div> : null}

    {(props.specularComponentDescription && props.specularComponentDescription.length > 0)? <div className="form-group">
        <label className="form-control-label">{props.specularComponentName}</label>
        <p>{props.specularComponentDescription}</p>
    </div> :null}

    {(props.apertureDescription && props.apertureDescription.length > 0)?<div className="form-group">
        <label className="form-control-label">{props.apertureName}</label>
        <p>{props.apertureDescription} </p>
    </div> : null}

    {(props.uvFilterDescription && props.uvFilterDescription.length > 0)?<div className="form-group">
        <label className="form-control-label">{props.uvFilterName}</label>
        <p>{props.uvFilterDescription}</p>
    </div> : null}
</>
}

export default ConnectionSettingsTab