import React, { useState } from 'react'
import { Translations } from '../../../../utils/translator'
import ChangePassword from '../ChangePassword'
import DeleteUser from '../DeleteUser'
import LockUser from '../LockUser'
import DropDownList from '@ncscolour/ncs-ui-utils/components/DropDownList'
import validate from 'validate.js'
import Modal from '../../common/Modal'

import './edit-user.scss';

const EditUser = ({userToEdit, showLockUnlockModal, showChangeModal, showDeleteModal, isCoreSupport, canEdit, isSupervisor, isLoggedInUser, countries, userId, onSave, deleteUser, lockUser, unlockUser, changePassword}) => {
    const [_userToEdit, setUserToEdit] = useState(userToEdit)
    const [_showLockUnlockModal, setShowLockModal] = useState(showLockUnlockModal)
    const [_showChangeModal, setShowChangeModal] = useState(showChangeModal)
    const [_showDeleteModal, setShowDeleteModal] = useState(showDeleteModal)
    const [validationResult, setValidationResult] = useState(null)
    const [oldPasswordRequired, setOldPasswordRequired] = useState(false)
    const [_isCoreSupport] = useState(isCoreSupport)
    const [_canEdit] = useState(canEdit)
    const [_isSupervisor] = useState(isSupervisor)
    const [_isLoggedInUser] = useState(isLoggedInUser)

    if(!userToEdit || Object.keys(userToEdit).length === 0){
        return null
    }

    const changesMade = () => {
        let changed = false
        Object.keys(_userToEdit).forEach(key => {
            if(_userToEdit[key] !== userToEdit[key]){
                changed = true
            }
        })
        return changed
    }


    const validateField = (fieldKey) => {
        return (validationResult && validationResult[fieldKey] && validationResult[fieldKey].length > 0)
    }

    const validationMessage = (fieldKey) => {
        return validateField(fieldKey)?
                <div className="validation-message"><p className="help-block error">{validationResult[fieldKey].join(' ')}</p></div>
                :
                null
    }

    const baseClass = 'form-group'
    const telephoneFormGroupClassName = validateField('telephone') ? `${baseClass} has-error` : baseClass
    const nameFormGroupClassName = validateField('name') ? `${baseClass} has-error` : baseClass
    const zipCodeFormGroupClassName = validateField('zipCode') ? `${baseClass} has-error` : baseClass

     return <>
        <header className="entity-header">
            <h1>{Translations.Copy_EditUser}</h1>
            <div className="button-row button-row-top">
                {_isCoreSupport || _isSupervisor ? <button disabled={userToEdit.isLockedOut === true} className="btn btn-secondary" onClick={()=> {
                        setOldPasswordRequired(false)
                        setShowChangeModal(!_showChangeModal)
                        }}>{Translations.Copy_EditUser_ResetPassword}</button> : null}
                    
                {_canEdit === true ?  <button disabled={userToEdit.isLockedOut === true} className="btn btn-secondary" onClick={()=> {
                    setOldPasswordRequired(true)
                    setShowChangeModal(!_showChangeModal) 
                    }}>{Translations.Copy_EditUser_ChangePassword}</button> : null }

                {(_isCoreSupport || _isSupervisor) && !_isLoggedInUser ? userToEdit.isLockedOut ? <button className="btn btn-secondary" onClick={()=> setShowLockModal(!_showLockUnlockModal)}>{Translations.Buttons_General_Unlock}</button> 
                : <button className="btn btn-secondary" onClick={()=> setShowLockModal(!_showLockUnlockModal)}>{Translations.Buttons_General_Lock}</button> : null}
                
                {(_isCoreSupport || _isSupervisor) && !_isLoggedInUser ? <button className="btn btn-danger" onClick={()=> {setShowDeleteModal(!_showDeleteModal)}}><i className="fas fa-trash"></i>{Translations.Buttons_General_DeleteButton}</button> : null}
            </div>
        </header> 
        <article className="entity-information">
            <aside className="info-panel">
                <div className="info-panel-item">
                    <span className="panel-item-label">{Translations.MainMenu_Companies}</span>
                    {userToEdit && userToEdit.companies.map((c, i) => <span key={i} className="panel-item-info">{c.Name}, {c.Country}</span>)}
                </div>
            </aside>

            <section className="main-panel">
                <div className={nameFormGroupClassName}>
                    <label className="form-control-label">{Translations.Copy_EditUser_ContactName}:</label>
                    <input className="form-control" value={_userToEdit.contactName} disabled={!_canEdit} onChange={(event) => setUserToEdit({..._userToEdit, contactName:event.currentTarget.value})} type="text"/>
                    {validationMessage('contactName')}
                </div>
                <div className="form-group">
                    <label className="form-control-label">{Translations.Copy_EditUser_Email}:</label>
                    <input className="form-control" type="email" disabled value={_userToEdit.email}/>
                </div>
                <div className="form-group">
                        <label className="form-control-label">{Translations.Copy_EditUser_UserRole}:</label>
                        <div className="form-check">
                            <input className="form-check-input" disabled={(!_isSupervisor && !_isCoreSupport) || (_isLoggedInUser && _isSupervisor)} type="radio" name="coreRole" id="coreRole1" value="1" checked={_userToEdit.coreRole === 1} onChange={(event) => setUserToEdit({..._userToEdit, coreRole: parseInt(event.currentTarget.value)})} />
                            <label className="form-check-label">User</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" disabled={(!_isSupervisor && !_isCoreSupport) || (_isLoggedInUser && _isSupervisor)} type="radio" name="coreRole" id="coreRole2" value="2" checked={_userToEdit.coreRole === 2}  onChange={(event) => setUserToEdit({..._userToEdit, coreRole: parseInt(event.currentTarget.value)})}/>
                            <label className="form-check-label">Admin</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" disabled={(!_isSupervisor && !_isCoreSupport) || (_isLoggedInUser && _isSupervisor)} type="radio" name="coreRole" id="coreRole3" value="3" checked={_userToEdit.coreRole === 3}  onChange={(event) => setUserToEdit({..._userToEdit, coreRole: parseInt(event.currentTarget.value)})}/>
                            <label className="form-check-label">Supervisor</label>
                        </div>
                </div>
                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" checked={Boolean(_userToEdit.sendNotifications)} onChange={(event) => setUserToEdit({..._userToEdit, sendNotifications: event.currentTarget.checked})} />
                        <label className="form-check-label">User want to receive notifications</label>
                    </div>
                </div>
                <div className="form-group">                    
                    <label className="form-control-label">{Translations.Copy_EditUser_PostalAdress}:</label>
                    <input className="form-control" disabled={!_canEdit} value={_userToEdit.postalAddress || ''} onChange={(event) => setUserToEdit({..._userToEdit, postalAddress: event.currentTarget.value})} type="text"/>
                </div>
                <div className={zipCodeFormGroupClassName}>
                    <label className="form-control-label">{Translations.Copy_EditUser_ZipCode}:</label>
                    <input type="text" className="form-control" disabled={!_canEdit} value={_userToEdit.zipCode || ''} onChange={(event) => setUserToEdit({..._userToEdit, zipCode: event.currentTarget.value})}/>
                    {validationMessage('zipCode')}
                </div>
                <div className="form-group">
                    <label className="form-control-label">{Translations.Copy_EditUser_City}:</label>
                    <input className="form-control" disabled={!_canEdit} value={_userToEdit.city || ''} onChange={(event) => setUserToEdit({..._userToEdit, city: event.currentTarget.value})} type="text"/>
                </div>
                <div className="form-group">
                    <label className="form-control-label">{Translations.Copy_EditUser_Country}:</label>
                    <DropDownList 
                        className="form-control" 
                        data={countries} 
                        selected={_userToEdit.country} 
                        onChange={country => setUserToEdit({..._userToEdit, country: country})}/>
                </div>
                <div className={telephoneFormGroupClassName}>
                    <label className="form-control-label">{Translations.Copy_EditUser_Telephone}:</label>
                    <input className="form-control" disabled={!_canEdit} value={_userToEdit.telephone || ''} onChange={(event) => setUserToEdit({..._userToEdit, telephone: event.currentTarget.value})} type="text"/>
                    {validationMessage('telephone')}
                </div>
                
                <div className="form-group buttons">
                    <button className="btn btn-secondary" onClick={()=>  window.location = '/V2/Account/UserList'}>{Translations.Buttons_General_CancelButton}</button>
                    <button 
                        className="btn btn-primary" 
                        disabled={!_canEdit || changesMade() !== true}  
                        onClick={() => {
                            const user = {
                                ..._userToEdit,
                                userId: userToEdit.userId
                            }
                            
                            const validationConstraints = {
                                contactName: {
                                    presence: {
                                        allowEmpty: false
                                    }
                                },
                                coreRole:{
                                    presence: {
                                        allowEmpty: false
                                    }
                                },
                                telephone:{
                                    presence: {
                                        allowEmpty: false
                                    }
                                },
                                zipCode:{
                                    presence: {
                                        allowEmpty: false
                                    }
                                }
                                };
                                const hasErrors = validate(user, validationConstraints) 
                                setValidationResult(hasErrors)
                                if(!hasErrors){
                                    onSave(user)
                                }
                        
                        }}
                    >{Translations.Buttons_General_SaveButton}</button>
                </div>
            
            {_showDeleteModal === true ? <DeleteUser 
                userName={userToEdit.email} 
                onDelete={()=>{
                    deleteUser(userToEdit.userId)
                }} 
                onClose={()=> setShowDeleteModal(!_showDeleteModal)}
                /> : null}

            {_showLockUnlockModal === true ? <LockUser 
                userName={userToEdit.email} 
                isLocked={userToEdit.isLockedOut} 
                onLock={()=>{
                    lockUser(userId)
                    setShowLockModal(!_showLockUnlockModal)
                }} 
                onUnlock={()=>{
                    unlockUser(userId)
                    setShowLockModal(!_showLockUnlockModal)
                }}
                onClose={()=> setShowLockModal(!_showLockUnlockModal)}
                /> : null}

            {_showChangeModal === true ? <Modal
                title={oldPasswordRequired ? Translations.Copy_EditUser_ChangePassword : Translations.Copy_EditUser_ResetPassword}
                >
                <ChangePassword 
                userName={userToEdit.email}
                oldPasswordRequired={oldPasswordRequired}
                userId={userToEdit.userId}
                onPasswordChange={(userId, newPassword, oldPassword)=>{
                    changePassword(userId, newPassword, oldPassword)
                    setShowChangeModal(!_showChangeModal)
                }} 
                onClose={()=> setShowChangeModal(!_showChangeModal)}
                />
                </Modal> : null}


            </section>
        </article>
</>
}

export default EditUser