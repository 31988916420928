import React,{ useState, useRef, useEffect, useCallback } from 'react'
// import * as d3 from 'd3' //https://www.freecodecamp.org/news/how-to-get-started-with-d3-and-react-c7da74a5bd9f/
import * as d3 from 'd3'
import DataTable from '../../../../components/common/DataTable';
import DropDownList from '@ncscolour/ncs-ui-utils/components/DropDownList'
import { Translations } from '../../../../../utils/translator';

const ConnectionResultChart = (props) =>{
    const d3CanvasElement = useRef()
    const svgElementIdRef = useRef()    
    const prevConnectionResult = useRef()
    const [selectedProfile, setSelectedProfile] = useState(props.activeProfile)
    const [selectedGloss, setSelectedGloss] = useState(props.activeProfile.isMultiGloss ? 0 : null)
    const [initialized, setInitialized] = useState(false)
    const xscale = useRef()
    const yscale = useRef()
    const xAxis = useRef()
    const yAxis = useRef()
    const displayed = useRef()
    const bars = useRef()
    const xOverview = useRef()
    const yOverview = useRef()
    const subgroups = useRef()
    subgroups.current = ['Bar1', 'Bar2', 'Bar3']

    const getFillColour = d => { 
      const fillColours = subgroups.current.map(subgroup => {
          if(d[1] - d[0] === d.data[subgroup] && subgroup !== 'Bar3'){
              const colour = d.data.Colours[subgroup]
              return `rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`
          } 
           if(subgroup === 'Bar3') {
            const colour = d.data.Colours[subgroup]
              return `rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`
           }
          return null;
      })
      const filteredFillColours = fillColours.filter(colour => colour !== null);
      return filteredFillColours[0]
    };

    useEffect(() => {
      if(initialized !== true){
        props.getConnectionResult(selectedProfile.id, selectedProfile.isMultiGloss ? 1 : null, 0, 15, [])
        setInitialized(true)
      }
        const connectionResultChanged = props.connectionResult.items !== prevConnectionResult.current
        if(connectionResultChanged){
          renderChart(true)
        }
        prevConnectionResult.current = props.connectionResult.items
    },[props, props.connectionResult, props.activeProfileId, renderChart, initialized, selectedProfile])

   const renderChart = useCallback(() => {
      const data = [...props.connectionResult.items]
      //const margin =  {top: 20, right: 10, bottom: 20, left: 40};
      const margin = {top: 20, right: 20, bottom: 40, left: 60};
      const marginOverview = {top: 30, right: 10, bottom: 20, left: 40};
      const selectorHeight = 40;
      const width = 800 - margin.left - margin.right;
      const height = 400 - margin.top - margin.bottom - selectorHeight;
      const heightOverview = 80 - marginOverview.top - marginOverview.bottom;
      const barWidth = 35;
      const numBars = Math.round(width/barWidth);
      const isScrollDisplayed = barWidth * data.length > width;
      const stackGen = d3.stack().keys(subgroups.current);
      const slicedData = data.slice(0, numBars);
      const slicedStackedData = stackGen(slicedData);
      const groups = slicedData.map(d => d.Group);
      const visualLimit = 0.4
      
      //Scales
      xscale.current = d3.scaleBand()
                  .domain(groups)
                  .range([0, width], .2)
                  .paddingInner(0.5);

      yscale.current = d3.scaleLinear()
                  .domain([0, props.connectionResult.maxValue * 1.1])
                  .range([height, 0]);
      
      xAxis.current  = d3.axisBottom(xscale.current);
      yAxis.current  = d3.axisLeft(yscale.current);
      //End Scales

      //Remove old SVG before render new one
      d3.select('svg').remove();
      const svg = d3.select(d3CanvasElement.current).append('svg')
                  .attr('width', width + margin.left + margin.right)
                  .attr('height', height + margin.top + margin.bottom + selectorHeight);

      const diagram = svg.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);
      diagram.append('g').attr('class', 'x axis').attr('transform', `translate(0, ${height})`).call(xAxis.current);
      diagram.append('g').attr('class', 'y axis').call(yAxis.current);

      bars.current = diagram.append('g').attr('class', 'bars-g');

      const barPosInfo = []
      bars.current.selectAll('g')
        .data(slicedStackedData.reverse())
        .enter()
        .append('g')
        .selectAll('rect')
        .data(d => d)
        .enter()
        .append('rect')
        .attr('fill', d => getFillColour(d))
        .attr('x', d => {
          const xValue = xscale.current(d.data.Group)
          const yValue = yscale.current(d[1])
          barPosInfo.push({
            x: xValue,
            y: yValue,
            barWidth: xscale.current.bandwidth(),
            barHeight: height - yscale.current(d[1]),
            tooltip: d.data.Tooltip
          })
          return xValue
        })
        .attr('y', d => yscale.current(d[1]))
        .attr('height', d => height - yscale.current(d[1]))
        .attr('width', xscale.current.bandwidth());
        
        //Render all tooltips
        const tooltipWidth = 390;
        const tooltipHeight = 40;
        barPosInfo.forEach(bpi => {
          const tooltipGroup = bars.current.append('g')
            .attr('class', 'focus')
            .style('display', 'none');

          const calcX = (bpi.x + tooltipWidth >= width)? width - tooltipWidth - 10 : bpi.x
          tooltipGroup.append('rect')
            .attr('class', 'capq-chart__tooltip')
            .attr('width', tooltipWidth)
            .attr('height', tooltipHeight)
            .attr('x', calcX)
            .attr('y', bpi.y)
            .attr('rx', 4)
            .attr('ry', 4);

          tooltipGroup.append('text')
            .attr('class', 'tooltip-value')
            .attr('x', calcX + 10)
            .attr('y', bpi.y + (tooltipHeight / 2) + 4)
            .text(bpi.tooltip);

          //Invisible overlay to handle mouse over to show/hide tooltip
          diagram.append('rect')
            .attr('class', 'capq-chart__overlay')
            //.attr('stroke', 'red')
            .attr('width', bpi.barWidth)
            .attr('height', bpi.barHeight)
            .attr('x', bpi.x)
            .attr('y', bpi.y)
            .on('mouseover', function() { 
              tooltipGroup.style('display', null); 
            })
            .on('mouseout', function() { 
              tooltipGroup.style('display', 'none'); 
            })
        })
        
        svg.append('text')
        .attr('text-anchor', 'end')
        .attr('x', 760)
        .attr('y', 350)
        .text(Translations.Copy_Instrument_Chart_Xaxis_Text);
    
        svg.append('text')
            .attr('text-anchor', 'end')
            .attr('transform', 'rotate(-90)')
            .attr('y', 15)
            .attr('x', -40)
            .text(Translations.Copy_Instrument_Chart_Yaxis_Text)

        if (isScrollDisplayed)
        {
          const subBarData = data.map(x => x.Bar3)

            xOverview.current = d3.scaleBand()
                        .domain(subBarData.map((d,index)=> index + 1))
                        .range([0, width], .2)
                        .paddingInner(0.5);

            yOverview.current = d3.scaleLinear()
                        .domain(yscale.current.domain())
                        .range([heightOverview, 0]);  

            diagram.append('g').attr('class', 'subBar');
            //subbars
            const subBars = diagram.selectAll('.subBar')
            .data(subBarData)

            subBars.enter()
            .append('rect')
            .classed('subBar', true)
            .attr('height', (d) => heightOverview - yOverview.current(d))
            .attr('width', () => xOverview.current.bandwidth())
            .attr('x', (d, index) => xOverview.current(index + 1))
            .attr('y', (d) => height + heightOverview + yOverview.current(d))
        }

            //0.4 Line?
            svg.append('g')
              .attr('transform', `translate(0, ${yscale.current(visualLimit)})`)
              .append('line')
              .attr('x2', 800 )
              .style('stroke', '#E2004A')
              .style('stroke-width', '1px')

            //Reproducibility line
            svg.append('g')
              .attr('transform', `translate(0, ${yscale.current(props.connectionResult.reproducibility)})`)
              .append('line')
              .attr('x2', 800)
              .style('stroke', '#dbe386')
              .style('stroke-width', '1px')

            displayed.current = d3.scaleQuantize()
                        .domain([0, width])
                        .range(d3.range(data.length));

            //Horizontal scroll handle
            diagram.append('rect')
              .attr('transform', `translate(0, ${height + margin.bottom})`)
              .attr('class', 'mover')
              .attr('x', 0)
              .attr('y', 0)
              .attr('height', selectorHeight)
              .attr('width', Math.round(parseFloat(numBars * width)/data.length))
              .attr('pointer-events', 'all')
              .attr('cursor', 'ew-resize')
              .call(d3.drag().on('drag', function(event) {
                const x = parseInt(d3.select(this).attr('x'));
                const newX = x + event.dx;
                const scrollHandleWidth = parseInt(d3.select(this).attr('width'));
        
                if ( newX < 0 || newX + scrollHandleWidth > width ) return;
            
                d3.select(this).attr('x', newX);
            
                const barPos = displayed.current(x);
                const newBarPos = displayed.current(newX);

                if (barPos === newBarPos) return;
            
                const newData = data.slice(newBarPos, newBarPos + numBars);
                
                const stackGen = d3.stack().keys(subgroups.current);
                const newStackedDataReversed = stackGen(newData).reverse(); 
            
                xscale.current.domain(newData.map(function (d) { return d.Group; }));
                diagram.select('.x.axis').call(xAxis.current);

                d3.select('.bars-g').remove();

                const newBarPosInfo = []
                bars.current = diagram.append('g').attr('class', 'bars-g');
                  bars.current.selectAll('g')
                      .data(newStackedDataReversed)
                      .enter()
                      .append('g')
                      .selectAll('rect')
                      .data(d => d)
                      .enter()
                      .append('rect')
                      .attr('fill', d => getFillColour(d))
                      .attr('x', d =>{
                        const xValue = xscale.current(d.data.Group)
                        const yValue = yscale.current(d[1])
                        newBarPosInfo.push({
                          x: xValue,
                          y: yValue,
                          barWidth: xscale.current.bandwidth(),
                          barHeight: height - yscale.current(d[1]),
                          tooltip: d.data.Tooltip
                        })
                        return xscale.current(d.data.Group)
                      })
                      .attr('y', d => yscale.current(d[1]))
                      .attr('height', d => height - yscale.current(d[1]))
                      .attr('width', xscale.current.bandwidth())

                      d3.select('.capq-chart__tooltip').remove();
                      d3.select('.capq-chart__overlay').remove();
                      d3.select('.focus').remove();

                              //Render all tooltips
                newBarPosInfo.forEach(bpi => {
                  const tooltipGroup = bars.current.append('g')
                    .attr('class', 'focus')
                    .style('display', 'none');

                  const calcX = (bpi.x + tooltipWidth >= width)? width - tooltipWidth - 10 : bpi.x
                  tooltipGroup.append('rect')
                    .attr('class', 'capq-chart__tooltip')
                    .attr('width', tooltipWidth)
                    .attr('height', tooltipHeight)
                    .attr('x', calcX)
                    .attr('y', bpi.y)
                    .attr('rx', 4)
                    .attr('ry', 4);

                  tooltipGroup.append('text')
                    .attr('class', 'tooltip-value')
                    .attr('x', calcX + 10)
                    .attr('y', bpi.y + (tooltipHeight / 2) + 4)
                    .text(bpi.tooltip);

                  //Invisible overlay to handle mouse over to show/hide tooltip
                  diagram.append('rect')
                    .attr('class', 'capq-chart__overlay')
                    //.attr('stroke', 'red')
                    .attr('width', bpi.barWidth)
                    .attr('height', bpi.barHeight)
                    .attr('x', bpi.x)
                    .attr('y', bpi.y)
                    .on('mouseover', function() { 
                      tooltipGroup.style('display', null); 
                    })
                    .on('mouseout', function() { 
                      tooltipGroup.style('display', 'none'); 
                    })
                })
            }))
          

      }, [props.connectionResult, xscale, yscale, xAxis, yAxis, displayed, bars, subgroups])


    const renderDeltaE2000 = (data) => {
      const toFind = 'E00'
          const pos = data.indexOf(toFind)
          if(pos >= 0){
            return <span>{data.substring(0, pos + 1)}<sub>00</sub>{data.substring(pos + toFind.length)}</span>
          }
        return data
    }
    

  return <div>
      { props.authorizedToAdministrate === true ? 
                <div className="content-row">
              <span className="label">{Translations.Copy_Instrument_Connection}:</span>
              <DropDownList 
                className="form-control profiles-dropdown" 
                data={props.profiles.data}
                contentProp="label"
                valueProp="id"
                selected={selectedProfile.id} 
                onChange={profileId => {
                    const newSelectedProfile = props.profiles.data.find(p => p.id.toString() === profileId)
                    setSelectedProfile(newSelectedProfile)
                    props.getConnectionResult(newSelectedProfile.id, newSelectedProfile.isMultiGloss ? 1 : null, props.connectionResult.skip, props.connectionResult.take, props.connectionResult.order, props.connectionResult.headers)
                }}
              />
          </div> : null
      }
      {selectedProfile.isMultiGloss ? 
            <div className="content-row">
            <span className="label">{Translations.Copy_Instrument_GlossLevel}:</span>
          <DropDownList 
          className="form-control profiles-dropdown" 
          data={selectedProfile.glossLevels}
          selected={selectedGloss} 
          onChange={gloss => {
              setSelectedGloss(gloss)
              props.getConnectionResult(selectedProfile.id, Number.parseInt(gloss) + 1, props.connectionResult.skip, props.connectionResult.take, props.connectionResult.order, props.connectionResult.headers)
              }}/>
      </div> : null}  
      <div id={svgElementIdRef.current} ref={d3CanvasElement}></div>
      <div className="svg-legend-parent">
        <div className="legend-item">
          <div className="legend-item-blurb legend-item-blurb__yellow">
          </div>
          <span className="legend-item-text">{Translations.Copy_Instrument_ConnectionResult_Reproducibility_Legend}</span>
        </div>
        <div className="legend-item">
          <div className="legend-item-blurb legend-item-blurb__red">
          </div>
          <span className="legend-item-text">{Translations.Copy_Instrument_ConnectionResult_VisualLimit_Legend}</span>
        </div>
      </div>
      <section>
  <div className="content-container">
  <h4>{Translations.Copy_Instrument_ConnectionResult_Data_Label}:</h4>
    <DataTable
      config={{
        'DeBefore2000': (data, isHeader) => {
          if(isHeader === true && data && data.length > 0){
            return renderDeltaE2000(data)
          }
          return data
        },
        'DeAfter2000': (data, isHeader) => {
          if(isHeader === true && data && data.length > 0){
            return renderDeltaE2000(data)
          }
          return data
        }
      }} 
      tableData={props.connectionResult} 
      navigateToNewPage={(selectedPageInfo) => props.getConnectionResult(selectedProfile.id, selectedProfile.gloss, selectedPageInfo.selected * props.connectionResult.take, props.connectionResult.take, props.connectionResult.order, props.connectionResult.headers)}
      updatePageSize={newSize => props.getConnectionResult(selectedProfile.id, selectedProfile.gloss, 0, newSize, props.connectionResult.order, props.connectionResult.headers)}
      selectedItems={[]}
      updateSortColumnOrder={(order) => {
          props.getConnectionResult(selectedProfile.id, selectedProfile.gloss, props.connectionResult.skip, props.connectionResult.take, order, props.connectionResult.headers);
      }}
    />
  </div>
      </section>
  </div>
}

export default ConnectionResultChart