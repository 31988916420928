import { GuideActionTypes } from '../actions/guideActions'

const initialState = {
    currentStep:1,
}

const guideReducer = (state = initialState, action) => {
    switch (action.type) {

        case GuideActionTypes.GO_TO_NEXT_STEP:
            return {
                ...state,
                currentStep: state.currentStep + 1
            }

            
        case GuideActionTypes.RETURN_TO_PREVIOUS_STEP:
            return {
                ...state,
                currentStep: state.currentStep - 1
            }
                
        default:
            return state
    }
}
export default guideReducer