import React, { useState, useRef } from 'react';
import Modal from '../../common/Modal';
import DropDownList from '@ncscolour/ncs-ui-utils/components/DropDownList';
import SwitchButton from '../../common/SwitchButton';
import DataTable from '../../common/DataTable';
import CreateProject from './create-project-modal';
import { Translations } from '../../../../utils/translator';
import DatePicker from 'react-datepicker';
import { qs } from '../../../../utils/dom';
import 'react-datepicker/dist/react-datepicker.css';
import validate from 'validate.js';


const CreateAssignmentStep1 = (props) => {  
    const [validationResult, setValidationResult] = useState(null)
    const [assignmentToEdit, setAssignmentToEdit] = useState(props.assignment)

    const checkFieldValid = (fieldKey) => {
        return (validationResult && validationResult[fieldKey] && validationResult[fieldKey].length > 0)
    }

    const validationMessage = (fieldKey) => {
        return checkFieldValid(fieldKey)?
                <div className="validation-message"><p className="help-block error">{validationResult[fieldKey].join(' ')}</p></div>
                :
                null
    }

    const validateAssignment = (assignment) => {
        const validationConstraints = {
            name: {
                presence: {
                    allowEmpty: false
                }
            }
        }
        const validationResult = validate(assignment, validationConstraints)
        setValidationResult(validationResult)
        return validationResult === undefined
    }

    const baseClass = 'form-group'
    const nameFormGroupClassName = checkFieldValid('name') ? `${baseClass} has-error` : baseClass
    const componentRef = useRef();
    const config = {
        'Id': (id, isHeader, isSelected) => {
            if(isHeader){
                return <input type="checkbox" className="js_selectAllSuppliers_Checkbox" checked={props.selectAllSuppliers} onChange={selectAllSuppliers} />
            } else {
                return <input type="checkbox" id={id} onChange={(event) => updateSelectedSuppliersList(event.currentTarget.id)} checked={isSelected}/>
            }
        }
    }

    const updateSelectedSuppliersList = (id) => {
        const listToUpdate = {...assignmentToEdit.suppliers};
        const itemToAdd = props.suppliers.data.filter(item => item.Id === parseInt(id));
        const checkbox = qs(componentRef.current, '.js_selectAllSuppliers_Checkbox');
        if(listToUpdate[id] !== undefined ){
            delete listToUpdate[id];
            checkbox.indeterminate = Object.keys(listToUpdate).length > 0;
            if (Object.keys(listToUpdate).length === 0) {
                checkbox.checked = false;
            }
        } else {
            if(itemToAdd.length > 0){
                listToUpdate[id] = itemToAdd[0];
                checkbox.indeterminate = true;
            }
        }
        updateAssignment(AssignmentPropertyNames.suppliers, listToUpdate);
        props.onSelectAllSuppliers(false);
    }
    const selectAllSuppliers = (event) => {
        const listToUpdate = {};
        if(event.currentTarget.checked){
            props.suppliers.data.forEach(supplier => {
                return listToUpdate[supplier.Id] = supplier
            });
        }
        updateAssignment(AssignmentPropertyNames.suppliers, listToUpdate);
        props.onSelectAllSuppliers(event.currentTarget.checked);
    }

    // const createNewProject = (newProject) => {
    //     props.createNewProject(newProject)
    // }

   const updateAssignment = (propName, value) => {
        const assignmentToEditCopy = {...assignmentToEdit};
        assignmentToEditCopy[propName] = value;
        assignmentToEditCopy.isValid = validateAssignment(assignmentToEditCopy) //TODO: Hack for now until rebuild it all
        setAssignmentToEdit(assignmentToEditCopy)
        props.onChange(assignmentToEditCopy)
    }

    const renderSuppliers = () => {
        if(props.suppliers.data.length > 0){
           return <div className="form-group">
            <label>{Translations.Copy_Assignment_Create_SelectSuppliers}</label>
            <DataTable
                config={config} 
                tableData={props.suppliers}
                selectProp={'Id'}
                selectedItems={assignmentToEdit.suppliers}
                selectRow={updateSelectedSuppliersList}
                selectAll={props.selectAllSuppliers}
            /> 
        </div>
        } else {
            return null
        }
    }
        if(props.currentStep !== 1) { // Prop: The current step
            return null
        }
        return  <div className="capq-guide__summary capq-guide__summary--full-height" ref={componentRef}>
                        <div className={nameFormGroupClassName}>
                            <label>{Translations.Copy_Assignment_Create_AssignmentName}</label>
                            <input value={assignmentToEdit.name} className="form-control text-box" placeholder={Translations.Copy_Assignment_Create_AssignmentNamePlaceholder} type="text"
                               onChange={(event) => updateAssignment(AssignmentPropertyNames.name, event.currentTarget.value)}/>
                                {validationMessage('name')}
                        </div>   
                        <div className="form-group">
                            <label>{Translations.Copy_General_Description}</label>
                            <input
                                value={assignmentToEdit.description} 
                                onChange={(event) => updateAssignment(AssignmentPropertyNames.description, event.currentTarget.value)}
                                className="form-control text-box" 
                                placeholder={Translations.Copy_Assignment_Create_DescriptionPlaceholder}  
                                type="text"/>
                        </div>
                        <div className="form-group form-group-half form-group-half--active">
                        <label>{Translations.Copy_General_Project}</label>
                            <div className="property-row">
                                <div className="form-group-half--item">
                                    <DropDownList 
                                        className="form-control"
                                        data={props.projects}
                                        contentProp={AssignmentPropertyNames.project.name} 
                                        valueProp={AssignmentPropertyNames.project.id} 
                                        selected={assignmentToEdit.projectId} 
                                        onChange={value => {
                                            console.log('Event: ', typeof value)
                                            updateAssignment(AssignmentPropertyNames.projectId, parseInt(value))
                                        }}/>
                                </div>
                                <div className="form-group-half--item action-links">
                                    <div className="entity-information">
                                        <button className="btn btn-primary" onClick={props.showModal}><i className="fas fa-plus"></i>New project</button>    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group form-group-half ">
                            <label>{Translations.Copy_Assignment_Create_AutomaticApproval}</label>
                            <div className="property-row">
                                <SwitchButton 
                                inactiveText={Translations.Copy_Assignment_Create_Manual} 
                                activeText={Translations.Copy_Assignment_Create_Automatic}
                                isOn={assignmentToEdit.automaticApproval} 
                                onChange={value => updateAssignment(AssignmentPropertyNames.automaticApproval, value)}
                                />
                            </div>                            
                        </div>
                        <div className="form-group form-group-half form-group-half--active">
                            <div className="property-row">
                                <div className="form-group-half--item">
                                    <label>{Translations.Copy_Assignment_Create_StartDate}</label>
                                    <DatePicker
                                    className="form-control date-control"  
                                    selected={assignmentToEdit.startDate}
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(startDate) => updateAssignment(AssignmentPropertyNames.startDate, startDate)}
                                    minDate={new Date()}
                                    maxDate={assignmentToEdit.endDate}
                                    />
                                </div>
                                <div className="form-group-half--item ">
                                    <label>{Translations.Copy_Assignment_Create_EndDate}</label>
                                    <DatePicker  
                                    className="form-control date-control"  
                                    selected={assignmentToEdit.endDate}
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(endDate) =>  updateAssignment(AssignmentPropertyNames.endDate, endDate)}
                                    minDate={assignmentToEdit.startDate}
                                    placeholderText={Translations.Copy_Assignment_Create_EndDate_Placeholder}
                                    />
                                </div>
                            </div>
         

                        </div>
                        {renderSuppliers()}
                    {props.modalVisible === true ? <Modal className="new-project_modal" 
                        title="Create new project">
                        <CreateProject onSave={props.createNewProject} hideModal={props.hideModal}/>   
                    </Modal> : null}
            </div>
}

export default CreateAssignmentStep1

const AssignmentPropertyNames = {
    name: 'name',
    description: 'description',
    projectId: 'projectId',
    automaticApproval: 'automaticApproval',
    suppliers: 'suppliers',
    startDate: 'startDate',
    endDate: 'endDate',
    project: {
        id:'id',
        name: 'name'
    }
}

