import React, { useState, useEffect} from 'react'
import DropDownList from '@ncscolour/ncs-ui-utils/components/DropDownList'
import { Translations } from '../../../../../utils/translator'

import './connection-result-table.scss'

const ConnectionResultTable = (props) => {
    const [selectedProfile, setSelectedProfile] = useState(props.activeProfile)
    const [selectedGloss, setSelectedGloss] = useState(props.activeProfile.isMultiGloss ? 0 : null)
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if(initialized !== true){
          props.getConnectionResultTable(selectedProfile.id, selectedProfile.isMultiGloss ? 1 : null)
          setInitialized(true)
        }
    },[selectedProfile, initialized, props])

    if(!props.connectionResultTable || !props.connectionResultTable.datatable){
        return null
    }

    return <section>
        {props.authorizedToAdministrate === true ? <div className="content-row">
            <span className="label">{Translations.Copy_Instrument_Connection}:</span>
            <DropDownList 
                className="form-control profiles-dropdown" 
                data={props.profiles.data}
                contentProp="label"
                valueProp="id"
                selected={selectedProfile.id} 
                onChange={profileId => {
                    const newSelectedProfile = props.profiles.data.find(p => p.id.toString() === profileId)
                    setSelectedProfile(newSelectedProfile)
                    props.getConnectionResultTable(newSelectedProfile.id, newSelectedProfile.isMultiGloss ? 1 : null)
                }}
            />
        </div> : null}   

        {selectedProfile.isMultiGloss ? <div className="content-row">
            <span className="label">{Translations.Copy_Instrument_GlossLevel}:</span>
            <DropDownList 
                className="form-control profiles-dropdown" 
                data={selectedProfile.glossLevels}
                selected={selectedGloss} 
                onChange={gloss => {
                    setSelectedGloss(gloss)
                    props.getConnectionResultTable(selectedProfile.id, Number.parseInt(gloss) + 1)
                }}
            />
        </div> : null}  
      
        <table>
        <tbody>
            <tr><td><b>Instrument:</b></td><td>{props.connectionResultTable.instrumentSettingsName}</td></tr>
            <tr><td><b>Local Ref:</b></td><td>{props.connectionResultTable.localRefInstrumentSettingsName}</td></tr>
            <tr><td><b>Ref:</b></td><td>{props.connectionResultTable.refInstrumentSettingsName}</td></tr>
            {/* <tr><td><b>Connection date:</b></td><td>{props.connectionResultTable.ConnectionDate}</td></tr> */}
            </tbody>
        </table>

        <div className="connection-result-table">
            <h4>Connection result table:</h4>
            {!props.isCoreSupport ? 
            <table>
            <thead>
                <tr>
                    <th></th>
                    <th>{Translations.Copy_Instrument_Result_AverageDeltaE}</th>
                    <th>{Translations.Copy_Instrument_Result_StandardDeviation}</th>
                </tr>
            </thead>
            <tbody>
                {props.connectionResultTable.datatable.Rows.filter(r=>r.Type === 'DE2000_1_1_1 before:' || r.Type === 'DE2000_1_1_1 after:').map((t, index)=>{
                    return <tr key={index}>
                                <td>{t.Type === 'DE2000_1_1_1 before:' ? Translations.Copy_Instrument_BeforeConnection : Translations.Copy_Instrument_AfterConnection }</td>
                                <td>{t.Average}</td>
                                <td>{t.Sdev}</td>
                            </tr>
                })}
            </tbody>
            </table>
            :    
            <table className="support-table">
                <thead>
                    <tr>
                        {props.connectionResultTable.datatable.Headers.map((h, index)=><th key={index}>{h}</th>)}
                    </tr>
                </thead>

            <tbody>
                {    props.connectionResultTable.datatable.Rows.map((t, index)=>{
                    return <tr key={index}>
                                <td>{t.Type}</td>
                                <td>{t.Average}</td>
                                <td>{t.Sdev}</td>
                                <td>{t.Max}</td>     
                                {/* <td> ID="MaxDEAfter" runat="server"/></td> */}
                            </tr>})}
            </tbody>
            </table>
            }
        </div>
    </section>
          
}

export default ConnectionResultTable